export const CATEGORY_DATA = "CATEGORY_DATA";
export const SUB_CATEGORY_LIST = "SUB_CATEGORY_LIST";
export const BRAND_DATA = "BRAND_DATA";
export const PRODUCT_DATA = "PRODUCT_DATA";
export const PRODUCT_IMAGE_DATA = "PRODUCT_IMAGE_DATA";
export const PRODUCT_CURRICULUM_DATA = "PRODUCT_CURRICULUM_DATA";
export const PRODUCT_GRADES_DATA = "PRODUCT_GRADES_DATA";
export const PRODUCT_SUBJECTS_DATA = "PRODUCT_SUBJECTS_DATA";
export const PRODUCT_TOPICS_DATA = "PRODUCT_TOPICS_DATA";
export const PRODUCT_CURRICULUM_IMAGES_DATA = "PRODUCT_CURRICULUM_IMAGES_DATA";
export const DISCOUNT_COUPON_LIST_DATA = "DISCOUNT_COUPON_LIST_DATA";