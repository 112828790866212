import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import {useDispatch, useSelector} from "react-redux";
import {callInsertDataWithLogFunction} from "../../../store/action/commonActions";
import useAuth from "../../../hooks/useAuth";
import {actionToGetSchoolsApiCall} from "../../../store/action/salesAction";
import SelectStateDropdown from "../region/SelectStateDropdown";
import SelectCityDropdown from "../region/SelectCityDropdown";
import {PinCodeText} from "../region/PinCodeText";

const AddSchool = ({ isOpen, onRequestClose }) => {
    const eventTypeId = useSelector((state) =>  state.sales.salesEventTypeId);
    const [errors, setErrors] = useState({});
    const [saveStatus, setSaveStatus] = useState({});
    const dispatch = useDispatch();
    const {auth} = useAuth();
    const [formData, setFormData] = useState({});
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };
    const updatePinCode = (val)=>{
        if( val.city_id){
            setFormData({
                ...formData,
                ['pin_code']:val.pincode,['city_id']:val.city_id,['state_id']:val.state_id
            });
        }else{
            setFormData({
                ...formData,
                ['pin_code']:val.pincode
            });
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const obj = Object.fromEntries(formData.entries());
        const newErrors = validateForm(obj);
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            obj['created_by']=auth?.id;
            dispatch(callInsertDataWithLogFunction({tableName:'schools',data:obj,userId:auth?.id,eventTypeId})).then(res => {
                dispatch(actionToGetSchoolsApiCall());
                let status = res?.success ===1 ? 'success' :'fail'
                setSaveStatus(status);
                if(status === 'success'){
                    resetForm();
                }
            })
        } else {
            setSaveStatus('fail');
        }
    };

    const resetForm = () => {
        document.getElementById('subject-form')?.reset();
    };

    const validateForm = (data) => {
        const errors = {};

        if (!data.name.trim()) {
            errors.name = 'School is required';
        }
      /*  if (data.pincode.trim() && data.pincode.length ==6) {
            errors.name = 'School is required';
        }*/

        return errors;
    };
    useEffect(() => {
        setErrors({});
        setSaveStatus({});
        resetForm();
    }, [isOpen]);
    console.log(formData,'formData')
    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='Modal'>
            <div className="container">
                <div className='title'>Add School</div>
                <form onSubmit={handleSubmit} id='subject-form'>
                    <div className='vendor-details'>
                        <div className='input-box'>
                            <input type='text' placeholder='School Name*' name='name' className='form-input'/>
                            {errors.name &&  <span className="error-message"> {errors.name} </span> }
                        </div>
                        <div className='input-box'>
                            <input type='text' placeholder='School Name*' name='name' className='form-input'/>
                            {errors.name &&  <span className="error-message"> {errors.name} </span> }
                        </div>
                        <div className='input-box'>
                            <textarea className='text-area' name={"address"} placeholder={'Address'} />
                        </div>
                        <div className='input-box'>
                            <input type="email" name={'email'} placeholder="Email" className='form-input'/>
                        </div>
                        <PinCodeText  errors={errors} name={'pin_code'} pin_code={formData.pin_code} updatePinCode={updatePinCode}/>
                        <SelectStateDropdown errors={errors} name={'state_id'} state_id={formData.state_id} handleChange={handleChange} />
                        <SelectCityDropdown errors={errors} name={'city_id'} state_id={formData.state_id} city_id={formData.city_id} handleChange={handleChange} />

                    </div>
                    <div className='buttons'>
                        <div className='button'>
                            <button className='submit-button' type='submit'>Submit</button>
                        </div>
                        <div className='button'>
                            <button className='close-button' onClick={onRequestClose}>Close</button>
                        </div>
                    </div>
                    {saveStatus==='success' ? <div className="success-message">School Added successfully!!</div> : saveStatus==='fail' && <div className='failure-message'>Something went Wrong</div> }
                </form>
            </div>
        </Modal>
    );
};

export default AddSchool;
