import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import {useDispatch, useSelector} from "react-redux";
import useAuth from "../../../../hooks/useAuth";
import {callInsertDataWithLogFunction} from "../../../../store/action/commonActions";
import {
 actionToGetWebSettingSeoMetaApiCall
} from "../../../../store/action/webSettingActions";

const AddSeoSetting = ({ isOpen, onRequestClose }) => {
    const eventTypeId = useSelector((state) =>  state.webSetting.webSettingEventTypeId);
    const menuDataList = useSelector((state) =>  state.webSetting.menuDataList);
    const seoReferenceHtml = useSelector((state) =>  state.webSetting.seoReferenceHtml);
    const companyList = useSelector((state) =>  state.users?.companyList);
    const userCompanyId = useSelector((state) =>  state.users.userCompanyId);
    const [errors, setErrors] = useState({});
    const [saveStatus, setSaveStatus] = useState({});
    const [formData, setFormData] = useState({});
    const dispatch = useDispatch();
    const {auth} = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDetail = new FormData(e.target);
        const obj = Object.fromEntries(formDetail.entries());
        const newErrors = validateForm(obj);
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            obj['created_by']=auth?.id;
            delete obj['source'];
            dispatch(callInsertDataWithLogFunction({tableName:'seo_reference',data:obj,userId:auth?.id,eventTypeId})).then(res => {
                dispatch(actionToGetWebSettingSeoMetaApiCall());
                let status = res?.success ===1 ? 'success' :'fail'
                setSaveStatus(status);
                if(status === 'success'){
                    resetForm();
                }
            })
        } else {
            setSaveStatus('fail');
        }
    };
    const resetForm = () => {
        document.getElementById('seo-content-form')?.reset();
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedFormData = {
            ...formData,
            [name]: value
        };
        setFormData(updatedFormData);
    };
    const validateForm = (data) => {
        const errors = {};

        if (!data.name.trim()) {
            errors.name = 'Name is required';
        }
        if (!data.menu_id.trim()) {
            errors.menu_id = 'Menu is required';
        }

        return errors;
    };
    useEffect(() => {
        setErrors({});
        setSaveStatus({});
        resetForm();
    }, [isOpen]);
    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='Modal'>
            <div className="container">
                <div className='title'>Add SEO Meta</div>
                <form onSubmit={handleSubmit} id='seo-content-form'>
                    <div className='vendor-details'>

                        <div className='input-box'>
                            <span className='details'>Company Name</span>
                            <select className='select' name='source' onChange={handleChange}>
                                {companyList?.map((company) => {
                                    return <option key={company?.id} value={company?.id} selected={company?.id === userCompanyId}>{company?.name}</option>
                                })}
                            </select>
                        </div>

                        <div className='input-box'>
                            <span className='details'>Menu</span>
                            <select className='select' placeholder='Website Menu'  name="menu_id">
                                <option value="">Select Menu</option>
                                {menuDataList !== 'loading' && menuDataList?.map(menuData => {
                                    return (formData?.source == menuData.source) && <option key={menuData?.id} value={menuData?.id} selected={menuData?.id === formData?.parent_id}>{menuData?.name}</option>
                                })}
                            </select>
                            {errors.menu_id &&  <span className="error-message"> {errors.menu_id} </span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>SEO Content Name</span>
                            <input type='text' placeholder='SEO Content Name*' name='name' className='form-input'/>
                            {errors.name &&  <span className="error-message"> {errors.name} </span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Html Type</span>
                            <select className='select' name='html_type' >
                                {seoReferenceHtml?.map((html,index) => {
                                    return <option key={html?.id} value={html?.id} selected={index === 0}>{html?.name}</option>
                                })}
                            </select>
                        </div>
                        <div className='input-box'>
                            <span className='details'>HTTP Equiv</span>
                            <input type='text' placeholder='Http equiv' name='http_equiv' className='form-input'/>
                            {errors.http_equiv &&  <span className="error-message"> {errors.http_equiv} </span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Charset</span>
                            <input type='text' placeholder='Charset' name='charset' className='form-input'/>
                            {errors.charset &&  <span className="error-message"> {errors.charset} </span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>SEO Content</span>
                            <textarea className='text-area' placeholder='SEO Content ' name='content' />
                            {errors.content &&  <span className="error-message"> {errors.content} </span> }
                        </div>
                    </div>
                    <div className='buttons'>
                        <div className='button'>
                            <button className='submit-button' type='submit'>Submit</button>
                        </div>
                        <div className='button'>
                            <button className='close-button' onClick={onRequestClose}>Close</button>
                        </div>
                    </div>
                    {saveStatus==='success' ? <div className="success-message">SEO Html Added successfully!!</div> : saveStatus==='fail' && <div className='failure-message'>Something went Wrong</div> }
                </form>
            </div>
        </Modal>
    );
};

export default AddSeoSetting;
