import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import {useDispatch, useSelector} from "react-redux";
import {actionToGetMenuListApiCall} from "../../../../store/action/webSettingActions";
import {callInsertDataWithLogFunction} from "../../../../store/action/commonActions";
import useAuth from "../../../../hooks/useAuth";
import SelectMenuDropdown from "./SelectMenuDropdown";
import SelectCompanyDropdown from "../../../auth/SelectCompanyDropdown";
import SelectComponentDropdown from "./SelectComponentDropdown";

const AddMenu = ({ isOpen, onRequestClose }) => {
    const eventTypeId = useSelector((state) =>  state.webSetting.webSettingEventTypeId);
    const ROLES = useSelector((state) =>  state.webSetting.roleList);
    const allowedRoles =[ROLES.SuperAdmin,ROLES.Admin];
    const [errors, setErrors] = useState({});
    const [saveStatus, setSaveStatus] = useState({});
    const dispatch = useDispatch();
    const {auth} = useAuth();
    const [formData, setFormData] = useState({
        source: auth.source,
        exact: '1',
        show_in_mobile_navbar: '0',
        show_in_web_navbar:'0',
        footer_menu: '0',
        second_footer_menu: '0',
        display_only_logged_in: '0',
        left_menu: '0',
        right_menu: '0'
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const obj = Object.fromEntries(formData.entries());
        const newErrors = validateForm(obj);
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            if (!obj['parent_id']?.trim()){
                delete obj['parent_id'];
            }
            obj['created_by']=auth?.id;
            dispatch(callInsertDataWithLogFunction({tableName:'menu',data:obj,userId:auth?.id,eventTypeId})).then(res => {
                dispatch(actionToGetMenuListApiCall());
                let status = res?.success ===1 ? 'success' :'fail'
                setSaveStatus(status);
                if(status === 'success'){
                    resetForm();
                }
            })
        } else {
            setSaveStatus('fail');
        }
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedFormData = {
            ...formData,
            [name]: value
        };
        setFormData(updatedFormData);
    };

    const resetForm = () => {
        document.getElementById('menu-form')?.reset();
    };

    const validateForm = (data) => {
        const errors = {};

        if (!data.name.trim()) {
            errors.name = 'Menu Name is required';
        }
        if (!data.url.trim()) {
            errors.url = 'Menu Url is required';
        }
        if (!data.ordering.trim()) {
            errors.ordering = 'ordering is required';
        }

        return errors;
    };
    useEffect(() => {
        setErrors({});
        setSaveStatus({});
        resetForm();
    }, [isOpen]);
    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='Modal'>
            <div className="container">
                <div className='title'>Add Menu</div>
                <form onSubmit={handleSubmit} id='menu-form'>
                    <div className='vendor-details'>
                        <SelectCompanyDropdown allowedRoles={allowedRoles} source={formData.source}  handleChange={handleChange} />

                        <SelectMenuDropdown name={'parent_id'} source={formData.source} errors={errors} menu_id={formData.parent_id} handleChange={handleChange}/>
                        <div className='input-box'>
                            <span className='details'>Menu Name</span>
                            <input type='text' placeholder='Menu Name*' name='name' className='form-input'/>
                            {errors.name &&  <span className="error-message"> {errors.name} </span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Menu Url</span>
                            <input type='text' placeholder='Menu Url*' name='url' className='form-input'/>
                            {errors.url &&  <span className="error-message"> {errors.url} </span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Menu Tooltip</span>
                            <input type='text' placeholder='Menu Tooltip' name='tooltip' className='form-input'/>
                            {errors.tooltip &&  <span className="error-message"> {errors.tooltip} </span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Menu Ordering</span>
                            <input type='number' placeholder='Menu Ordering' name='ordering' className='form-input'/>
                            {errors.ordering &&  <span className="error-message"> {errors.ordering} </span> }
                        </div>
                        <SelectComponentDropdown component_type={'menu'} name={'component_id'}  errors={errors} component_id={formData.component_id} handleChange={handleChange}/>
                        <div className='input-box'>
                            <span className='details'>Exact Url</span>
                            <select className='select' name='exact' value={formData.exact} placeholder='Exact Url' onChange={handleChange}>
                                <option value="1" >Yes</option>
                                <option value="0" >No</option>
                            </select>
                            {errors.exact && <span className="error-message"> {errors.exact}</span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Show in Mobile Navbar</span>
                            <select className='select' name='show_in_mobile_navbar' value={formData.show_in_mobile_navbar} placeholder='Show in Mobile Navbar' onChange={handleChange}>
                                <option value="1" >Yes</option>
                                <option value="0" >No</option>
                            </select>
                            {errors.show_in_mobile_navbar && <span className="error-message"> {errors.show_in_mobile_navbar}</span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Show in Web Navbar</span>
                            <select className='select' name='show_in_web_navbar' value={formData.show_in_web_navbar} placeholder='Show in Web Navbar' onChange={handleChange}>
                                <option value="1" >Yes</option>
                                <option value="0">No</option>
                            </select>
                            {errors.show_in_web_navbar && <span className="error-message"> {errors.show_in_web_navbar}</span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Footer Menu</span>
                            <select className='select' name='footer_menu' value={formData.footer_menu} placeholder='Show in Footer Menu' onChange={handleChange}>
                                <option value="1" >Yes</option>
                                <option value="0" selected={true}>No</option>
                            </select>
                            {errors.footer_menu && <span className="error-message"> {errors.footer_menu}</span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Show in Footer Menu</span>
                            <select className='select' name='second_footer_menu' placeholder='Show in Footer Menu' onChange={handleChange}>
                                <option value="1" >Yes</option>
                                <option value="0" selected={true}>No</option>
                            </select>
                            {errors.second_footer_menu && <span className="error-message"> {errors.second_footer_menu}</span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Display Only Logged in</span>
                            <select className='select' name='display_only_logged_in' placeholder='Display Only Logged in' onChange={handleChange}>
                                <option value="1" >Yes</option>
                                <option value="0" selected={true}>No</option>
                            </select>
                            {errors.display_only_logged_in && <span className="error-message"> {errors.display_only_logged_in}</span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Show in Left Side</span>
                            <select className='select' name='left_menu' placeholder='Show in Left Side' onChange={handleChange}>
                                <option value="1" >Yes</option>
                                <option value="0" selected={true}>No</option>
                            </select>
                            {errors.left_menu && <span className="error-message"> {errors.left_menu}</span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Show in Right Side</span>
                            <select className='select' name='right_menu' placeholder='Show in Right Side' onChange={handleChange}>
                                <option value="1" >Yes</option>
                                <option value="0" selected={true}>No</option>
                            </select>
                            {errors.right_menu && <span className="error-message"> {errors.right_menu}</span> }
                        </div>
                    </div>
                    <div className='buttons'>
                        <div className='button'>
                            <button className='submit-button' type='submit'>Submit</button>
                        </div>
                        <div className='button'>
                            <button className='close-button' onClick={onRequestClose}>Close</button>
                        </div>
                    </div>
                    {saveStatus==='success' ? <div className="success-message">Menu Added successfully!!</div> : saveStatus==='fail' && <div className='failure-message'>Something went Wrong</div> }
                </form>
            </div>
        </Modal>
    );
};

export default AddMenu;
