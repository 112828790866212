import React, {useState} from "react";
import {callInsertDataWithLogFunction} from "../../../store/action/commonActions";
import {actionToGetCurriculumListApiCall} from "../../../store/action/curriculumAction";
import {actionToGetProductCurriculumImagesApiCall, uploadFileOnAws} from "../../../store/action/inventoryActions";
import {useDispatch, useSelector} from "react-redux";
import useAuth from "../../../hooks/useAuth";

const AddCurriculumContent = ({selectedCurriculum,callFrom,selectedProduct}) =>{
    const eventTypeId = useSelector((state) =>  state.curriculum.curriculumEventTypeId);
    const dispatch = useDispatch();
    const [uploadedFile,setUploadedFile] = useState([]);
    const [formData, setFormData] = useState({  });
    const [errors, setErrors] = useState({});
    const {auth} = useAuth();

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedFormData = {
            ...formData,
            [name]: value
        };
        setFormData(updatedFormData);
    };
    const handleImageUpload = (event) => {
        const files = event.target.files;
        setUploadedFile(Array.from(files));
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formValue = new FormData(e.target);
        const obj = Object.fromEntries(formValue.entries());
        const newErrors = await validateForm(obj);
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            let ordering = Number(obj.ordering);
            obj['curriculum_id'] = selectedCurriculum.id;
            if(obj['type'] === 'youtube'){
                dispatch(callInsertDataWithLogFunction({
                    tableName: 'curriculum_content',
                    data: obj,
                    userId: auth?.id,
                    eventTypeId: eventTypeId
                })).then(() => {
                    if(callFrom ==='product-curriculum-image'){
                        dispatch(actionToGetProductCurriculumImagesApiCall({product_curriculum_id: selectedCurriculum.id}))
                    }else{
                        dispatch(actionToGetCurriculumListApiCall())
                    }
                    resetForm();
                    //props.onRequestClose();
                });
            }else {
                uploadedFile.map(async photo => {
                    let payload = {...obj};
                    let companyName = selectedProduct?.company_name ? selectedProduct?.company_name : 'stemcity';
                    let filename = `curriculum/content-${companyName}.${photo.name?.replaceAll(/[ ]+$/g, "")}`;
                    payload['url'] = await dispatch(uploadFileOnAws({
                        filename: filename,
                        photo: photo,
                        showOnWebsite: companyName,
                        type: photo.type
                    }));
                    payload['ordering'] = ordering++;
                    await dispatch(callInsertDataWithLogFunction({
                        tableName: 'curriculum_content',
                        data: payload,
                        userId: auth?.id,
                        eventTypeId: eventTypeId
                    })).then(() => {
                        if(callFrom ==='product-curriculum-image'){
                            dispatch(actionToGetProductCurriculumImagesApiCall({product_curriculum_id: selectedCurriculum.id}))
                        }else{
                            dispatch(actionToGetCurriculumListApiCall())
                        }
                        resetForm();
                        // props.onRequestClose();
                    });
                })
            }
        }
    };
    const resetForm = () => {
        document.getElementById('curriculum-file')?.reset();
        setFormData({});
        setUploadedFile({});
        setErrors({});
    };

    const validateForm = (data) => {
        const errors = {};
        if (!data.type.trim()) {
            errors.type = 'Type is required';
        }

        if(data.type === 'flipbook' && !data.flipbook_code.trim()){
            errors.flipbook_code = 'Flipbook code is required';
        }
        return errors;
    };
    return (
        <>
            <div className='grade-container'>
            <form id={'curriculum-file'} onSubmit={handleSubmit}>
                <div className='input-box'>
                    <select className='select' name='type'  onChange={handleChange}>
                        <option value={''}>Please select File Type</option>
                        <option value="image" >Image</option>
                        <option value="video" >Video</option>
                        <option value="youtube" >Youtube</option>
                        <option value="flipbook" >Flipbook</option>
                        <option value="file" >file</option>
                    </select>
                    {errors.type && <span className="error-message"> {errors.type}</span> }
                </div>
                {formData.type === 'flipbook' ?
                    <div className={'input-box'}>
                        <input name={'flipbook_code'} type={'text'} placeholder={"Enter Flipbook code"}/>
                        {errors.flipbook_code && <span className="error-message"> {errors.flipbook_code}</span> }
                    </div> : ''}
                {formData.type === 'youtube' ?
                    <div className={'input-box'}>
                        <input type={'text'} name={'url'} placeholder={'please paste youtube link'}/></div>:
                    <input
                        type="file"
                        multiple
                        accept={formData.type === 'flipbook' || formData.type ==="image" ? "image/*" : formData.type === 'video' ? "video/*" :
                            formData.type === 'file' ? "application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,\n" +
                                "text/plain, application/pdf": "*"}
                        name={'url'}
                        className="upload-input"
                        onChange={handleImageUpload}
                    />}
                <div className='vendor-details'>
                <div className='input-box'>
                    <span className='details'>Position</span>
                    <input type='number' name='ordering' className='form-input'/>
                    {errors.ordering &&  <span className="error-message"> {errors.ordering} </span> }
                </div></div>
                <div className='buttons'>
                    <div className='button'>
                        <button className='add-curriculum-button' type={"submit"}>Add Curriculum File</button>
                    </div>
                </div>
            </form>
            </div>
        </>
    )
}
export default AddCurriculumContent;
