import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import {useDispatch, useSelector} from "react-redux";
import {actionToGetCompanyListApiCall} from "../../../../store/action/webSettingActions";
import useAuth from "../../../../hooks/useAuth";
import {callUpdateDataWithLogFunction} from "../../../../store/action/commonActions";


const EditCompany= ({ isOpen,selectedData, onRequestClose }) => {
    const eventTypeId = useSelector((state) =>  state.webSetting.webSettingEventTypeId);
    const dispatch = useDispatch();
    const {auth} = useAuth();
    const [isFormChanged, setIsFormChanged] = useState(false);
    const [formData, setFormData] = useState({
        name: ''
    });

    const [errors, setErrors] = useState({});
    const [saveStatus, setSaveStatus] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedFormData = {
            ...formData,
            [name]: value
        };
        setFormData(updatedFormData);

        // Check if the updated form data differs from the initial data
        setIsFormChanged(JSON.stringify(updatedFormData) !== JSON.stringify(selectedData));
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        const formValue = new FormData(e.target);
        const obj = Object.fromEntries(formValue.entries());
        const newErrors = validateForm(obj);
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            dispatch(callUpdateDataWithLogFunction({tableName:'company',data:obj,id:selectedData?.id,userId:auth?.id,eventTypeId})).then(res => {
                let status = res.success ===1 ? 'success' : 'fail'
                setSaveStatus(status);
                if(status === 'success'){
                    dispatch(actionToGetCompanyListApiCall());
                    onRequestClose();
                }
            })
        } else {
            setSaveStatus('fail');
        }
    };

    useEffect(() => {
        setFormData(selectedData);
    }, [selectedData]);

    const validateForm = (data) => {
        const errors = {};

        if (!data.phone.trim()) {
            errors.phone = 'Company Phone is required';
        }
        if (!data.email.trim()) {
            errors.email = 'Company Email is required';
        }

        return errors;
    };
    useEffect(() => {
        setErrors({});
        setSaveStatus({});
    }, [isOpen]);
    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='Modal' ariaHideApp={false}>
            <div className="container">
                <div className='title'>Edit Company</div>
                <form action='#' onSubmit={(e) => handleSubmit(e)}>
                    <div className='vendor-details'>
                        <div className='input-box'>
                            <span className='details'>Phone</span>
                            <input type='text' placeholder='Phone Number*'
                                   name='phone' className='form-input'
                                   value={formData?.phone}
                                   onChange={handleChange}/>
                            {errors?.phone && <span className="error-message">{errors.phone} </span>}
                        </div>
                        <div className='input-box'>
                            <span className='details'>Address</span>
                            <textarea className='text-area' placeholder='Address' name='address' value={formData?.address}
                                      onChange={handleChange}/>;
                            {errors?.address && <span className="error-message">{errors.address} </span>}
                        </div>
                        <div className='input-box'>
                            <span className='details'>Description</span>
                            <textarea className='text-area' placeholder='Description' name='description' value={formData?.description}
                                      onChange={handleChange}/>
                            {errors?.description && <span className="error-message">{errors.description} </span>}
                        </div>

                        <div className='input-box'>
                            <span className='details'>Url</span>
                            <input type='text' placeholder='Url*'  name='url' className='form-input' value={formData?.url}  onChange={handleChange}/>
                            {errors?.url && <span className="error-message">{errors.url} </span>}
                        </div>
                        <div className='input-box'>
                            <span className='details'>Email</span>
                            <input type='text' placeholder='Email*'  name='email' className='form-input' value={formData?.email}  onChange={handleChange}/>
                            {errors?.email && <span className="error-message">{errors.email} </span>}
                        </div>
                        <div className='input-box'>
                            <span className='details'>Landline</span>
                            <input type='text' placeholder='Landline'  name='landline' className='form-input' value={formData?.landline}  onChange={handleChange}/>
                            {errors?.landline && <span className="error-message">{errors.landline} </span>}
                        </div>
                        <div className='input-box'>
                            <span className='details'>Facebook Link</span>
                            <input type='text' placeholder='Facebook Link'  name='facebook_link' className='form-input' value={formData?.facebook_link}  onChange={handleChange}/>
                            {errors?.facebook_link && <span className="error-message">{errors.facebook_link} </span>}
                        </div>
                        <div className='input-box'>
                            <span className='details'>Instagram Link</span>
                            <input type='text' placeholder='Instagram Link'  name='instagram_link' className='form-input' value={formData?.instagram_link}  onChange={handleChange}/>
                            {errors?.instagram_link && <span className="error-message">{errors.instagram_link} </span>}
                        </div>
                        <div className='input-box'>
                            <span className='details'>Linkedin Link</span>
                            <input type='text' placeholder='Linkedin Link'  name='linkedin_link' className='form-input' value={formData?.linkedin_link}  onChange={handleChange}/>
                            {errors?.linkedin_link && <span className="error-message">{errors.linkedin_link} </span>}
                        </div>
                        <div className='input-box'>
                            <span className='details'>Youtube Link</span>
                            <input type='text' placeholder='youtube_link'  name='youtube_link' className='form-input' value={formData?.youtube_link}  onChange={handleChange}/>
                            {errors?.youtube_link && <span className="error-message">{errors.youtube_link} </span>}
                        </div>
                        <div className='input-box'>
                            <span className='details'>Whatsapp Link</span>
                            <input type='text' placeholder='whatsapp_link'  name='whatsapp_link' className='form-input' value={formData?.whatsapp_link}  onChange={handleChange}/>
                            {errors?.whatsapp_link && <span className="error-message">{errors.whatsapp_link} </span>}
                        </div>
                        <div className='input-box'>
                            <span className='details'>Pintrest Link</span>
                            <input type='text' placeholder='pintrest Link'  name='pintrest_link' className='form-input' value={formData?.pintrest_link}  onChange={handleChange}/>
                            {errors?.pintrest_link && <span className="error-message">{errors.pintrest_link} </span>}
                        </div>
                        <div className='input-box'>
                            <span className='details'>Map Link</span>
                            <input type='text' placeholder='map link'  name='map_link' className='form-input' value={formData?.map_link}  onChange={handleChange}/>
                            {errors?.map_link && <span className="error-message">{errors.map_link} </span>}
                        </div>
                    </div>
                    <div className='buttons'>
                        <div className='button'>
                            <button className='submit-button' type='submit'  disabled={!isFormChanged}>Update</button>
                        </div>
                        <div className='button'>
                            <button className='close-button' onClick={onRequestClose}>Close</button>
                        </div>
                    </div>
                    {saveStatus==='success' ? <div className="success-message">Company Updated successfully!!</div> : saveStatus==='fail' && <div className='failure-message'>Something went Wrong</div> }
                </form>

            </div>
        </Modal>
    );
};


export default EditCompany;
