import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import CreatableSelect from 'react-select/creatable';
import {callInsertDataWithLogFunction} from "../../../../store/action/commonActions";
import {actionToGetBrandsApiCall, actionToGetSubCategoriesApiCall} from "../../../../store/action/inventoryActions";
import useAuth from "../../../../hooks/useAuth";

const SelectBrandDropdown = (props) =>{
    const subCategoryData = useSelector((state) =>  state.inventory?.subCategoryData);
    const eventTypeId = useSelector((state) =>  state.inventory.inventoryEventTypeId);
    const [componentDropdown, setComponentDropdown] = useState([]);
    const [selectedDropDown, setSelectedDropDown] = useState({});
    const {auth} = useAuth();
    const dispatch=useDispatch();

    useEffect(() => {
        let componentArray = [];
        let selectedData = {};
        subCategoryData !== 'loading'  && subCategoryData?.map(val=>{
            if(val.category_id ==props?.category_id){
                componentArray.push({value:val.id,label:val.name});
                if(props?.sub_category_id == val.id) selectedData={value:val.id,label:val.name};
            }
        })
        setSelectedDropDown(selectedData);
        setComponentDropdown([...componentArray]);
    }, [subCategoryData,props?.category_id,props?.sub_category_id]);
    const handleCreate = (val) => {
        if (val) {
            let obj ={name:val,created_by:auth?.id,category_id:props?.category_id};
            dispatch(callInsertDataWithLogFunction({tableName:'sub_categories',data:obj,userId:auth?.id,eventTypeId})).then(res => {
                if(res?.id){
                    props.handleChange({target:{name:props?.name,value: res.id}});
                    dispatch(actionToGetSubCategoriesApiCall());
                }
            })
        } 
    };
    return(
    <div className='input-box'>
        <span className='details'>Select Sub Category</span>
        <CreatableSelect name={props.name}
                value={selectedDropDown}
                onCreateOption={handleCreate}
                onChange={(e)=>{props.handleChange({target:{name:props?.name,value:e.value}})}}
                options={componentDropdown}
        />
        {props.errors[props.name] && <span className="error-message"> {props.errors[props.name]}</span> }
    </div>
    )
}
export default SelectBrandDropdown;