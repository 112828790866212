import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth.js";
import {subDomainUrl} from "../../hooks/config";

const AuthorizedComponent = ({ allowedRoles,AuthComponent,Component }) => {
    const { auth } = useAuth();

    return (
        (allowedRoles?.includes(auth?.role))
            ? AuthComponent
            : Component
    );
}

export default AuthorizedComponent;
