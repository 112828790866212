import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";
import {
    actionToGetGradeCurriculumTopicApiCall,
    actionToGetGradeSubjectTopicApiCall,
    actionToGetTopicsApiCall
} from "../../../store/action/curriculumAction";
import {callDeleteDataWithLogFunction, callInsertDataWithLogFunction} from "../../../store/action/commonActions";
import useAuth from "../../../hooks/useAuth";
import SelectGradeDropdown from "../grade/SelectGradeDropdown";
import SelectSubjectDropdown from "../subject/SelectSubjectDropdown";
import SelectGradeSubjectTopicDropdown from "../topic/SelectGradeSubjectTopicDropdown";

const AddGradeSubjectTopicCurriculum = (props) => {
    const dispatch = useDispatch();
    const {auth} = useAuth();
    const [errors, setErrors] = useState({});
    const eventTypeId = useSelector((state) =>  state.curriculum.curriculumEventTypeId);
    const gradeSubjectTopicCurriculumListData = useSelector((state) =>  state.curriculum.gradeSubjectTopicCurriculumListData);
    const selectedData = props.selectedData;
    const [formData, setFormData] = useState({  });

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedFormData = {
            ...formData,
            [name]: value
        };
        setFormData(updatedFormData);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formValue = new FormData(e.target);
        const obj = Object.fromEntries(formValue.entries());
        const newErrors = await validateForm(obj);
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
                dispatch(callInsertDataWithLogFunction({
                    tableName: 'curriculum_grade_subject_topic',
                    data: {grade_subject_topic_id:obj['grade_subject_topic_id'],curriculum_id:selectedData.id},
                    userId: auth?.id,
                    eventTypeId: eventTypeId
                })).then(() => {
                    dispatch(actionToGetGradeCurriculumTopicApiCall({curriculum_id: selectedData?.id}))
                    resetForm();
                });
        }
    };
    const resetForm = () => {
        document.getElementById('grade-subject-topic-curriculum')?.reset();
    };

    const validateForm = (data) => {
        const errors = {};
        if (!data.grade_id.trim()) {
            errors.grade_id = 'Grade is required';
        }

        if (!data.subject_id.trim()) {
            errors.subject_id = 'Subject is required';
        }
        if (!data.grade_subject_topic_id.trim()) {
            errors.grade_subject_topic_id = 'Topic is required';
        }

        return errors;
    };
    const deleteCurriculumTopic = (id) =>{
        swal({
            title: "Are you sure?",
            text: "You want to delete this curriculum topic ",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (success) => {
                if (success) {
                    dispatch(callDeleteDataWithLogFunction({tableName:'curriculum_grade_subject_topic',id:id,userId:auth?.id,eventTypeId})).then(() => {
                        dispatch(actionToGetGradeCurriculumTopicApiCall({curriculum_id: selectedData?.id}))
                    })
                }
            });

    }

    useEffect(()=>{
        if(selectedData?.id && props.isOpen){
            setFormData({});
            dispatch(actionToGetGradeCurriculumTopicApiCall({curriculum_id: selectedData?.id}))
            dispatch(actionToGetGradeSubjectTopicApiCall())
        }
    },[selectedData,props.isOpen])
    return (
        <div>
            <Modal isOpen={props.isOpen} onRequestClose={props.onRequestClose} className='region-state-modal'>

                    <div className='container'>
                        <div className='title'>Grade Subject Topic</div>
                        <form id={'grade-subject-topic-curriculum'} onSubmit={handleSubmit}>
                            <SelectGradeDropdown errors={errors} name={'grade_id'} grade_id={formData.grade_id} handleChange={handleChange}/>
                            <SelectSubjectDropdown errors={errors} name={'subject_id'} subject_id={formData.subject_id} handleChange={handleChange}/>
                            {formData.grade_id && formData.subject_id && <SelectGradeSubjectTopicDropdown  errors={errors} name={'grade_subject_topic_id'} subject_id={formData.subject_id} grade_id={formData.grade_id} grade_subject_topic_id={formData.grade_subject_topic_id} handleChange={handleChange}/>}
                            <button type={"submit"}>Add Grade Subject Topic</button>
                        </form>
                        <table className="image-table">
                            <thead>
                            <tr>
                                <th>Sr No</th>
                                <th>Grade Name</th>
                                <th>Subject Name</th>
                                <th>Topic Name</th>
                                <th>Delete</th>
                            </tr>
                            </thead>
                            <tbody>
                            {gradeSubjectTopicCurriculumListData?.map((topic,index) => (
                                <tr key={topic.id}>
                                    <td>{index+1}</td>
                                    <td>{topic.grade_name}</td>
                                    <td>{topic.subject_name}</td>
                                    <td>{topic.topic_name}</td>
                                    <td> <FontAwesomeIcon className='action-icon' icon={faTrash} onClick={()=> deleteCurriculumTopic(topic.id)} /></td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
            </Modal>
        </div>
    );
};

export default AddGradeSubjectTopicCurriculum;
