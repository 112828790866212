import {api} from '../../hooks/api/ApiConfig.js';
import CryptoJS from "crypto-js";
import {
    CUSTOMER_LIST,
    UPDATE_VENDOR_FORM_DATA, UPDATE_CUSTOMER_FORM_DATA, VENDOR_LIST,
    PRODUCT_DETAILS_DATA_CP, STATE_LIST, CITY_LIST, ZIP_CODE_LIST
} from "../constant";
import {REGION_LIST} from "../constant/salesConstant";

const ENCRYPTION_KEY = "XkhZG4fW2t2W";


export const callInsertDataFunction = (payload) => async () => {
    try {
        const { data } = await api.post('common/insertCommonApiCall',payload);
        return data;
    } catch (error) {
        console.log(error,'error');
    }
}
export const callDeleteDataFunction = (payload) => async () => {
    try {
        const { data } = await api.post('common/deleteCommonApiCall',payload);
        return data;
    } catch (error) {
        console.log(error,'error');
    }
}
export const commonUpdateFunction = (payload) => async () => {
    try {
        const { data } = await api.post('common/updateCommonApiCall',payload);
        return data;
    } catch (error) {
        console.log(error,'error');
    }
}
export const actionToCreateVendor = (payload) => async (dispatch) =>{
    const aliasArray = ['?','?','?','?', '?', '?', '?', '?', '?', '?','?', '?'];
    const columnArray = ['name','email','mobile','created_by', 'address', 'company_name', 'bank_name', 'account_holder_name', 'account_number', 'ifsc_code', 'upi_id', 'photo'];
    const valuesArray = [payload?.vendorName,payload?.email,payload?.mobileNumber,'41241Z41G_user_41G41n41M', payload?.address, payload?.companyName, payload?.bankName, payload?.accountName, payload?.accountNumber, payload?.ifscCode, payload?.upiId, '1'];
    const insertData = {alias:aliasArray,column:columnArray,values:valuesArray,tableName:'vendor'};
    let response = await dispatch(callInsertDataFunction(insertData));
    return response;
}

export const actionToCreateSales = (payload) => async (dispatch) =>{
    const aliasArray = ['?','?','?','?', '?', '?', '?', '?'];
    const columnArray = ['customer_id','order_date','order_status','total_products', 'sub_total', 'invoice_number', 'payment_type', 'payment_status'];
    const valuesArray = [payload?.customer_id,payload?.order_date,payload?.order_status, payload?.total_products, payload?.sub_total, payload?.invoice_number, payload?.payment_type, payload?.payment_status];
    const insertData = {alias:aliasArray,column:columnArray,values:valuesArray,tableName:'order'};
    let response = await dispatch(callInsertDataFunction(insertData));
    return response;
}

export const actionToCreateCustomer = (payload) => async (dispatch) =>{
    const aliasArray = ['?','?','?','?', '?', '?', '?', '?', '?', '?','?', '?'];
    const columnArray = ['name','email','mobile','created_by', 'address', 'school_name', 'bank_name', 'account_holder_name', 'account_number', 'ifsc_code', 'upi_id', 'photo'];
    const valuesArray = [payload?.customerName,payload?.email,payload?.mobileNumber,'41241Z41G_user_41G41n41M', payload?.address, payload?.schoolName, payload?.bankName, payload?.accountName, payload?.accountNumber, payload?.ifscCode, payload?.upiId, '1'];
    const insertData = {alias:aliasArray,column:columnArray,values:valuesArray,tableName:'customer'};
    let response = await dispatch(callInsertDataFunction(insertData));
    return response;
}

export const actionToSetUpdateFromData = (data) => async (dispatch) => {
    dispatch({type: UPDATE_VENDOR_FORM_DATA, payload: data});
}
export const actionToSetUpdateFromCustomerData = (data) => async (dispatch) => {
    dispatch({type: UPDATE_CUSTOMER_FORM_DATA, payload: data});
}

export const actionToGetVendorApiCall = () => async (dispatch) => {
    dispatch({ type: VENDOR_LIST, payload: 'loading'});
    const {data} = await api.post(`inventory/actionToGetVendorApiCall`);
    dispatch({ type: VENDOR_LIST, payload: data});
    return data;
}

export const actionToGetCustomerApiCall = () => async (dispatch) => {
    dispatch({ type: CUSTOMER_LIST, payload: 'loading'});
    const {data} = await api.post(`inventory/actionToGetCustomerApiCall`);
    dispatch({ type: CUSTOMER_LIST, payload: data});
    return data;
}

export const actionToGetProductDetailsDataApiCall = (payload) => async (dispatch) => {
    const {data} = await api.post(`products/actionToGetProductsDetailsByIdApiCall`,{payload:CryptoJS.AES.encrypt(JSON.stringify(payload), ENCRYPTION_KEY).toString()});
    let responseDataString = CryptoJS.AES.decrypt(data, ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
    let productData = JSON.parse(responseDataString);
    dispatch({ type: PRODUCT_DETAILS_DATA_CP, payload:{data: productData,loading:false}});
    return data;
}

export const actionToGetStateListApiCall = (payload) => async (dispatch) => {
    const {data} = await api.post(`common/get-state-list`,{user_id:payload?.user_id});
    dispatch({ type: STATE_LIST, payload: data });
    return data;
}
export const actionToGetCityListApiCall = (payload) => async (dispatch) => {
    const {data} = await api.post(`common/get-city-list`,{user_id:payload?.user_id});
    dispatch({ type: CITY_LIST, payload: data });
    return data;
}
export const actionToGetPinCodeListApiCall = (payload) => async (dispatch) => {
    const {data} = await api.post(`common/get-pin-code-list`,{user_id:payload?.user_id});
    dispatch({ type: ZIP_CODE_LIST, payload: data });
    return data;
}