import {useSelector} from "react-redux";
import React from "react";

export const PinCodeText = (props) => {
    const zipCodeList = useSelector((state) =>  state.common.zipCodeList);
    const handleChange = (e) =>{
        let val =e.target.value;
        let value = val?.length ==6 &&zipCodeList[val]?.length >0 && zipCodeList[val][0];
        let payload = value ? value : {pincode:val};
        props.updatePinCode(payload);
    }
    return(

        <div className='input-box'>
            <input type='text' placeholder='PinCode' name='pincode' className='form-input' onChange={handleChange} value={props.pin_code}/>
            {props.errors[props.name] && <span className="error-message"> {props.errors[props.name]}</span> }
        </div>
    )
}