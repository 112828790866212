import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import CreatableSelect from 'react-select/creatable';
import {callInsertDataWithLogFunction} from "../../../../store/action/commonActions";
import {actionToGetCategoriesApiCall} from "../../../../store/action/inventoryActions";
import useAuth from "../../../../hooks/useAuth";

const CategoryDropdown=(props) =>{
    const eventTypeId = useSelector((state) =>  state.inventory.inventoryEventTypeId);
    const errors =props.errors;
    const source =props.source;
    const listOfCategories = useSelector((state) => { return state.inventory?.categoryData});
    const [selectedDropDown, setSelectedDropDown] = useState({});
    const {auth} = useAuth();
    const dispatch = useDispatch();
    const [categoryDropdown,setCategoryDropdown]= useState([]);
    useEffect(() => {
        let categoryArray = [];
        listOfCategories !== 'loading' && listOfCategories?.map(val=>{
            if(!source || (source && val.source == source)){
                categoryArray.push({value:val.id,label:val.name});

                if(props?.category == val.id) setSelectedDropDown({value:val.id,label:val.name});
            }
        })
        setCategoryDropdown(categoryArray);
    }, [listOfCategories,source,props.category]);
    const handleCreate = (val) => {
        if (val) {
            let obj ={name:val,created_by:auth?.id,source:source};
            dispatch(callInsertDataWithLogFunction({tableName:'categories',data:obj,userId:auth?.id,eventTypeId})).then(res => {
                if(res?.id){
                    props.handleChange({target:{name:props?.name,value: res.id}});
                    dispatch(actionToGetCategoriesApiCall());
                }
            })
        }
    };
    return(
        <div className='input-box'>
            <span className='details'>Select Category</span>
            <CreatableSelect name={props.name}
                    value={selectedDropDown}
                    onCreateOption={handleCreate}
                    onChange={(e)=>props.handleChange({target:{name:props.name,value:e.value}})}
                    options={categoryDropdown}
            />
            {errors[props.name] && <span className="error-message">{errors[props.name]} </span> }
        </div>
    )
}
export default CategoryDropdown;
