import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import {useDispatch, useSelector} from "react-redux";
import {callUpdateDataWithLogFunction} from "../../../../store/action/commonActions";
import {actionToGetMenuListApiCall} from "../../../../store/action/webSettingActions";
import useAuth from "../../../../hooks/useAuth";
import SelectComponentDropdown from "./SelectComponentDropdown";


const EditMenu= ({ isOpen,selectedData, onRequestClose }) => {
    const eventTypeId = useSelector((state) =>  state.webSetting.webSettingEventTypeId);
    const menuDataList = useSelector((state) =>  state.webSetting.menuDataList);
    const dispatch = useDispatch();
    const {auth} = useAuth();
    const [isFormChanged, setIsFormChanged] = useState(false);
    const [formData, setFormData] = useState({
        name: ''
    });

    const [errors, setErrors] = useState({});
    const [saveStatus, setSaveStatus] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedFormData = {
            ...formData,
            [name]: value
        };
        setFormData(updatedFormData);

        // Check if the updated form data differs from the initial data
        setIsFormChanged(JSON.stringify(updatedFormData) !== JSON.stringify(selectedData));
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        const formValue = new FormData(e.target);
        const obj = Object.fromEntries(formValue.entries());
        const newErrors = validateForm(obj);
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            if (!obj['parent_id']?.trim()){
                delete obj['parent_id'];
            }
            dispatch(callUpdateDataWithLogFunction({tableName:'menu',data:obj,id:selectedData?.id,userId:auth?.id,eventTypeId})).then(res => {
                let status = res.success ===1 ? 'success' : 'fail'
                setSaveStatus(status);
                if(status === 'success'){
                    dispatch(actionToGetMenuListApiCall());
                    onRequestClose();
                }
            })
        } else {
            setSaveStatus('fail');
        }
    };

    useEffect(() => {
        setFormData(selectedData);
    }, [selectedData]);

    const validateForm = (data) => {
        const errors = {};

        if (!data.name.trim()) {
            errors.name = 'Menu Name is required';
        }

        return errors;
    };
    useEffect(() => {
        setErrors({});
        setSaveStatus({});
    }, [isOpen]);
    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='Modal' ariaHideApp={false}>
            <div className="container">
                <div className='title'>Edit Menu</div>
                <form action='#' onSubmit={(e) => handleSubmit(e)}>
                    <div className='vendor-details'>
                        <div className='input-box'>
                            <span className='details'>Menu</span>
                            <select className='select' placeholder='Website Menu'  name="parent_id" onChange={handleChange}>
                                <option value="">Select Menu</option>
                                {menuDataList !== 'loading' && menuDataList?.map(menuData => {
                                    return (formData?.source == menuData.source) && <option key={menuData?.id} value={menuData?.id} selected={menuData?.id === formData?.parent_id}>{menuData?.name}</option>
                                })}
                            </select>
                        </div>
                        <div className='input-box'>
                            <span className='details'>Name</span>
                            <input type='text' placeholder='Menu Name*' name='name' className='form-input'  value={formData?.name}  onChange={handleChange}/>
                            {errors?.name && <span className="error-message">{errors.name} </span>}
                        </div>
                        <div className='input-box'>
                            <span className='details'>Url</span>
                            <input type='text' placeholder='Menu Url*' name='url' className='form-input' value={formData?.url}  onChange={handleChange}/>
                            {errors.url &&  <span className="error-message"> {errors.url} </span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Tooltip</span>
                            <input type='text' placeholder='Menu Tooltip' name='tooltip' className='form-input' value={formData?.tooltip}  onChange={handleChange}/>
                            {errors.tooltip &&  <span className="error-message"> {errors.tooltip} </span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Ordering</span>
                            <input type='number' placeholder='Menu Ordering' name='ordering' className='form-input' value={formData?.ordering}  onChange={handleChange}/>
                            {errors.ordering &&  <span className="error-message"> {errors.ordering} </span> }
                        </div>
                        <SelectComponentDropdown component_type={'menu'} name={'component_id'}  errors={errors} component_id={formData?.component_id} handleChange={handleChange}/>
                        <div className='input-box'>
                            <span className='details'>Exact Url</span>
                            <select className='select' name='exact' placeholder='Exact Url' value={formData?.exact} onChange={handleChange}>
                                <option value="1" >Yes</option>
                                <option value="0" selected={true}>No</option>
                            </select>
                            {errors.exact && <span className="error-message"> {errors.exact}</span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Show in Mobile Navbar</span>
                            <select className='select' name='show_in_mobile_navbar' placeholder='Show in Mobile Navbar' value={formData?.show_in_mobile_navbar} onChange={handleChange}>
                                <option value="1" >Yes</option>
                                <option value="0" selected={true}>No</option>
                            </select>
                            {errors.show_in_mobile_navbar && <span className="error-message"> {errors.show_in_mobile_navbar}</span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Show in Web Navbar</span>
                            <select className='select' name='show_in_web_navbar' placeholder='Show in Web Navbar' value={formData?.show_in_web_navbar} onChange={handleChange}>
                                <option value="1" >Yes</option>
                                <option value="0" selected={true}>No</option>
                            </select>
                            {errors.show_in_web_navbar && <span className="error-message"> {errors.show_in_web_navbar}</span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Footer Menu</span>
                            <select className='select' name='footer_menu' placeholder='Footer Menu' value={formData?.footer_menu} onChange={handleChange}>
                                <option value="1" >Yes</option>
                                <option value="0" selected={true}>No</option>
                            </select>
                            {errors.footer_menu && <span className="error-message"> {errors.footer_menu}</span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Footer Menu</span>
                            <select className='select' name='second_footer_menu' placeholder='Second Footer Menu' value={formData?.second_footer_menu} onChange={handleChange}>
                                <option value="1" >Yes</option>
                                <option value="0" selected={true}>No</option>
                            </select>
                            {errors.second_footer_menu && <span className="error-message"> {errors.second_footer_menu}</span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Display Only Logged in</span>
                            <select className='select' name='display_only_logged_in' placeholder='Display Only Logged in' value={formData?.display_only_logged_in} onChange={handleChange}>
                                <option value="1" selected={formData?.display_only_logged_in === '1'}>Yes</option>
                                <option value="0" selected={formData?.display_only_logged_in === '0'}>No</option>
                            </select>
                            {errors.display_only_logged_in && <span className="error-message"> {errors.display_only_logged_in}</span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Show in Left Side</span>
                            <select className='select' name='left_menu' placeholder='Show in Left Side' value={formData?.left_menu} onChange={handleChange}>
                                <option value="1" >Yes</option>
                                <option value="0" selected={true}>No</option>
                            </select>
                            {errors.left_menu && <span className="error-message"> {errors.left_menu}</span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Show in Right Side</span>
                            <select className='select' name='right_menu' placeholder='Show in Right Side' value={formData?.right_menu} onChange={handleChange}>
                                <option value="1" >Yes</option>
                                <option value="0" selected={true}>No</option>
                            </select>
                            {errors.right_menu && <span className="error-message"> {errors.right_menu}</span> }
                        </div>
                    </div>
                    <div className='buttons'>
                        <div className='button'>
                            <button className='submit-button' type='submit'  disabled={!isFormChanged}>Update</button>
                        </div>
                        <div className='button'>
                            <button className='close-button' onClick={onRequestClose}>Close</button>
                        </div>
                    </div>
                    {saveStatus==='success' ? <div className="success-message">Menu Updated successfully!!</div> : saveStatus==='fail' && <div className='failure-message'>Something went Wrong</div> }
                </form>

            </div>
        </Modal>
    );
};


export default EditMenu;
