import InventoryComponent from "../Components/inventory/InventoryComponent.js";
import {Navigate, Route, Routes} from "react-router-dom";
import React from "react";
export default function inventory() {
    return (
        <>
            <Routes>
                <Route path="" exact element={<Navigate to="inventory" replace />} />
                <Route path="/:tabName" element={<InventoryComponent />} />
            </Routes>
        </>
    )
}