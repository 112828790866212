import {api} from "../../hooks/api/ApiConfig";
import {REGION_LIST, SCHOOL_LIST} from "../constant/salesConstant";

export const actionToGetSchoolsApiCall = (payload) => async (dispatch) => {
    const {data} = await api.post(`sales/get-schools`,{user_id:payload?.user_id});
    dispatch({ type: SCHOOL_LIST, payload: data });
    return data;
}
export const actionToGetRegionsApiCall = (payload) => async (dispatch) => {
    const {data} = await api.post(`sales/get-regions`,{user_id:payload?.user_id});
    dispatch({ type: REGION_LIST, payload: data });
    return data;
}