import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";

function Details() {
    return (

        <div>
            Details
        </div>
    )
}

export default Details;

