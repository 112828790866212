import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import Select from "react-select";

const SelectCurriculumDropdown = (props) =>{
    const curriculumListData = useSelector((state) =>  state.curriculum.curriculumListData);
    const [componentDropdown, setComponentDropdown] = useState([]);
    const [selectedDropDown, setSelectedDropDown] = useState({});

    //Add your search logic here.
    const customFilter = (option, searchText) => {
        if(searchText.trim() === ''){
            return true;
        } else if(
            option.data.label?.toLowerCase().includes(searchText.toLowerCase()) ||
            option.data.id?.toLowerCase().includes(searchText.toLowerCase()) ||
            option.data.slug?.toLowerCase().includes(searchText.toLowerCase()) ||
            option.data.focus?.toLowerCase().includes(searchText.toLowerCase())
        ) {
            return true;
        } else {
            return false;
        }
    };
    useEffect(() => {
        let componentArray = [];
        let selectedData = {};
        curriculumListData !== 'loading'  && curriculumListData?.map(val=>{
            componentArray.push({value:val.id,label:val.name});
            if(props?.curriculum_id == val.id) selectedData={value:val.id,label:val.name};
        })
        setSelectedDropDown(selectedData);
        setComponentDropdown([...componentArray]);
    }, [curriculumListData,props?.curriculum_id]);
    return(
    <div className='input-box'>
        <span className='details'>Select Curriculum</span>
        <Select name={props.name}
                value={selectedDropDown}
                filterOption={customFilter}
                onChange={(e)=>props.handleChange({target:{name:props?.name,value:e.value}})}
                options={componentDropdown}
        />
        {props.errors[props.name] && <span className="error-message"> {props.errors[props.name]}</span> }
    </div>
    )
}
export default SelectCurriculumDropdown;