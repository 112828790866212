import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import Select from "react-select";

const SelectCityDropdown = (props) =>{
    const cityList = useSelector((state) =>  state.common.cityList);
    const [componentDropdown, setComponentDropdown] = useState([]);
    const [selectedDropDown, setSelectedDropDown] = useState({});

    useEffect(() => {
        let componentArray = [];
        let selectedData = {};
        let value = cityList[props?.state_id];
        console.log(props?.city_id,'city')
        value?.map(val=>{
                componentArray.push({value:val.id,label:val.name});
                if(props?.city_id == val.id) selectedData={value:val.id,label:val.name};
        })
        setSelectedDropDown(selectedData);
        setComponentDropdown([...componentArray]);
    }, [cityList,props?.state_id,props?.city_id]);
    return(
        <div className='input-box'>
            <span className='details'>Select City</span>
            <Select name={props.name}
                    value={selectedDropDown}
                    onChange={(e)=>props.handleChange({target:{name:props?.name,value:e.value}})}
                    options={componentDropdown}
            />
            {props.errors[props.name] && <span className="error-message"> {props.errors[props.name]}</span> }
        </div>
    )
}
export default SelectCityDropdown;