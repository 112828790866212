import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import Select from "react-select";

const SelectStateDropdown = (props) =>{
    const stateListData = useSelector((state) =>  state.common.stateList);
    const [componentDropdown, setComponentDropdown] = useState([]);
    const [selectedDropDown, setSelectedDropDown] = useState({});

    useEffect(() => {
        let componentArray = [];
        let selectedData = {};
        console.log(props?.state_id,'state_id')
        stateListData !== 'loading'  && Object.values(stateListData)?.map(val=>{
            componentArray.push({value:val.id,label:val.name});
            if(props?.state_id == val.id) selectedData={value:val.id,label:val.name};
        })
        setSelectedDropDown(selectedData);
        setComponentDropdown([...componentArray]);
    }, [stateListData,props?.state_id]);
    return(
        <div className='input-box'>
            <span className='details'>Select State</span>
            <Select name={props.name}
                    value={selectedDropDown}
                    onChange={(e)=>props.handleChange({target:{name:props?.name,value:e.value}})}
                    options={componentDropdown}
            />
            {props.errors[props.name] && <span className="error-message"> {props.errors[props.name]}</span> }
        </div>
    )
}
export default SelectStateDropdown;