import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import {useDispatch, useSelector} from "react-redux";
import {
    actionToGetProductCurriculumApiCall
} from "../../../../../store/action/inventoryActions";
import useAuth from "../../../../../hooks/useAuth";
import {callUpdateDataWithLogFunction} from "../../../../../store/action/commonActions";


const EditProductCurriculum= ({ isOpen,selectedProductCurriculum, onRequestClose }) => {
    const dispatch = useDispatch();
    const eventTypeId = useSelector((state) =>  state.curriculum.curriculumEventTypeId);
    // State to track if any input has changed
    const [isFormChanged, setIsFormChanged] = useState(false);
    const {auth} = useAuth();
    const [formData, setFormData] = useState({
        name: '',
        slug: '',
        source:''
    });

    const [errors, setErrors] = useState({});
    const [saveStatus, setSaveStatus] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedFormData = {
            ...formData,
            [name]: value
        };
        setFormData(updatedFormData);

        // Check if the updated form data differs from the initial data
        setIsFormChanged(JSON.stringify(updatedFormData) !== JSON.stringify(selectedProductCurriculum));
    };
    const handleSubmit = (e) => {
        e.preventDefault()
        const formValue = new FormData(e.target);
        const obj = Object.fromEntries(formValue.entries());
        const newErrors = validateForm(obj);
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            dispatch(callUpdateDataWithLogFunction({tableName:'curriculum',data:obj,id:selectedProductCurriculum?.id,userId:auth?.id,eventTypeId})).then(res => {
                let status = res?.success ===1 ? 'success' : 'fail'
                setSaveStatus(status);
                if(status === 'success'){
                    dispatch(actionToGetProductCurriculumApiCall({productId:selectedProductCurriculum.product_id}));
                    onRequestClose();
                }
            })
        } else {
            setSaveStatus('fail');
        }
    };

    useEffect(() => {
        setFormData(selectedProductCurriculum);
    }, [selectedProductCurriculum]);

    const validateForm = (data) => {
        const errors = {};

        if (!data.name.trim()) {
            errors.name = 'Curriculum name is required';
        }

        if (!data.slug.trim()) {
            errors.slug = 'Slug is required';
        }

        return errors;
    };
    useEffect(() => {
        setErrors({});
        setSaveStatus({});
    }, [isOpen]);
    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='Modal'>
            {}
            <div className="container">
                <div className='title'>Edit Product Curriculum</div>
                <form action='#' onSubmit={(e) => handleSubmit(e)}>
                    <div className='vendor-details'>
                        <div className='input-box'>
                            <input type='text'
                                   placeholder='Enter Name of the Product Curriculum'
                                   name='name'
                                   value={formData?.name}
                                   onChange={handleChange}
                            />
                            {errors.name &&  <span className="error-message">{errors.name} </span>}
                        </div>
                        <div className='input-box'>
                            <input type='text'
                                   placeholder='Enter Slug of the Product Curriculum'
                                   name='slug'
                                   value={formData?.slug}
                                   onChange={handleChange}
                            />
                            {errors.slug &&  <span className="error-message">{errors.slug} </span>}
                        </div>
                        <div className='input-box'>
                            <input type='text'
                                   placeholder='Enter Topic name of the Product Curriculum'
                                   name='focus'
                                   value={formData?.focus}
                                   onChange={handleChange}
                            />
                            {errors.focus &&  <span className="error-message">{errors.focus} </span>}
                        </div>
                        <div className='input-box'>
                            <textarea className='text-area'  placeholder='Enter Short Description of the Product Curriculum' name='description' value={formData?.description} onChange={handleChange}/>
                            {errors.description && <span className="error-message">{errors.description} </span> }
                        </div>
                    </div>
                    <div className='buttons'>
                        <div className='button'>
                            <button className='submit-button' type='submit' disabled={!isFormChanged}>Update</button>
                        </div>
                        <div className='button'>
                            <button className='close-button' onClick={onRequestClose}>Close</button>
                        </div>
                    </div>
                    {saveStatus==='success' ? <div className="success-message">Product Curriculum Updated successfully!!</div> : saveStatus==='fail' && <div className='failure-message'>Something went Wrong</div> }
                </form>
            </div>
        </Modal>
    );
};


export default EditProductCurriculum;
