import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
function ProductDetails({productDetailsData}) {

    return (
        <div>
            <section>
                <div className='product-head'>
                    <h4 className='mb-1'>{productDetailsData?.name}</h4>
                    <h6 className='mb-4'>SKU:000{productDetailsData?.id}</h6>
                </div>
                <h5 className='mb-4 price'>₹ {productDetailsData?.price} </h5>
                <p dangerouslySetInnerHTML={{__html:productDetailsData?.sortDesc}} />
            </section>
            <div className={"border-bottom pb-4"}>
                <h4>Product's Description:</h4>
                <p dangerouslySetInnerHTML={{__html:productDetailsData?.description}} />
            </div>
        </div>
    )
}

export default ProductDetails;

