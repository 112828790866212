import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import CreatableSelect from 'react-select/creatable';
import {callInsertDataWithLogFunction} from "../../../../store/action/commonActions";
import {actionToGetBrandsApiCall} from "../../../../store/action/inventoryActions";
import useAuth from "../../../../hooks/useAuth";

const SelectBrandDropdown = (props) =>{
    const brandData = useSelector((state) =>  state.inventory?.brandData);
    const eventTypeId = useSelector((state) =>  state.inventory.inventoryEventTypeId);
    const [componentDropdown, setComponentDropdown] = useState([]);
    const [selectedDropDown, setSelectedDropDown] = useState({});
    const {auth} = useAuth();
    const dispatch=useDispatch();

    useEffect(() => {
        let componentArray = [];
        let selectedData = {};
        brandData !== 'loading'  && brandData?.map(val=>{
            componentArray.push({value:val.id,label:val.name});
            if(props?.brand_id == val.id) selectedData={value:val.id,label:val.name};
        })
        setSelectedDropDown(selectedData);
        setComponentDropdown([...componentArray]);
    }, [brandData,props?.brand_id]);
    const handleCreate = (val) => {
        if (val) {
            let obj ={name:val,created_by:auth?.id};
            dispatch(callInsertDataWithLogFunction({tableName:'brand',data:obj,userId:auth?.id,eventTypeId})).then(res => {
                if(res?.id){
                    props.handleChange({target:{name:props?.name,value: res.id}});
                    dispatch(actionToGetBrandsApiCall());
                }
            })
        } 
    };
    return(
    <div className='input-box'>
        <span className='details'>Select Brand</span>
        <CreatableSelect name={props.name}
                value={selectedDropDown}
                onCreateOption={handleCreate}
                onChange={(e)=>props.handleChange({target:{name:props?.name,value:e.value}})}
                options={componentDropdown}
        />
        {props.errors[props.name] && <span className="error-message"> {props.errors[props.name]}</span> }
    </div>
    )
}
export default SelectBrandDropdown;