import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import Select from "react-select";

const ProductDropdown=(props) =>{
    const errors =props.errors;
    const source =props.source;
    const productsData = useSelector((state) =>  state.inventory?.productData);
    const [productDropdown,setProductDropdown]= useState([]);
    const [defaultValue,setDefaultValue]= useState({});
    useEffect(() => {
        let productArray = [];
        let defaultObject = {};
        productsData !== 'loading' && productsData?.map(val=>{
            if(val.id ==props.product){
                defaultObject = {value:val.id,label:val.name,category_name:val.category_name};

            }
            if(!source || (source && val.source == source))
                productArray.push({value:val.id,label:val.name,category_name:val.category_name});
        })
        setDefaultValue(defaultObject);
        setProductDropdown(productArray);
    }, [productsData,source,props.product]);
    //Add your search logic here.
    const customFilter = (option, searchText) => {
        if(searchText.trim() === ''){
            return true;
        } else if(
            option.data.label?.toLowerCase().includes(searchText.toLowerCase()) ||
            option.data.id?.toLowerCase().includes(searchText.toLowerCase()) ||
            option.data.category_name?.toLowerCase().includes(searchText.toLowerCase())
        ) {
            return true;
        } else {
            return false;
        }
    };
    return(
        <div className='input-box'>
            <span className='details'>Select Product</span>
            <Select name={props.name}
                    value={defaultValue}
                    onChange={(e)=>props.handleChange({target:{name:props.name,value:e.value}})}
                    filterOption={customFilter}
                    options={productDropdown} className='select'
            />
            {errors[props.name] && <span className="error-message">{errors[props.name]} </span> }
        </div>
    )
}
export default ProductDropdown;
