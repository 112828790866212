import React, {useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Virtuoso} from "react-virtuoso";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLaptopFile, faPenToSquare, faTrash} from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";
import {callSoftDeleteDataWithLogFunction, callUpdateDataWithLogFunction} from "../../../store/action/commonActions";
import {useEffectOnce} from "../../../hooks/useEffectOnce";
import useAuth from "../../../hooks/useAuth";
import { uploadFileOnAws} from "../../../store/action/inventoryActions";
import Tippy from "@tippyjs/react";
import EditSchool from "./EditSchool";
import {actionToGetSchoolsApiCall} from "../../../store/action/salesAction";
import PreLoader from "../../loader/PreLoader";

export const SchoolTable = () => {
    const [isEditSchoolOpen, setIsEditSchoolOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);
    const dispatch = useDispatch();
    const schoolData = useSelector((state) =>  state.sales.schoolList);
    const {auth} = useAuth();
    const hiddenFileInput = useRef();
    const eventTypeId = useSelector((state) =>  state.sales.salesEventTypeId);

    useEffectOnce(()=>{
        dispatch(actionToGetSchoolsApiCall());
    })

    const deleteSchool = (row) => {
        swal({
            title: "Are you sure?",
            text: "You want to delete this School "+row?.name,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (success) => {
                if (success) {
                    dispatch(callSoftDeleteDataWithLogFunction({tableName:'schools',id:row.id,userId:auth?.id,eventTypeId})).then(() => {
                        dispatch(actionToGetSchoolsApiCall())
                    })
                }
            });

    };
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedRows([]);
        } else {
            setSelectedRows(schoolData.map(item => item.id));
        }
        setSelectAll(!selectAll);
    };
    const handlePhotoClick = (index) => {
        setSelectedIndex(index);
        hiddenFileInput.current.click();    // ADDED
    };
    const handleMainPhotoUpload = async event => {
        const fileUploaded = event.target.files[0];
        const fileExtension = fileUploaded.name.split('.').pop();
        let data =schoolData[selectedIndex];
        let payload = {filename:'subject/'+data.name?.toLowerCase()+'.'+fileExtension,showOnWebsite:'stemcity',photo:fileUploaded,type:fileUploaded.type}
        data.photo ? payload['deletedFileName'] =  data.photo?.replace('https://stemcity.s3.ap-southeast-2.amazonaws.com/','') :'';
        let url = await dispatch(uploadFileOnAws(payload));
        dispatch(callUpdateDataWithLogFunction({tableName:'school',data: {photo:url},id:data?.id,userId:auth?.id,eventTypeId:eventTypeId})).then(() => {
            dispatch(actionToGetSchoolsApiCall());
        })
    };
    const handleSelectRow = (id) => {
        const newSelectedRows = selectedRows.includes(id)
            ? selectedRows.filter(rowId => rowId !== id)
            : [...selectedRows, id];

        setSelectedRows(newSelectedRows);
        setSelectAll(newSelectedRows.length === schoolData.length);
    };
    return(
        <>
            <div className='table-container'>
            {
                schoolData === 'loading' ?
                    <PreLoader/>
                    :
        <div className="table">
            <div className="row-header">
                <div className="three-column-cell">
                    <input className='checkbox' type="checkbox"
                           checked={selectAll}
                           onChange={()=>handleSelectAll()}/>
                    School Name
                </div>
                <div className="three-column-cell">School Icon</div>
                <div className="three-column-cell">Action</div>
            </div>
            <Virtuoso style={{ height: '600px' }} totalCount={schoolData.length} itemContent={index =>
                <div className="table-row">
                    <div className="three-column-cell">
                        <input className='checkbox' type="checkbox"
                               checked={selectedRows.includes(schoolData[index]?.id)}
                               onChange={() => handleSelectRow(schoolData[index]?.id)}/>
                        {schoolData[index]?.name}
                    </div>
                    <div className="three-column-cell" onClick={()=>handlePhotoClick(index)}>
                            <img src={schoolData[index]?.photo} height="35" alt={schoolData[index]?.name} />
                    </div>
                    <div className="three-column-cell">
                        <div className='action-components'>
                            <div className='action-buttons'>
                                <button className='action-button' onClick={() => {setIsEditSchoolOpen('edit_school'); setSelectedIndex(index);}} >
                                    <FontAwesomeIcon className='action-icon' icon={faPenToSquare} />
                                </button>
                            </div>
                            <div className='action-buttons'>
                                <button onClick={()=>deleteSchool(schoolData[index])} className='action-button'>
                                    <FontAwesomeIcon className='action-icon' icon={faTrash} />
                                </button>
                            </div>

                            <div className='action-buttons'>
                                <Tippy content='Add Topic'>
                                    <button className='action-button' onClick={() => {setIsEditSchoolOpen('grade_topic'); setSelectedIndex(index);}} >
                                        <FontAwesomeIcon className='action-icon' icon={faLaptopFile} />
                                    </button>
                                </Tippy>
                            </div>
                        </div>
                    </div>
                </div>
            } />
        </div>
            }

        <input type="file" onChange={handleMainPhotoUpload} ref={hiddenFileInput} style={{display: 'none'}} />
        <EditSchool isOpen={isEditSchoolOpen ==='edit_school'} selectedData={schoolData[selectedIndex]} onRequestClose={() => {setIsEditSchoolOpen(false); setSelectedIndex(false);}} />
    </div>
    </>
    )
}
