import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useEffectOnce} from "../../../../hooks/useEffectOnce";
import {Virtuoso} from "react-virtuoso";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBars,
    faFileCirclePlus,
    faPenToSquare,
    faSquarePlus,
    faTableList,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import PreLoader from "../../../loader/PreLoader";
import swal from "sweetalert";
import {callSoftDeleteDataWithLogFunction} from "../../../../store/action/commonActions";
import useAuth from "../../../../hooks/useAuth";
import Tippy from "@tippyjs/react";
import {actionToGetWebSettingSectionApiCall} from "../../../../store/action/webSettingActions";
import EditWebsiteSection from "./EditWebsiteSection";
import AddMenuSection from "./AddMenuSection";
import AddWebsiteContent from "./AddWebsiteSectionContent";
import {WebsiteSectionContentTable} from "./WebsiteSectionContentTable";
import {useParams} from "react-router-dom";
export const WebsiteSectionTable = (props) => {
    const [isEditSectionOpen, setIsEditSectionOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);
    const dispatch = useDispatch();
    const websiteSectionData = useSelector((state) =>  state.webSetting?.websiteSectionData);
    const {auth} = useAuth();
    const eventTypeId = useSelector((state) =>  state.webSetting.webSettingEventTypeId);
    const [selectedRows, setSelectedRows] = useState([]);
    const {menu_id} = useParams();
    const [tableData, setTableData] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    useEffectOnce(()=>{
        dispatch(actionToGetWebSettingSectionApiCall());
    })
    const websiteSectionFilterFunction = (menu_id) =>{
        let tableList=[];
        if(menu_id){
            let idArray=[];
            let idOrdering={};
            if( props.contentData){
                props.contentData?.map(content=>{
                    !idArray.includes(content.id) && idArray.push(content.id);
                    idOrdering[content.id]=content.ordering;
                })
                websiteSectionData?.map(dataArray=>{
                    if(idArray.includes(dataArray.id)){
                        dataArray['ordering']=idOrdering[dataArray.id];
                        tableList.push(dataArray);
                    }
                })
                tableList.sort(function(a, b){return a.ordering - b.ordering});
            }
        }else{
            tableList =websiteSectionData;
        }
        console.log(tableList,menu_id,props.contentData)
        setTableData(tableList);
    }
    useEffect(() => {
        websiteSectionData !=='loading' && websiteSectionData.length > 0 && websiteSectionFilterFunction(menu_id);
    }, [menu_id,websiteSectionData,props.contentData]);
    const deleteSection = (row) => {
        swal({
            title: "Are you sure?",
            text: "You want to delete this Section "+row?.name,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (success) => {
                if (success) {
                    dispatch(callSoftDeleteDataWithLogFunction({tableName:'website_section',id:row.id,userId:auth?.id,eventTypeId})).then(() => {
                        dispatch(actionToGetWebSettingSectionApiCall())
                    })
                }
            });

    };

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedRows([]);
        } else {
            setSelectedRows(tableData.map(item => item.id));
        }
        setSelectAll(!selectAll);
    };

    const handleSelectRow = (id) => {
        const newSelectedRows = selectedRows.includes(id)
            ? selectedRows.filter(rowId => rowId !== id)
            : [...selectedRows, id];

        setSelectedRows(newSelectedRows);
        setSelectAll(newSelectedRows.length === tableData.length);
    };
    return(
        <>
            <div className='table-container'>
            {
                websiteSectionData === 'loading' ?
                    <PreLoader/>
                    :
                    <>

                        <div className="website-table">
                            <div className="website-row-header">
                                <div className="seven-column-cell">
                                    <input className='checkbox' type="checkbox"
                                           checked={selectAll}
                                           onChange={()=>handleSelectAll()}/>
                                    Section Name
                                </div>
                                {menu_id?
                                <div className="seven-column-cell">Ordering</div>:
                                <div className="seven-column-cell">Website Name</div>}
                                <div className="seven-column-cell">Heading</div>
                                <div className="seven-column-cell">Sub Heading</div>
                                <div className="seven-column-cell">Class</div>
                                <div className="seven-column-cell">Call to Action Link</div>
                                <div className="seven-column-cell">Action</div>
                            </div>
                            <Virtuoso style={{ height: '600px' }} totalCount={tableData.length} itemContent={index =>
                                <div className="table-row">
                                    <div className="seven-column-cell">
                                        <input className='checkbox' type="checkbox"
                                               checked={selectedRows.includes(tableData[index]?.id)}
                                               onChange={() => handleSelectRow(tableData[index]?.id)}/>
                                        {tableData[index]?.name}
                                    </div>
                                    {menu_id? <div className="seven-column-cell"> {tableData[index]?.ordering}</div> :
                                    <div className="seven-column-cell"> {tableData[index]?.company_name}</div>}
                                    <div className="seven-column-cell"> {tableData[index]?.object_heading}</div>
                                    <div className="seven-column-cell"> {tableData[index]?.object_subheading}</div>
                                    <div className="seven-column-cell"> {tableData[index]?.object_class}</div>
                                    <div className="seven-column-cell"> {tableData[index]?.call_to_action_link}</div>
                                    <div className="seven-column-cell">
                                        <div className='action-components'>
                                            <div className='action-buttons'>
                                                <Tippy content='Add Menu'>
                                                    <button  className='action-button' onClick={() => {setIsEditSectionOpen('add_menu'); setSelectedIndex(index);}}>
                                                        <FontAwesomeIcon className='action-icon' icon={faSquarePlus} />
                                                    </button>
                                                </Tippy>
                                            </div>
                                            <div className='action-buttons'>
                                                <Tippy content='Add Content'>
                                                    <button  className='action-button' onClick={() => {setIsEditSectionOpen('add_website_content'); setSelectedIndex(index);}}>
                                                        <FontAwesomeIcon className='action-icon' icon={faFileCirclePlus} />
                                                    </button>
                                                </Tippy>
                                            </div>
                                            <div className='action-buttons'>
                                                <Tippy content='Content Table'>
                                                    <button  className='action-button' onClick={() => {setIsEditSectionOpen('website_content_table'); setSelectedIndex(index);}}>
                                                        <FontAwesomeIcon className='action-icon' icon={faTableList} />
                                                    </button>
                                                </Tippy>
                                            </div>
                                            <div className='action-buttons'>
                                                <button className='action-button' onClick={() => {setIsEditSectionOpen('edit_content'); setSelectedIndex(index);}} >
                                                    <FontAwesomeIcon className='action-icon' icon={faPenToSquare} />
                                                </button>
                                            </div>
                                            <div className='action-buttons'>
                                                <button onClick={()=>deleteSection(tableData[index])} className='action-button'>
                                                    <FontAwesomeIcon className='action-icon' icon={faTrash} />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            } />
                        </div>
                        <EditWebsiteSection isOpen={isEditSectionOpen === 'edit_content'} selectedData={tableData[selectedIndex]} onRequestClose={() => {setIsEditSectionOpen(false); setSelectedIndex(false);}} />
                        <AddMenuSection isOpen={isEditSectionOpen === 'add_menu'} selectedData={tableData[selectedIndex]} onRequestClose={() => {setIsEditSectionOpen(false); setSelectedIndex(false);}} />
                        <AddWebsiteContent isOpen={isEditSectionOpen === 'add_website_content'} selectedData={tableData[selectedIndex]} onRequestClose={() => {setIsEditSectionOpen(false); setSelectedIndex(false);}} />
                        <WebsiteSectionContentTable isOpen={isEditSectionOpen === 'website_content_table'} selectedData={tableData[selectedIndex]} onRequestClose={() => {setIsEditSectionOpen(false); setSelectedIndex(false);}} />

                    </>
            }

            </div>
            </>
    )
}
