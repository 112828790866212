import React, {useState,  useEffect} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faGlobe,
    faSchool,
    faUserPlus

} from "@fortawesome/free-solid-svg-icons";
import Tippy from "@tippyjs/react";
import 'tippy.js/dist/tippy.css';
import {Link, useParams} from "react-router-dom";
import {subDomainUrl} from "../../hooks/config";
import {SchoolTable} from "./school/SchoolTable";
import {RegionTable} from "./region/RegionTable";
import AddRegion from "./region/AddRegion";
import AddSchool from "./school/AddSchool";
import {useEffectOnce} from "../../hooks/useEffectOnce";
import {useDispatch} from "react-redux";
import {
    actionToGetCityListApiCall,
    actionToGetPinCodeListApiCall,
    actionToGetStateListApiCall
} from "../../store/action";


const SalesComponent = React.memo(function () {
    let {tabName} = useParams();
    const dispatch = useDispatch();
    const [addModalName, setAddModalName] = useState(false);
    const [activeTab, setActiveTab] = useState('inventory');

    const getTabTable =() =>{
        switch (activeTab){
            case 'client-lead':
                return <SchoolTable />;
            case 'region':
                return <RegionTable />;
            default:
                return <SchoolTable />;
        }
    }
    useEffect(() => {
        setActiveTab(tabName);
    }, [tabName]);
    useEffectOnce(()=>{
        dispatch(actionToGetStateListApiCall());
        dispatch(actionToGetCityListApiCall());
        dispatch(actionToGetPinCodeListApiCall());
    })
    return (
        <>
            <section className='section'>
                <div className='sub-header'>
                    <div className='subheader-box'>
                        <div className="tab-container">
                            <div className="tab">
                                <Link to={subDomainUrl+'/sales/school'} className='tab'><button
                                    className={`tab ${activeTab === 'school' ? 'active' : ''}`}>
                                   Schools
                                </button>
                                </Link>
                                <Link to={subDomainUrl+'/sales/region'} className='tab'><button
                                    className={`tab ${activeTab === 'region' ? 'active' : ''}`}>
                                   Region
                                </button>
                                </Link>
                                <Link to={subDomainUrl+'/sales/client-lead'} className='tab'><button
                                    className={`tab ${activeTab === 'client-lead' ? 'active' : ''}`}>
                                   Client Lead
                                </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className='subheader-box'>
                        <div className='subheader-components'>
                            <Tippy content='Add School'>
                                <button  className='common-button' onClick={() => setAddModalName('add_school')}>
                                    <FontAwesomeIcon className='filter-icon' icon={faSchool} />
                                </button>
                            </Tippy>
                            <Tippy content='Add Region'>
                                <button  className='common-button' onClick={() => setAddModalName('add_region')}>
                                    <FontAwesomeIcon className='filter-icon' icon={faGlobe} />
                                </button>
                            </Tippy>
                            <Tippy content='Add Contact Person'>
                                <button  className='common-button' onClick={() => setAddModalName('add_contact_person')}>
                                    <FontAwesomeIcon className='filter-icon' icon={faUserPlus} />
                                </button>
                            </Tippy>
                        </div>

                    </div>
                </div>
                <div className="tab-content">
                    {getTabTable()}
                </div>
                <AddRegion isOpen={addModalName === 'add_region'} onRequestClose={() => setAddModalName(false)} />
                <AddSchool isOpen={addModalName === 'add_school'} onRequestClose={() => setAddModalName(false)} />
            </section>
        </>
    )
});

export default SalesComponent;
