import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import {useDispatch, useSelector} from "react-redux";
import {
    actionToGetDiscountCouponForProductApiCall,
    actionToGetProductApiCall
} from "../../../../store/action/inventoryActions";
import {callUpdateDataWithLogFunction} from "../../../../store/action/commonActions";
import useAuth from "../../../../hooks/useAuth";
import Select from "react-select";
import {useEffectOnce} from "../../../../hooks/useEffectOnce";
const ProductDetailUpdate = ({ isOpen, onRequestClose,selectedProduct }) => {
    const dispatch = useDispatch();
    const {auth} = useAuth();
    const eventTypeId = useSelector((state) =>  state.inventory.inventoryEventTypeId);
    const discountCouponListData = useSelector((state) =>  state.inventory.discountCouponListData);
    const [formData, setFormData] = useState({slug:'' });
    const [isFormChanged, setIsFormChanged] = useState(false);
    const [discountCouponDropdown, setDiscountCouponDropdown] = useState([]);
    const [errors, setErrors] = useState({});
    const [saveStatus, setSaveStatus] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedFormData = {
            ...formData,
            [name]: value
        };
        setFormData(updatedFormData);

        // Check if the updated form data differs from the initial data
        setIsFormChanged(JSON.stringify(updatedFormData) !== JSON.stringify(selectedProduct));
    };
    const handleSubmit = async(e) => {
        e.preventDefault();
        const formValue = new FormData(e.target);
        const obj = Object.fromEntries(formValue.entries());
        const newErrors = validateForm(obj);
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
           dispatch(callUpdateDataWithLogFunction({tableName:'product_details',data:obj,id:selectedProduct?.product_detail_id,userId:auth?.id,eventTypeId})).then(res => {
                let status = res.success ===1 ? 'success' : 'fail'
                setSaveStatus(status);
                if(status === 'success'){
                    dispatch(actionToGetProductApiCall());
                    onRequestClose();
                }
            })
        } else {
            setSaveStatus('fail');
        }
    };
    useEffect(() => {
        if(selectedProduct?.id){
            setFormData(selectedProduct);
        }
    }, [selectedProduct]);

    const validateForm = (data) => {
        const errors = {};
        if (!data.slug.trim()) {
            errors.slug = 'Product Slug is required';
        }

        return errors;
    };
    useEffect(() => {
        let discountArray = [];
        discountCouponListData?.map(val=>{
            discountArray.push({value:val.id,label:val.name})
        })
        setDiscountCouponDropdown(discountArray);
    }, [discountCouponListData]);
    useEffectOnce(()=>{
        dispatch(actionToGetDiscountCouponForProductApiCall());
    })
    return (
        <div>
            <Modal isOpen={isOpen}  maxWidth="xl" onRequestClose={onRequestClose} className='Modal lg'>
                <div className='container'>
                    <div className='title'>Product Detail</div>
                    <form action='#' onSubmit={(e) => handleSubmit(e)}>
                        {selectedProduct?.id ?
                        <div className='vendor-details'>
                            <div className='input-box'>
                                <input type='text'
                                       placeholder='Enter Name of the Product Slug'
                                       name='slug'
                                       value={formData?.slug}
                                       onChange={handleChange}
                                       required/>
                                {errors.slug &&  <span className="error-message">{errors.slug} </span>}
                            </div>
                            <div className='input-box'>
                                <input type='number'
                                       placeholder='Minimum Age Eligible'
                                       name='slug'
                                       value={formData?.min_age}
                                       onChange={handleChange}
                                       required/>
                                {errors.min_age &&  <span className="error-message">{errors.min_age} </span>}
                            </div>
                            <div className='input-box'>
                                <input type='number'
                                       placeholder='Minimum Grade Eligible'
                                       name='max_age'
                                       value={formData?.max_age}
                                       onChange={handleChange}
                                       required/>
                                {errors.max_age &&  <span className="error-message">{errors.max_age} </span>}
                            </div>
                            <div className='input-box'>
                                <Select name={"discount_id"}
                                    defaultValue={formData?.discount_id}
                                    value={formData?.discount_id}
                                    onChange={(val)=>handleChange({target:{name:'discount_id',value:val}})}
                                    options={discountCouponDropdown}
                                />
                                {errors.discount_id &&  <span className="error-message">{errors.discount_id} </span>}
                            </div>
                        </div> : ''}
                        {saveStatus==='success' ? <div className="success-message">Product Updated successfully!!</div> : saveStatus==='fail' && <div className='failure-message'>Something went Wrong</div> }


                    <div className='buttons'>
                        <div className='button'>
                            <button className='submit-button' type='submit' disabled={!isFormChanged}>Update Product Detail</button>
                        </div>
                        <div className='button'>
                            <button className='close-button' onClick={onRequestClose}>Close</button>
                        </div>
                    </div>
                    </form>
                </div>

            </Modal>
        </div>
    );
};

export default ProductDetailUpdate;
