import React from 'react'
import logo from "../../assets/images/logo.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBoxOpen,
    faUserLarge,
    faRightFromBracket,
    faHouse,
    faPeopleGroup,
    faGears,
    faGraduationCap, faFile
} from "@fortawesome/free-solid-svg-icons";
import {Link, useNavigate} from 'react-router-dom';
import Tippy from "@tippyjs/react";
import {actionToLogout} from "../../store/action/usersActions";
import {useDispatch} from "react-redux";
import useAuth from "../../hooks/useAuth";
import {subDomainUrl} from "../../hooks/config";

function Header() {
    const {auth, setAuth } = useAuth();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const menuArray = [
        {url:subDomainUrl+'/home', toolTip: 'Home' , icon: faHouse,authorized_role:[1,2,3,4,5,6,7]},
        {url:subDomainUrl+'/gallery', toolTip: 'Gallery' , icon: faFile,authorized_role:[1,2,3,4,5,6,7]},
        {url:subDomainUrl+'/inventory', toolTip: 'Inventory' , icon: faBoxOpen,authorized_role:[1,2,5]},
        {url:subDomainUrl+'/users', toolTip: 'User' , icon: faUserLarge,authorized_role:[1,2]},
        {url:subDomainUrl+'/sales', toolTip: 'Sales Management' , icon: faPeopleGroup,authorized_role:[1,2,3]},
        {url:subDomainUrl+'/website-setting', toolTip: 'Website Setting' , icon: faGears,authorized_role:[1,2,4]},
        {url:subDomainUrl+'/curriculum', toolTip: 'Curriculum' , icon:faGraduationCap,authorized_role:[1,2,5,6]}
    ]

    const logout =()=>{
        dispatch(actionToLogout());
        setAuth({});
        navigate(subDomainUrl+'/login')
    }
  return (
      <>
          <div className='header'>
              <div className='header-logo'>
                  <img className='logo' alt={"shikshak solutions"} src={logo}/>
              </div>
              {menuArray.map((menu,index)=>{
                  if(menu.authorized_role?.includes(auth?.role)){
                      return <Link to={menu.url} key={index}>
                          <div className='subheader-components'>
                              <Tippy content={menu.toolTip}>
                                  <button className='header-icons'>
                                      <FontAwesomeIcon  icon={menu.icon} />
                                  </button>
                              </Tippy>
                          </div>
                      </Link>
                  }
              })}
              <div className='logout-icon' onClick={logout}>
              <Tippy content='Log Out'>
                  <button className='header-icons'>
                      <FontAwesomeIcon icon={faRightFromBracket} />
                  </button>
              </Tippy>
              </div>
          </div>
      </>
  )
}

export default Header;