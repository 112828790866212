import {api} from "../../hooks/api/ApiConfig";
import {COMPANY_LIST} from "../constant";
import AWS from "aws-sdk";

const AWS_ACCESS_KEY_ID='AKIA5BAMSD7MAS6P43HN';
const AWS_SECRET_ACCESS_KEY='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';
const AWS_DEFAULT_REGION='eu-north-1';
const AWS_BUCKET = 'shikshak-solutions';
const STEMCITY_AWS_ACCESS_KEY_ID='AKIAQFC27GCBHVGYNE5C';
const STEMCITY_AWS_SECRET_ACCESS_KEY='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';
const STEMCITY_AWS_DEFAULT_REGION='ap-southeast-2';
const STEMCITY_AWS_BUCKET = 'stemcity';

const S3Bucket = new AWS.S3({
    params: {Bucket: AWS_BUCKET},
    region: AWS_DEFAULT_REGION,
    accessKeyId: AWS_ACCESS_KEY_ID,
    secretAccessKey: AWS_SECRET_ACCESS_KEY
})

const S3StemcityBucket = new AWS.S3({
    params: {Bucket: STEMCITY_AWS_BUCKET},
    region: STEMCITY_AWS_DEFAULT_REGION,
    accessKeyId: STEMCITY_AWS_ACCESS_KEY_ID,
    secretAccessKey: STEMCITY_AWS_SECRET_ACCESS_KEY
})


export const actionToUploadDataToS3BucketStore = (payload) => async () => {
    const params = {
        ACL: 'public-read',
        Body: payload.blobData,
        Bucket: AWS_BUCKET,
        Key: payload.key,
        ContentType:payload.type
    };

    S3Bucket.putObject(params)
        .send((err) => {
            console.log(err,'error');
        })
    return `https://shikshak-solutions.s3.eu-north-1.amazonaws.com/${payload.key}`;
}

export const actionToDeleteAwsObject = (payload)=> async()=>{
    const params = { Bucket: AWS_BUCKET,  Key:payload.key};
    return S3Bucket.deleteObject(params);
}
export const actionToUploadDataToStemcityS3BucketStore = (payload) => async () => {
    const params = {
        ACL: 'public-read',
        Body: payload.blobData,
        Bucket: STEMCITY_AWS_BUCKET,
        Key: payload.key,
        ContentType:payload.type
    };
    S3StemcityBucket.putObject(params)
        .send((err) => {
            console.log(err,'error');
        })
    return `https://stemcity.s3.ap-southeast-2.amazonaws.com/${payload.key}`;
}

export const actionToDeleteStemcityAwsObject = (payload)=> async()=>{
    const params = { Bucket: STEMCITY_AWS_BUCKET,  Key:payload.key};
    return S3StemcityBucket.deleteObject(params);
}
export const callInsertDataWithLogFunction = (payload) => async () => {
    try {
        const { data } = await api.post('common-log/insert-common-with-log-api-call',payload);
        return data;
    } catch (error) {
        console.log(error,'error');
    }
}

export const callSoftDeleteDataWithLogFunction = (payload) => async () => {
    try {
        const { data } = await api.post('common-log/soft-delete-common-with-log-api-call',payload);
        return data;
    } catch (error) {
        console.log(error,'error');
    }
}

export const callDeleteDataWithLogFunction = (payload) => async () => {
    try {
        const { data } = await api.post('common-log/delete-common-with-log-api-call',payload);
        return data;
    } catch (error) {
        console.log(error,'error');
    }
}
export const callUpdateDataWithLogFunction = (payload) => async () => {
    try {
        const { data } = await api.post('common-log/update-common-with-log-api-call',payload);
        return data;
    } catch (error) {
        console.log(error,'error');
    }
}
export const actionToGetCompanyListApiCall = (payload) => async (dispatch) => {
    try {
        const { data } = await api.post('common-log/get-company-list',payload);
        dispatch({ type: COMPANY_LIST, payload: data });
    } catch (error) {
        console.log(error,'error');
    }
}