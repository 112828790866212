import React, {useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Virtuoso} from "react-virtuoso";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLaptopFile, faPenToSquare, faTrash} from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";
import {callSoftDeleteDataWithLogFunction, callUpdateDataWithLogFunction} from "../../../store/action/commonActions";
import {useEffectOnce} from "../../../hooks/useEffectOnce";
import useAuth from "../../../hooks/useAuth";
import { uploadFileOnAws} from "../../../store/action/inventoryActions";
import Tippy from "@tippyjs/react";
import EditRegion from "./EditRegion";
import AddRegionState from "./AddRegionState";
import {actionToGetRegionsApiCall} from "../../../store/action/salesAction";

export const RegionTable = () => {
    const [isEditRegionsOpen, setIsEditRegionsOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);
    const dispatch = useDispatch();
    const regionListData = useSelector((state) =>  state.sales.regionList);
    const {auth} = useAuth();
    const hiddenFileInput = useRef();
    const eventTypeId = useSelector((state) =>  state.sales.salesEventTypeId);

    useEffectOnce(()=>{
        dispatch(actionToGetRegionsApiCall());
    })

    const deleteRegion = (row) => {
        swal({
            title: "Are you sure?",
            text: "You want to delete this Region "+row?.name,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (success) => {
                if (success) {
                    dispatch(callSoftDeleteDataWithLogFunction({tableName:'region',id:row.id,userId:auth?.id,eventTypeId})).then(() => {
                        dispatch(actionToGetRegionsApiCall())
                    })
                }
            });

    };
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedRows([]);
        } else {
            setSelectedRows(regionListData.map(item => item.id));
        }
        setSelectAll(!selectAll);
    };
    const handlePhotoClick = (index) => {
        setSelectedIndex(index);
        hiddenFileInput.current.click();    // ADDED
    };
    const handleMainPhotoUpload = async event => {
        const fileUploaded = event.target.files[0];
        const fileExtension = fileUploaded.name.split('.').pop();
        let data =regionListData[selectedIndex];
        let payload = {filename:'subject/'+data.name?.toLowerCase()+'.'+fileExtension,showOnWebsite:'stemcity',photo:fileUploaded,type:fileUploaded.type}
        data.photo ? payload['deletedFileName'] =  data.photo?.replace('https://stemcity.s3.ap-southeast-2.amazonaws.com/','') :'';
        let url = await dispatch(uploadFileOnAws(payload));
        dispatch(callUpdateDataWithLogFunction({tableName:'region',data: {photo:url},id:data?.id,userId:auth?.id,eventTypeId:eventTypeId})).then(() => {
            dispatch(actionToGetRegionsApiCall());
        })
    };
    const handleSelectRow = (id) => {
        const newSelectedRows = selectedRows.includes(id)
            ? selectedRows.filter(rowId => rowId !== id)
            : [...selectedRows, id];

        setSelectedRows(newSelectedRows);
        setSelectAll(newSelectedRows.length === regionListData.length);
    };
    return(
        <>
            <div className='table-container'>
            {
                regionListData === 'loading' ?
                    <PreLoader/>
                    :
        <div className="table">
            <div className="row-header">
                <div className="two-column-cell">
                    <input className='checkbox' type="checkbox"
                           checked={selectAll}
                           onChange={()=>handleSelectAll()}/>
                    Region Name
                </div>
                <div className="two-column-cell">Action</div>
            </div>
            <Virtuoso style={{ height: '600px' }} totalCount={regionListData.length} itemContent={index =>
                <div className="table-row">
                    <div className="two-column-cell">
                        <input className='checkbox' type="checkbox"
                               checked={selectedRows.includes(regionListData[index]?.id)}
                               onChange={() => handleSelectRow(regionListData[index]?.id)}/>
                        {regionListData[index]?.name}
                    </div>
                    <div className="two-column-cell">
                        <div className='action-components'>
                            <div className='action-buttons'>
                                <button className='action-button' onClick={() => {setIsEditRegionsOpen('edit_region'); setSelectedIndex(index);}} >
                                    <FontAwesomeIcon className='action-icon' icon={faPenToSquare} />
                                </button>
                            </div>
                            <div className='action-buttons'>
                                <button onClick={()=>deleteRegion(regionListData[index])} className='action-button'>
                                    <FontAwesomeIcon className='action-icon' icon={faTrash} />
                                </button>
                            </div>

                            <div className='action-buttons'>
                                <Tippy content='Add State'>
                                    <button className='action-button' onClick={() => {setIsEditRegionsOpen('region_state'); setSelectedIndex(index);}} >
                                        <FontAwesomeIcon className='action-icon' icon={faLaptopFile} />
                                    </button>
                                </Tippy>
                            </div>
                        </div>
                    </div>
                </div>
            } />
        </div>
            }

            <input type="file" onChange={handleMainPhotoUpload} ref={hiddenFileInput} style={{display: 'none'}} />
            <EditRegion isOpen={isEditRegionsOpen ==='edit_region'} selectedData={regionListData[selectedIndex]} onRequestClose={() => {setIsEditRegionsOpen(false); setSelectedIndex(false);}} />
            <AddRegionState isOpen={isEditRegionsOpen ==='region_state'} selectedData={regionListData[selectedIndex]} onRequestClose={() => {setIsEditRegionsOpen(false); setSelectedIndex(false);}} />
            </div>
            </>
    )
}
