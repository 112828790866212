import React, {useEffect, useRef, useState} from 'react';
import Modal from 'react-modal';
import {useDispatch, useSelector} from "react-redux";
import {callUpdateDataWithLogFunction} from "../../../../store/action/commonActions";
import useAuth from "../../../../hooks/useAuth";
import {Editor} from "@tinymce/tinymce-react";
import { actionToGetWebSettingSectionApiCall} from "../../../../store/action/webSettingActions";
import SelectComponentDropdown from "../menu/SelectComponentDropdown";


const EditWebsiteSection= ({ isOpen,selectedData, onRequestClose }) => {
    const eventTypeId = useSelector((state) =>  state.webSetting.webSettingEventTypeId);
    const dispatch = useDispatch();
    const {auth} = useAuth();
    const [isFormChanged, setIsFormChanged] = useState(false);
    const [formData, setFormData] = useState({ });
    const [errors, setErrors] = useState({});
    const [saveStatus, setSaveStatus] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedFormData = {
            ...formData,
            [name]: value
        };
        setFormData(updatedFormData);

        // Check if the updated form data differs from the initial data
        setIsFormChanged(JSON.stringify(updatedFormData) !== JSON.stringify(selectedData));
    };

    const parseEditorData = (content, editor) => {
        //content is the current value of the text editor
        // editor is an object that holds the html element that in this case is the text area where the name prop will be stored.
        const { targetElm } = editor;
        // This name value references the prop that you pass as textAreaName (content in your case)
        const { name } = targetElm;

        // This function returns an object that your handle change can parse correctly
        return {
            target: {
                name,
                value: content
            }
        };
    };
    const handleSubmit = async(e) => {
        e.preventDefault()
        const formValue = new FormData(e.target);
        const obj = Object.fromEntries(formValue.entries());
        const newErrors = validateForm(obj);
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {

            dispatch(callUpdateDataWithLogFunction({tableName:'website_section',data:obj,id:selectedData?.id,userId:auth?.id,eventTypeId})).then(res => {
                let status = res.success ===1 ? 'success' : 'fail'
                setSaveStatus(status);
                if(status === 'success'){
                    dispatch(actionToGetWebSettingSectionApiCall());
                    onRequestClose();
                }
            })
        } else {
            setSaveStatus('fail');
        }
    };

    useEffect(() => {
        setFormData(selectedData);
    }, [selectedData]);

    const validateForm = (data) => {
        const errors = {};

        if (!data.name.trim()) {
            errors.name = 'Name is required';
        }
        if (!data.component_type.trim()) {
            errors.component_type = 'Component is required';
        }

        return errors;
    };
    useEffect(() => {
        setErrors({});
        setSaveStatus({});
    }, [isOpen]);
    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='Modal' ariaHideApp={false}>
            {formData?.id ?<div className="container">
                <div className='title'>Edit Website Section</div>
                <form action='#' onSubmit={(e) => handleSubmit(e)}>
                    <div className='vendor-details'>
                        <div className='input-box'>
                            <span className='details'>Select Component Type</span>
                            <select className='select' name='component_type' placeholder='Component Type' value={formData.component_type} onChange={(e)=>handleChange(e)}>
                                <option value="" >Select Component Type </option>
                                <option value="banner" selected={formData.component_type === 'banner'}>Banner </option>
                                <option value="section"  selected={formData.component_type === 'section'}>Section</option>
                                <option value="category_card"  selected={formData.component_type === 'category_card'}>Category Card</option>
                                <option value="grade_card"  selected={formData.component_type === 'grade_card'}>Grade Card</option>
                                <option value="curriculum_card"  selected={formData.component_type === 'curriculum_card'}>Curriculum Card</option>
                                <option value="product_card"  selected={formData.component_type === 'product_card'}>Product Card</option>
                                <option value="curriculum_card"  selected={formData.component_type === 'curriculum_card'}>Curriculum Card</option>
                                <option value="component"  selected={formData.component_type === 'component'}>Pure Component</option>
                                <option value="page_content"  selected={formData.component_type === 'page_content'}>Page Content</option>
                                <option value="others"  selected={formData.component_type === 'others'}>Others</option>
                            </select>
                            {errors.component_type && <span className="error-message"> {errors.component_type}</span> }
                        </div>
                        <SelectComponentDropdown name={'component_id'}  errors={errors} component_id={formData.component_id} component_type={formData.component_type} handleChange={handleChange}/>

                        <div className='input-box'>
                            <span className='details'>Section Name</span>
                            <input type='text' placeholder='Section Name*' name='name' className='form-input' value={formData?.name}  onChange={handleChange}/>
                            {errors.name &&  <span className="error-message"> {errors.name} </span> }
                        </div>

                        <div className='input-box'>
                            <span className='details'>Object Heading</span>
                            <input type='text' placeholder='Website Heading' name='object_heading' className='form-input' value={formData?.object_heading}  onChange={handleChange}/>
                            {errors.object_heading &&  <span className="error-message"> {errors.object_heading} </span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Object Sub Heading</span>
                            <input type='text' placeholder='Website Sub Heading' name='object_subheading' className='form-input' value={formData?.object_subheading}  onChange={handleChange}/>
                            {errors.object_subheading &&  <span className="error-message"> {errors.object_subheading} </span> }
                        </div>

                        <div className='input-box'>
                            <span className='details'>Object Class</span>
                            <input type='text' placeholder='Call to Action Name' name='object_class' className='form-input' value={formData?.object_class}  onChange={handleChange}/>
                            {errors.object_class &&  <span className="error-message"> {errors.object_class} </span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Call to action Link</span>
                            <input type='text' name='call_to_action_link' className='form-input' value={formData?.call_to_action_link}  onChange={handleChange}/>
                            {errors.call_to_action_link &&  <span className="error-message"> {errors.call_to_action_link} </span> }
                        </div>
                        <Editor
                            initialValue={formData?.object_content}
                            apiKey="nchrb4md98keu69dxd2sjsc25q52blhw3z0ryes84hw6wscr"
                            init={{
                                height: 200,
                                plugins: [
                                    'advlist', 'autolink', 'link', 'image', 'lists', 'charmap', 'preview', 'anchor', 'pagebreak',
                                    'searchreplace', 'wordcount', 'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media',
                                    'table', 'emoticons', 'help'
                                ],
                                toolbar: 'undo redo | print preview code media fullscreen | styles bold italic | alignleft aligncenter alignright alignjustify |codeformat | ' +
                                    'bullist numlist outdent indent | link image | ' +
                                    'forecolor backcolor emoticons | help',
                                menu: {
                                    favs: { title: 'My Favorites', items: 'code visualaid | searchreplace | emoticons' }
                                },
                                menubar: 'favs file edit view insert format tools table help',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                            }}
                           /* onEditorChange={(content, editor) =>
                                handleChange(parseEditorData(content, editor))
                            }*/
                            //value={formData?.object_content}
                            textareaName="object_content"
                        />

                    </div>
                    <div className='buttons'>
                        <div className='button'>
                            <button className='submit-button' type='submit'  disabled={!isFormChanged}>Update</button>
                        </div>
                        <div className='button'>
                            <button className='close-button' onClick={onRequestClose}>Close</button>
                        </div>
                    </div>
                    {saveStatus==='success' ? <div className="success-message">Website Section Updated successfully!!</div> : saveStatus==='fail' && <div className='failure-message'>Something went Wrong</div> }
                </form>

            </div> : ''}
        </Modal>
    );
};


export default EditWebsiteSection;
