import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";

function ProductHistory() {
    return (

        <div>
            History
        </div>
    )
}

export default ProductHistory;

