import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import {Editor} from '@tinymce/tinymce-react';
import {useDispatch, useSelector} from "react-redux";
import {actionToGetProductApiCall} from "../../../../store/action/inventoryActions";
import {callUpdateDataWithLogFunction} from "../../../../store/action/commonActions";
import useAuth from "../../../../hooks/useAuth";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import ShowPreviewofDescription from "./ShowPreviewofDescription";
const AddBriefDescription = ({ isOpen, onRequestClose,selectedProduct }) => {
    const [isShowPreviewOpen, setIsShowPreviewOpen] = useState(false);
    const [description, setDescription] = useState('');
    const [initialDescription, setInitialDescription] = useState('');
    const dispatch = useDispatch();
    const {auth} = useAuth();
    const eventTypeId = useSelector((state) =>  state.inventory.inventoryEventTypeId);

    const handleEditorChange = (content) => {
        setDescription(content);
    };
    const actionToUpdateDescription = () =>{
        dispatch(callUpdateDataWithLogFunction({tableName:'product_details',data:{long_description:description},id:selectedProduct?.product_detail_id,userId:auth?.id,eventTypeId})).then(() => {
            dispatch(actionToGetProductApiCall())
        });
    }
    useEffect(() => {
        if(selectedProduct?.id){
            setInitialDescription(selectedProduct?.long_description);
            setDescription(selectedProduct?.long_description);
        }
    }, [selectedProduct]);


    return (
        <div>
            <Modal isOpen={isOpen}  maxWidth="xl" onRequestClose={onRequestClose} className='description-modal lg'>
                <div className='description-container'>
                    <div className='title'>Add Brief Description</div>
                    <div className={"row"}>
                        <div className={"col-lg-12"}>
                            <div className='editor'>
                                <Editor
                                    apiKey="nchrb4md98keu69dxd2sjsc25q52blhw3z0ryes84hw6wscr"
                                    initialValue={initialDescription}
                                    init={{
                                        height: 500,
                                        plugins: [
                                            'advlist', 'autolink', 'link', 'image', 'lists', 'charmap', 'preview', 'anchor', 'pagebreak',
                                            'searchreplace', 'wordcount', 'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media',
                                            'table', 'emoticons', 'help'
                                        ],
                                        toolbar: 'undo redo | print preview code media fullscreen | styles bold italic | alignleft aligncenter alignright alignjustify |codeformat | ' +
                                            'bullist numlist outdent indent | link image | ' +
                                            'forecolor backcolor emoticons | help',
                                        menu: {
                                            favs: { title: 'My Favorites', items: 'code visualaid | searchreplace | emoticons' }
                                        },
                                        menubar: 'favs file edit view insert format tools table help',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                    }}
                                    onEditorChange={handleEditorChange}
                                />
                            </div>
                        </div>
                        {/*<div className={"col-lg-6"}>*/}
                        {/*    <div dangerouslySetInnerHTML={{__html:description}}></div>*/}
                        {/*</div>*/}

                    </div>
                </div>
                <div className='buttons'>
                    <div className='button'>
                        <button className='submit-button' type='button' onClick={() => {setIsShowPreviewOpen(true); }}>Preview</button>
                    </div>
                    <div className='button'>
                        <button className='submit-button' type='button' onClick={()=> actionToUpdateDescription()}>Update</button>
                    </div>
                    <div className='button'>
                        <button className='close-button' onClick={onRequestClose}>Close</button>
                    </div>
                </div>
            </Modal>
            <ShowPreviewofDescription isOpen={isShowPreviewOpen} description={description} onRequestClose={() => {setIsShowPreviewOpen(false); }} />
        </div>
    );
};

export default AddBriefDescription;
