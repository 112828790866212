import React, {useState,  useEffect} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AddVendor from '../Modals/AddVendor.js';
import AddCustomer from '../Modals/AddCustomer.js';
import AddProduct from './inventory/product/AddProduct.js';
import AddCategory from './inventory/category/AddCategory.js';
import AddSubCategory from './inventory/sub-category/AddSubCategory.js';
import {
    faFilter,
    faSearch,
    faTrash,
    faFileArrowDown,
    faTableList,
    faUserPlus,
    faArrowDownShortWide,
    faUsers,
    faBoxOpen,
    faAnglesDown,
    faLayerGroup,
    faCopyright,
    faFileCirclePlus,
    faBagShopping,
    faSackDollar,

} from "@fortawesome/free-solid-svg-icons";
import Tippy from "@tippyjs/react";
import 'tippy.js/dist/tippy.css';
//import {VendorTable} from "./inventory/vendor/VendorTable";
//import {CustomerTable} from "./inventory/customer/CustomerTable";
import {InventoryTable} from "./inventory/inventory/InventoryTable";
import {CategoryTable} from "./inventory/category/CategoryTable";
import {SubCategoryTable} from "./inventory/sub-category/SubCategoryTable";
import {ProductTable} from "./inventory/product/ProductTable";
import {BrandTable} from "./inventory/brand/BrandTable";
import AddBrand from "./inventory/brand/AddBrand";
//import {SalesTable} from "./inventory/sales/SalesTable";
//import {PurchaseTable} from "./inventory/purchase/PurchaseTable";
import AddSales from "../Modals/AddSales";
import AddPurchase from "../Modals/AddPurchase";
import {Link, useParams} from "react-router-dom";
import {useEffectOnce} from "../../hooks/useEffectOnce";
import {useDispatch} from "react-redux";
import {subDomainUrl} from "../../hooks/config";
import {actionToGetCompanyListApiCall} from "../../store/action/commonActions";


function InventoryComponent() {
    let {tabName} = useParams();
    const [isAddVendorOpen, setIsAddVendorOpen] = useState(false);
    const [isAddCustomerOpen, setIsAddCustomerOpen] = useState(false);
    const [isAddProductOpen, setIsAddProductOpen] = useState(false);
    const [isAddCategoryOpen, setIsAddCategoryOpen] = useState(false);
    const [isAddSubCategoryOpen, setIsAddSubCategoryOpen] = useState(false);
    const [isAddBrandOpen, setIsAddBrandOpen] = useState(false);
    const [isAddSalesOpen, setIsAddSalesOpen] = useState(false);
    const [isAddPurchaseOpen, setIsAddPurchaseOpen] = useState(false);
    const [activeTab, setActiveTab] = useState('inventory');
    const dispatch = useDispatch();

    const getTabTable =() =>{
        switch (activeTab){
           /* case 'vendor':
                return <VendorTable />;
            case 'customer':
                return <CustomerTable />;
            case 'inventory':
                return <InventoryTable />;*/
            case 'category':
                return <CategoryTable />;
            case 'sub-category':
                return <SubCategoryTable />;
            case 'product':
                return <ProductTable />;
            case 'brand':
                return <BrandTable />;
            /*case 'sales':
                return <SalesTable />;
            case 'purchase':
                return <PurchaseTable />;*/
            default:
                return <ProductTable />;
        }
    }
    useEffect(() => {
        setActiveTab(tabName);
    }, [tabName]);
    useEffectOnce(()=>{
        dispatch(actionToGetCompanyListApiCall({in_inventory_use:1}));
    })
    return (
        <>
            <section className='section'>
                <div className='sub-header'>
                    <div className='subheader-box'>
                        <div className="tab-container">
                            <div className="tab">
                               {/* <Link to={subDomainUrl+'/inventory/inventory'} className='tab'><button
                                    className={`tab ${activeTab === 'inventory' ? 'active' : ''}`}>
                                    Inventory
                                </button>
                                </Link>*/}
                                {/*<Link to={subDomainUrl+'/inventory/vendor'} className='tab'><button
                                    className={`tab ${activeTab === 'vendor' ? 'active' : ''}`}>
                                    Vendor</button>
                                </Link>
                                <Link to={subDomainUrl+'/inventory/customer'} className='tab'><button
                                    className={`tab ${activeTab === 'customer' ? 'active' : ''}`}>
                                    Customer </button>
                                </Link>*/}
                                <Link to={subDomainUrl+'/inventory/product'} className='tab'><button
                                    className={`tab ${activeTab === 'product' ? 'active' : ''}`} >
                                    Products </button>
                                </Link>
                                <Link to={subDomainUrl+'/inventory/category'} className='tab'>
                                <button
                                    className={`tab ${activeTab === 'category' ? 'active' : ''}`}>
                                    Category
                                </button>
                                </Link>
                                <Link to={subDomainUrl+'/inventory/sub-category'} className='tab'>
                                <button
                                    className={`tab ${activeTab === 'sub-category' ? 'active' : ''}`}>
                                    Sub-Category
                                </button>
                                </Link>
                                <Link to={subDomainUrl+'/inventory/brand'} className='tab'>
                                <button
                                    className={`tab ${activeTab === 'brand' ? 'active' : ''}`}>
                                    Brand
                                </button>
                                </Link>
                               {/* <Link to={subDomainUrl+'/inventory/sales'} className='tab'>
                                <button
                                    className={`tab ${activeTab === 'sales' ? 'active' : ''}`} >
                                    Sales Reports
                                </button>
                                </Link>
                                <Link to={subDomainUrl+'/inventory/purchase'} className='tab'>
                                <button
                                    className={`tab ${activeTab === 'purchase' ? 'active' : ''}`}>
                                    Purchase Reports
                                </button>
                                </Link>*/}
                            </div>
                        </div>
                    </div>
                    <div className='subheader-box'>
                        {/*<div className='subheader-components'>
                            <Tippy content='Add Vendor'>
                                <button  className='common-button' onClick={() => setIsAddVendorOpen(true)}>
                                    <FontAwesomeIcon className='filter-icon' icon={faUserPlus} />
                                </button>
                            </Tippy>
                            <AddVendor isOpen={isAddVendorOpen} onRequestClose={() => setIsAddVendorOpen(false)} />
                        </div>

                        <div className='subheader-components'>
                            <Tippy content='Add Customer'>
                                <button className='common-button' onClick={() => setIsAddCustomerOpen(true)}>
                                    <FontAwesomeIcon className='filter-icon' icon={faUsers} />
                                </button>
                            </Tippy>
                            <AddCustomer isOpen={isAddCustomerOpen} onRequestClose={() => setIsAddCustomerOpen(false)} />
                        </div>*/}

                        <div className='subheader-components'>
                            <Tippy content='Add Product'>
                                <button className='common-button' onClick={() => setIsAddProductOpen(true)}>
                                    <FontAwesomeIcon className='filter-icon' icon={faBoxOpen} />
                                </button>
                            </Tippy>
                            <AddProduct isOpen={isAddProductOpen} onRequestClose={() => setIsAddProductOpen(false)} />
                        </div>

                        <div className='subheader-components'>
                            <Tippy content='Add Category'>
                                <button  className='common-button' onClick={() => setIsAddCategoryOpen(true)}>
                                    <FontAwesomeIcon className='filter-icon' icon={faAnglesDown} />
                                </button>
                            </Tippy>
                            <AddCategory isOpen={isAddCategoryOpen} onRequestClose={() => setIsAddCategoryOpen(false)} />
                        </div>

                        <div className='subheader-components'>
                            <Tippy content='Add Sub-Category'>
                                <button  className='common-button' onClick={() => setIsAddSubCategoryOpen(true)}>
                                    <FontAwesomeIcon className='filter-icon' icon={faLayerGroup} />
                                </button>
                            </Tippy>
                            <AddSubCategory isOpen={isAddSubCategoryOpen} onRequestClose={() => setIsAddSubCategoryOpen(false)} />
                        </div>

                        <div className='subheader-components'>
                            <Tippy content='Add Brand'>
                                <button  className='common-button' onClick={() => setIsAddBrandOpen(true)}>
                                    <FontAwesomeIcon className='filter-icon' icon={faCopyright} />
                                </button>
                            </Tippy>
                            <AddBrand isOpen={isAddBrandOpen} onRequestClose={() => setIsAddBrandOpen(false)} />
                        </div>
                      {/*  <div className='subheader-components'>
                            <Tippy content='Add Sales'>
                                <button  className='common-button' onClick={() => setIsAddSalesOpen(true)}>
                                    <FontAwesomeIcon className='filter-icon' icon={faSackDollar} />
                                </button>
                            </Tippy>
                            <AddSales isOpen={isAddSalesOpen} onRequestClose={() => setIsAddSalesOpen(false)} />
                        </div>
                        <div className='subheader-components'>
                            <Tippy content='Add Purchases'>
                                <button  className='common-button' onClick={() => setIsAddPurchaseOpen(true)}>
                                    <FontAwesomeIcon className='filter-icon' icon={faBagShopping} />
                                </button>
                            </Tippy>
                            <AddPurchase isOpen={isAddPurchaseOpen} onRequestClose={() => setIsAddPurchaseOpen(false)} />
                        </div>*/}
                    </div>
                </div>

                <div className='table-container'>
                    {/*<div className='table-header'>
                        <div className='header-box'>
                            <div className='subheader-components'>
                                <button className='filter-button'>
                                    <FontAwesomeIcon className='filter-icon' icon={faFilter} />
                                    Advanced Filter
                                </button>
                            </div>
                            <div className='subheader-components'>
                                <Tippy content='Search'>
                                    <button className='common-button' >
                                        <FontAwesomeIcon icon={faSearch} />
                                    </button>
                                </Tippy>
                            </div>
                            <div className='subheader-components'>
                                <Tippy content='Delete'>
                                    <button className='common-button' title='Delete'>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                </Tippy>
                            </div>
                        </div>
                        <div className='header-box'>
                            <div className='subheader-components'>
                                <Tippy content='View as'>
                                    <button className='common-button'>
                                        <FontAwesomeIcon className='filter-icon' icon={faTableList} />
                                        Table
                                    </button>
                                </Tippy>
                            </div>
                            <div className='subheader-components'>
                                <Tippy content='Create Quotation'>
                                    <button className='common-button' >
                                        <FontAwesomeIcon icon={faFileCirclePlus} />
                                    </button>
                                </Tippy>
                            </div>
                            <div className='subheader-components'>
                                <Tippy content='Export File'>
                                    <button className='common-button'>
                                        <FontAwesomeIcon icon={faFileArrowDown} />
                                    </button>
                                </Tippy>
                            </div>
                            <div className='subheader-components'>
                                <Tippy content='Sort By'>
                                    <button className='common-button'>
                                        <FontAwesomeIcon className='filter-icon' icon={faArrowDownShortWide} />
                                    </button>
                                </Tippy>
                            </div>
                        </div>
                    </div>*/}
                    <div className="tab-content">
                        {getTabTable()}
                    </div>
                </div>
            </section>
        </>
    )
}

export default InventoryComponent;
