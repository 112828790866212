import React, {useState, useEffect} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import ProductImg from "./ProductImg";
import Header from "../../../Layout/Header";
import ProductDetails from "./ProductDetails";
import Details from "./productTabs/Details";
import ProductHistory from "./productTabs/ProductHistory";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useEffectOnce} from "../../../../hooks/useEffectOnce";
import {actionToGetProductDetailsDataApiCall} from "../../../../store/action";

export default function ProductOverview() {
    const [loadingData,setLoadingData]= useState(true);
    const productDetailsData = useSelector((state) => state.inventory.productDetailsData);

    let {id} = useParams();
    let dispatch = useDispatch();
    useEffectOnce(()=>{
        dispatch(actionToGetProductDetailsDataApiCall({'id':id}))
    })
    useEffect(() => {
        if(productDetailsData?.data){
            setTimeout(function(){
                setLoadingData(productDetailsData?.loading);

            },500)
        }

    },[productDetailsData])

    const [activeTab, setActiveTab] = useState('details');

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };
    const getTabContent =() =>{
        switch (activeTab){
            case 'details':
                return <Details />;
            case 'productHistory':
                return <ProductHistory />;
            default:
                return <Details />
        }
    }
    return (
        <>
        <Header/>
        <div className='product-container'>
            <div className='row'>
                <div className='col-md-3'>
                    {productDetailsData?.data?.id && <ProductImg productDetailsData={productDetailsData?.data}/>}
                </div>
                <div className='col-md-8'>
                   {productDetailsData?.data?.id && <ProductDetails productDetailsData={productDetailsData?.data}/>}
                </div>
            </div>
        </div>
            <div className="product-tab-container">
                <div className="tab">
                    <button
                        className={`tab ${activeTab === 'details' ? 'active' : ''}`}
                        onClick={() => handleTabClick('details')}
                    >
                        Details
                    </button>
                    <button
                        className={`tab ${activeTab === 'productHistory' ? 'active' : ''}`}
                        onClick={() => handleTabClick('productHistory')}
                    >
                        History
                    </button>
                </div>
                <div className="tab-content">
                    {loadingData ? <>Loading</> : getTabContent()}
                </div>
            </div>

        </>
    )
};

