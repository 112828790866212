import React from "react";
import Header from "./Header.js";
import {Outlet} from "react-router-dom";
//import Breadcrumb from "./BreadCrumb";

function AdminLayout() {
    return (
      <>
          <Header/>
          {/*<Breadcrumb/>*/}
          <Outlet/>
      </>
    )
  }
  
  export default AdminLayout;