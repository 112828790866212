import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import {useDispatch, useSelector} from "react-redux";
import {actionToCreateCategory, actionToGetCategoriesApiCall} from "../../../../store/action/inventoryActions";
import useAuth from "../../../../hooks/useAuth";
import SelectCompanyDropdown from "../../../auth/SelectCompanyDropdown";

const AddCategory = ({ isOpen, onRequestClose }) => {
    const [errors, setErrors] = useState({});
    const [saveStatus, setSaveStatus] = useState({});
    const dispatch = useDispatch();
    const ROLES = useSelector((state) =>  state.webSetting.roleList);
    const allowedRoles =[ROLES.SuperAdmin,ROLES.Admin];
    const {auth} = useAuth();
    const [formData, setFormData] = useState({ source:auth?.source});

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedFormData = {
            ...formData,
            [name]: value
        };
        setFormData(updatedFormData);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const formValue = new FormData(e.target);
        const obj = Object.fromEntries(formValue.entries());
        const newErrors = validateForm(obj);
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            obj['created_by']=auth?.id;
            dispatch(actionToCreateCategory(obj)).then(res => {
                dispatch(actionToGetCategoriesApiCall());
                let status = res?.success ===1 ? 'success' :'fail'
                setSaveStatus(status);
                if(status === 'success'){
                    resetForm();
                }
            })
        } else {
            setSaveStatus('fail');
        }
    };

    const resetForm = () => {
        document.getElementById('category-form')?.reset();
    };

    const validateForm = (data) => {
        const errors = {};

        if (!data.name.trim()) {
            errors.name = 'Category is required';
        }

         if (!data.slug.trim()) {
             errors.slug = 'Slug is required';
         }

        return errors;
    };
    useEffect(() => {
        setErrors({})
    }, [isOpen]);
    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='Modal'>
            <div className="container">
                <div className='title'>Add Category</div>
                <form onSubmit={handleSubmit} id='category-form'>
                    <div className='vendor-details'>
                        <SelectCompanyDropdown allowedRoles={allowedRoles} source={formData.source} handleChange={handleChange} />
                        <div className='input-box'>

                            <input type='text' placeholder='Category Name*' name='name' className='form-input'/>
                            {errors.name &&  <span className="error-message"> {errors.name} </span> }
                        </div>
                        <div className='input-box'>
                            <input type='text' name='slug' className='form-input' placeholder='Slug' />
                            {errors.slug && <span className="error-message"> {errors.slug} </span>}
                        </div>

                    </div>
                    <div className='buttons'>
                        <div className='button'>
                            <button className='submit-button' type='submit'>Submit</button>
                        </div>
                        <div className='button'>
                            <button className='close-button' onClick={onRequestClose}>Close</button>
                        </div>
                    </div>
                    {saveStatus==='success' ? <div className="success-message">Category Added successfully!!</div> : saveStatus==='fail' && <div className='failure-message'>Something went Wrong</div> }
                </form>
            </div>
        </Modal>
    );
};

export default AddCategory;