import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import {useDispatch, useSelector} from "react-redux";
import {actionToGetProductApiCall} from "../../../../store/action/inventoryActions";
import {callUpdateDataWithLogFunction} from "../../../../store/action/commonActions";
import useAuth from "../../../../hooks/useAuth";
import CategoryDropdown from "../category/CategoryDropdown";
import SelectSubCategoryDropdown from "../sub-category/SelectSubCategoryDropdown";
import SelectBrandDropdown from "../brand/SelectBrandDropdown";


const EditProduct = ({ isOpen, selectedProduct,onRequestClose }) => {
    const eventTypeId = useSelector((state) =>  state.inventory.inventoryEventTypeId);
    const dispatch = useDispatch();
    const {auth} = useAuth();
   /* const companyList = useSelector((state) =>  state.users?.companyList);*/
    const listOfCategories = useSelector((state) => { return state.inventory?.categoryData});
    const listOfSubCategories = useSelector((state) => { return state.inventory?.subCategoryData});
    const brandData = useSelector((state) =>  state.inventory?.brandData);
    const [formData, setFormData] = useState({ });
    const [errors, setErrors] = useState({});
    const [saveStatus, setSaveStatus] = useState({});


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault()
        const formValue = new FormData(e.target);
        const obj = Object.fromEntries(formValue.entries());
        const newErrors = validateForm(obj);
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            delete obj['category_id'];
            delete obj['source'];
            dispatch(callUpdateDataWithLogFunction({tableName:'products',data:obj,id:selectedProduct?.id,userId:auth?.id,eventTypeId})).then(res => {
                let status = res.success ===1 ? 'success' : 'fail'
                setSaveStatus(status);
                if(status === 'success'){
                    dispatch(actionToGetProductApiCall());
                    onRequestClose();
                }
            })
        } else {
            setSaveStatus('fail');
        }
    };

    useEffect(() => {
        setFormData(selectedProduct);
    }, [selectedProduct]);

    const validateForm = (data) => {
        const errors = {};
        if (!data.name.trim()) {
            errors.name = 'Product Name is required';
        }
        if (!data.sub_category_id.trim()) {
            errors.sub_category_id = 'Sub Category is required';
        }
        if (!data.in_stock_quantity.trim()) {
            errors.in_stock_quantity = 'Quantity is required';
        }
        if (!data.gst.trim()) {
            errors.gst = 'GST percentage is required';
        }

        return errors;
    };

    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='Modal'>
            <div className="container">
                <div className='title'>Edit Product</div>
                <form action='#' onSubmit={(e) => handleSubmit(e)}>
                    <div className='vendor-details'>
                        {/*<div className='input-box'>
                            <span className='details'>Company</span>
                            <select className='select' name="source" onChange={handleChange}>
                                {companyList?.map((company) => {
                                    return <option key={company?.id} value={company?.id} selected={company?.id === formData?.source}>{company?.name}</option>
                                })}
                            </select>
                        </div>*/}
                        <div className='input-box'>
                            <span className='details'>Product Name</span>
                            <input type='text'
                                   placeholder='Enter Name of the Product'
                                   name='name'
                                   value={formData?.name}
                                   onChange={handleChange}
                                   required/>
                            {errors.name &&  <span className="error-message">{errors.name} </span>}
                        </div>
                        <CategoryDropdown errors={errors} source={formData?.source} name={'category_id'} category={formData?.category_id} handleChange={handleChange}/>
                        <SelectSubCategoryDropdown errors={errors} name={'sub_category_id'} category_id={formData?.category_id} sub_category_id={formData?.sub_category_id} handleChange={handleChange}/>
                        <SelectBrandDropdown errors={errors} name={'brand_id'} brand_id={formData?.brand_id} handleChange={handleChange}/>

                        <div className='input-box'>
                            <span className='details'>Product Type</span>
                            <select className='select' name='type' placeholder='Product Type' onChange={handleChange}>
                                <option value="component" selected={formData?.type==="component"}>Component</option>
                                <option value="product" selected={formData?.type==="product"}>Product</option>
                                <option value="product_component" selected={formData?.type==="product_component"}>Product and Component Both</option>
                            </select>
                            {errors.type && <span className="error-message"> {errors.type}</span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Short Description</span>
                            <textarea className='text-area'  placeholder='Enter Short Description of the Product' required name='description' value={formData?.description} onChange={handleChange}/>
                            {errors.description && <span className="error-message">{errors.description} </span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Location</span>
                            <input type='text' placeholder='Enter Location' name='location' value={formData?.location} onChange={handleChange}/>
                            {errors.location && <span className="error-message">{errors.location}</span>}
                        </div>

                        <div className='input-box'>
                            <span className='details'>GST Amount</span>
                            <input type='number' placeholder='Enter GST' name='gst' value={formData?.gst} onChange={handleChange}/>
                            {errors.gst && <span className="error-message"> {errors.gst} </span>}
                        </div>
                        <div className='input-box'>
                            <span className='details'>Qty. in Stock</span>
                            <input type='number'
                                   placeholder='Enter In stock Quantity'
                                   required name='in_stock_quantity' value={formData?.in_stock_quantity}  onChange={handleChange}/>
                            {errors.in_stock_quantity && <span className="error-message"> {errors.in_stock_quantity} </span>}
                        </div>
                        <div className='input-box'>
                            <span className='details'>Purchase Price</span>
                            <input type='number' placeholder='Enter Purchase Price of the unit Product'
                                   required name='purchase_price' value={formData?.purchase_price} onChange={handleChange}/>
                            {errors.purchase_price &&  <span className="error-message"> {errors.purchase_price}</span>}
                        </div>
                        <div className='input-box'>
                            <span className='details'>Selling Price</span>
                            <input type='number' placeholder='Enter Sale Price of the unit Product'  value={formData?.sale_price}
                                   required name='sale_price' onChange={handleChange}/>
                            {errors.sale_price &&  <span className="error-message"> {errors.sale_price}</span>}
                        </div>
                    </div>
                    <div className='buttons'>
                        <div className='button'>
                            <button className='submit-button' type='submit'>Update</button>
                        </div>
                        <div className='button'>
                            <button className='close-button' onClick={onRequestClose}>Close</button>
                        </div>
                    </div>
                    {saveStatus==='success' ? <div className="success-message">Product Updated successfully!!</div> : saveStatus==='fail' && <div className='failure-message'>Something went Wrong</div> }
                </form>

            </div>
        </Modal>
    );
};


export default EditProduct;
