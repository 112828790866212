import {
    VENDOR_LIST,
    CUSTOMER_LIST,
    UPDATE_VENDOR_FORM_DATA,
    UPDATE_CUSTOMER_FORM_DATA,
    PRODUCT_DETAILS_DATA_CP
} from "../constant/index.js";
import {
    SUB_CATEGORY_LIST,
    CATEGORY_DATA,
    BRAND_DATA,
    PRODUCT_DATA,
    PRODUCT_IMAGE_DATA,
    PRODUCT_CURRICULUM_DATA,
    PRODUCT_CURRICULUM_IMAGES_DATA,
    DISCOUNT_COUPON_LIST_DATA,
    PRODUCT_TOPICS_DATA, PRODUCT_GRADES_DATA, PRODUCT_SUBJECTS_DATA
} from "../constant/inventoryConstant";

const initialState = {
    categoryData:[],
    subCategoryData:[],
    vendorList:[],
    customerList:[],
    productData:[],
    productTopicsData:[],
    productGradesData:[],
    productSubjectsData:[],
    productImageData:[],
    productCurriculumData:[],
    productCurriculumImageData:[],
    brandData:[],
    productDetailsData:{data:[],loading: true},
    urlSlugData:[],
    updateVendorFormData:[],
    updateCustomerFormData:[],
    updateUrlSlugFormData:[],
    discountCouponListData:[],
    inventoryEventTypeId:2,
}
export  const inventoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case CATEGORY_DATA:
            return { ...state, categoryData: action.payload };
        case SUB_CATEGORY_LIST:
            return { ...state, subCategoryData: action.payload };
        case VENDOR_LIST:
            return { ...state, vendorList: action.payload };
        case CUSTOMER_LIST:
            return { ...state, customerList: action.payload };
        case PRODUCT_DATA:
            return { ...state, productData: action.payload };
        case PRODUCT_DETAILS_DATA_CP:
            return { ...state, productDetailsData: action.payload };
        case BRAND_DATA:
            return { ...state, brandData: action.payload };
        case UPDATE_VENDOR_FORM_DATA:
            return { ...state, updateVendorFormData: action.payload };
        case UPDATE_CUSTOMER_FORM_DATA:
            return { ...state, updateCustomerFormData: action.payload };
        case PRODUCT_IMAGE_DATA:
            return { ...state, productImageData:action.payload };
        case PRODUCT_CURRICULUM_DATA:
            return { ...state, productCurriculumData:action.payload };
        case PRODUCT_GRADES_DATA:
            return { ...state, productGradesData:action.payload };
        case PRODUCT_SUBJECTS_DATA:
            return { ...state, productSubjectsData:action.payload };
        case PRODUCT_TOPICS_DATA:
            return { ...state, productTopicsData:action.payload };
        case PRODUCT_CURRICULUM_IMAGES_DATA:
            return { ...state, productCurriculumImageData:action.payload };
        case DISCOUNT_COUPON_LIST_DATA:
            return { ...state, discountCouponListData:action.payload };
        default:
            return state
    }
}