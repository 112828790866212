import React, {useState,  useEffect} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBookOpenReader,
    faChalkboardTeacher,
    faLaptopFile,
    faSchool,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import Tippy from "@tippyjs/react";
import 'tippy.js/dist/tippy.css';
import {Link, useParams} from "react-router-dom";
import {useEffectOnce} from "../../hooks/useEffectOnce";
import {actionToGetCompanyListApiCall} from "../../store/action/commonActions";
import {useDispatch, useSelector} from "react-redux";
import {CurriculumTable} from "./curriculum/CurriculumTable";
import AddCurriculum from "./curriculum/AddCurriculum";
import {subDomainUrl} from "../../hooks/config";
import AddSubjects from "./subject/AddSubjects";
import {SubjectsTable} from "./subject/SubjectsTable";
import {GradesTable} from "./grade/GradesTable";
import {TopicsTable} from "./topic/TopicsTable";
import AddTopics from "./topic/AddTopics";
import AddGrades from "./grade/AddGrades";
import {ProductTable} from "../inventory/inventory/product/ProductTable";
import AuthorizedComponent from "../auth/AuthorizedComponent";
import useAuth from "../../hooks/useAuth";



const CurriculumComponent = React.memo(function () {
    let {tabName} = useParams();
    const [addModalName, setAddModalName] = useState(false);
    const [activeTab, setActiveTab] = useState('inventory');
    const ROLES = useSelector((state) =>  state.webSetting.roleList);
    const allowedRoles=[ROLES.SuperAdmin,ROLES.Admin,ROLES.WebsiteEditor,ROLES.InventoryManager];
    const dispatch = useDispatch();
    const {auth} = useAuth();

    const getTabTable =() =>{
        switch (activeTab){
            case 'grade' :
                if(allowedRoles?.includes(auth?.role))
                return <GradesTable />;
                else  return <ProductTable />;
            case 'subject':
                return <SubjectsTable />;
            case 'topic':
                return <TopicsTable />;
            case 'curriculum':
                return <CurriculumTable />;
            case 'product':
                return <ProductTable />;
            default:
                return <ProductTable />;
        }
    }

    useEffect(() => {
        setActiveTab(tabName);
    }, [tabName]);

    useEffectOnce(()=>{
        dispatch(actionToGetCompanyListApiCall({in_curriculum_use:1}));
    })
    return (
        <>
            <section className='section'>
                <div className='sub-header'>
                    <div className='subheader-box'>
                        <div className="tab-container">
                            <div className="tab">
                                <Link to={subDomainUrl+'/curriculum/product'} className='tab'><button
                                    className={`tab ${activeTab === 'product' ? 'active' : ''}`}>
                                    Product
                                </button>
                                </Link>
                               <Link to={subDomainUrl+'/curriculum/curriculum'} className='tab'><button
                                    className={`tab ${activeTab === 'curriculum' ? 'active' : ''}`}>
                                    Curriculum
                                </button>
                                </Link>
                                <AuthorizedComponent allowedRoles={allowedRoles}
                                                     AuthComponent={<> <Link to={subDomainUrl+'/curriculum/grade'} className='tab'><button
                                                         className={`tab ${activeTab === 'grade' ? 'active' : ''}`}>
                                                         Grade
                                                     </button>
                                                     </Link>

                                                     </>}
                                                     Component={''}/>
                                <Link to={subDomainUrl+'/curriculum/subject'} className='tab'><button
                                    className={`tab ${activeTab === 'subject' ? 'active' : ''}`}>
                                    Subject
                                </button>
                                </Link>
                                <Link to={subDomainUrl+'/curriculum/topic'} className='tab'><button
                                    className={`tab ${activeTab === 'topic' ? 'active' : ''}`}>
                                    Topic
                                </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className='subheader-box'>
                        <div className='subheader-components'>
                            <AuthorizedComponent allowedRoles={allowedRoles}
                                                 AuthComponent={<> <Tippy content='Add Grade'>
                                                     <button  className='common-button' onClick={() => setAddModalName('add_grade')}>
                                                         <FontAwesomeIcon className='filter-icon' icon={faSchool} />
                                                     </button>
                                                 </Tippy>
                                                 </>}
                                                 Component={''}/>

                            <Tippy content='Add Subject'>
                                <button  className='common-button' onClick={() => setAddModalName('add_subject')}>
                                    <FontAwesomeIcon className='filter-icon' icon={faChalkboardTeacher} />
                                </button>
                            </Tippy>
                            <Tippy content='Add Topic'>
                                <button  className='common-button' onClick={() => setAddModalName('add_topic')}>
                                    <FontAwesomeIcon className='filter-icon' icon={faLaptopFile} />
                                </button>
                            </Tippy>
                            <Tippy content='Add Curriculum'>
                                <button  className='common-button' onClick={() => setAddModalName('add_curriculum')}>
                                    <FontAwesomeIcon className='filter-icon' icon={faBookOpenReader} />
                                </button>
                            </Tippy>

                            <AddSubjects isOpen={addModalName === 'add_subject'} onRequestClose={() => setAddModalName(false)} />
                            <AddCurriculum isOpen={addModalName === 'add_curriculum'} onRequestClose={() => setAddModalName(false)} />
                            <AddTopics isOpen={addModalName === 'add_topic'} onRequestClose={() => setAddModalName(false)} />
                            <AddGrades isOpen={addModalName === 'add_grade'} onRequestClose={() => setAddModalName(false)} />
                        </div>

                    </div>
                </div>
                <div className="tab-content">
                    {getTabTable()}
                </div>
            </section>
        </>
    )
});

export default CurriculumComponent;
