import {api} from "../../hooks/api/ApiConfig";
import {
    BRAND_DATA,
    CATEGORY_DATA, DISCOUNT_COUPON_LIST_DATA, PRODUCT_CURRICULUM_DATA, PRODUCT_CURRICULUM_IMAGES_DATA,
    PRODUCT_DATA, PRODUCT_GRADES_DATA,
    PRODUCT_IMAGE_DATA, PRODUCT_SUBJECTS_DATA, PRODUCT_TOPICS_DATA,
    SUB_CATEGORY_LIST
} from "../constant/inventoryConstant";
import {
    actionToDeleteAwsObject,
    actionToDeleteStemcityAwsObject,
    actionToUploadDataToS3BucketStore,
    actionToUploadDataToStemcityS3BucketStore,
    callInsertDataWithLogFunction,
    callSoftDeleteDataWithLogFunction, callUpdateDataWithLogFunction
} from "./commonActions";


export const actionToGetCategoriesApiCall = () => async (dispatch) => {
    dispatch({ type: CATEGORY_DATA, payload: 'loading'});
    const {data} = await api.post(`inventory/get-categories`);
    dispatch({ type: CATEGORY_DATA, payload: data });
    return data;
}

export const actionToCreateCategory = (data) => async (dispatch,getState) =>{
    const eventTypeId = getState().inventory.inventoryEventTypeId;
    return await dispatch(callInsertDataWithLogFunction({tableName:'categories',data,userId:data['created_by'],eventTypeId}));
}

export const actionToDeleteCategory = (data) => async (dispatch,getState) =>{
    const eventTypeId = getState().inventory.inventoryEventTypeId;
    return await dispatch(callSoftDeleteDataWithLogFunction({tableName:'categories',id:data['id'],userId:data['created_by'],eventTypeId}));
}
export const actionToGetSubCategoriesApiCall = () => async (dispatch) => {
    dispatch({ type: SUB_CATEGORY_LIST, payload: 'loading'});
    const {data} = await api.post(`inventory/get-sub-categories`);
    dispatch({ type: SUB_CATEGORY_LIST, payload: data});
    return data;
}
export const actionToGetBrandsApiCall = () => async (dispatch) => {
    dispatch({ type: BRAND_DATA, payload: 'loading'});
    const {data} = await api.post(`inventory/get-brands`);
    dispatch({ type: BRAND_DATA, payload: data});
    return data;
}
export const actionToGetProductApiCall = (payload) => async (dispatch) => {
    dispatch({ type: PRODUCT_DATA, payload: 'loading'});
    const {data} = await api.post(`inventory/get-products`,payload);
    dispatch({ type: PRODUCT_DATA, payload: data });
    return data;
}
export const uploadFileOnAws = (payload) =>async (dispatch)=>{
    let imagePayload = {
        blobData:payload?.photo,
        key:payload?.filename,
        type:payload?.type,
    }
    let url ='';
    if(payload.showOnWebsite?.toLowerCase() === 'stemcity'){
        payload?.deletedFilename && await dispatch(actionToDeleteStemcityAwsObject({key:payload?.deletedFilename}));
        url =await dispatch(actionToUploadDataToStemcityS3BucketStore(imagePayload));
    }else{
        payload?.deletedFilename && await dispatch(actionToDeleteAwsObject({key:payload?.deletedFilename}));
        url =await dispatch(actionToUploadDataToS3BucketStore(imagePayload));
    }
    return url;
}
export const actionToUploadMainImageApiCall = (payload) => async (dispatch) =>{
    let name = payload.name.replace(/\s+/g, '-').toLowerCase();
    let fileExtension = payload.fileExtension ? payload.fileExtension : 'png';
    let filename = `${payload.folder}/${name}-${payload.id}.${fileExtension}`;
    let fileType = payload.fileType ? payload.fileType : 'img/png'
    let url = await dispatch(uploadFileOnAws({filename:filename,deletedFilename:filename,photo:payload?.photo,showOnWebsite:payload?.showOnWebsite,type:fileType}));
    dispatch(callUpdateDataWithLogFunction({tableName:'products',data: {photo:url},id:payload?.id,userId:payload?.auth_id,eventTypeId:payload?.eventTypeId})).then(() => {
        dispatch(actionToGetProductApiCall());
    })
}
export const actionToUploadImageSliderApiCall = (payload) => async (dispatch) =>{
    payload.newImages.map( async photo =>{
        let name = payload.name.replace(/\s+/g, '-').toLowerCase();
        let filename = `products/${name}-${payload.productId}-${Math.random()}.png`;
        let url = await dispatch(uploadFileOnAws({filename:filename,photo:photo,showOnWebsite:payload?.showOnWebsite,type:'img/png'}));
        dispatch(callInsertDataWithLogFunction({tableName:'product_photos',data:{product_id:payload.productId,url:url},userId:payload?.auth_id,eventTypeId:payload?.eventTypeId})).then(() => {
            dispatch(actionToGetProductImagesApiCall(payload))
        });
    })
}
export const actionToGetProductImagesApiCall = (payload) => async (dispatch) => {
    const {data} = await api.post(`inventory/get-product-images`,{id:payload?.productId});
    dispatch({ type: PRODUCT_IMAGE_DATA, payload: data });
    return data;
}
export const actionToGetProductCurriculumApiCall = (payload) => async (dispatch) => {
    dispatch({ type: PRODUCT_CURRICULUM_DATA, payload: 'loading'});
    const {data} = await api.post(`inventory/get-product-curriculum`,{id:payload?.productId});
    dispatch({ type: PRODUCT_CURRICULUM_DATA, payload: data });
    return data;
}
export const actionToGetProductGradeApiCall = (payload) => async (dispatch) => {
    const {data} = await api.post(`inventory/get-product-grade`,{id:payload?.productId});
    dispatch({ type: PRODUCT_GRADES_DATA, payload: data });
    return data;
}
export const actionToGetProductSubjectApiCall = (payload) => async (dispatch) => {
    const {data} = await api.post(`inventory/get-product-subject`,{id:payload?.productId});
    dispatch({ type: PRODUCT_SUBJECTS_DATA, payload: data });
    return data;
}
export const actionToGetProductTopicApiCall = (payload) => async (dispatch) => {
    const {data} = await api.post(`inventory/get-product-topic`,{id:payload?.productId});
    dispatch({ type: PRODUCT_TOPICS_DATA, payload: data });
    return data;
}
export const actionToGetDiscountCouponForProductApiCall = () => async (dispatch) => {
    const {data} = await api.post(`inventory/get-discount-coupon-for-product`);
    dispatch({ type: DISCOUNT_COUPON_LIST_DATA, payload: data });
    return data;
}
export const actionToGetProductCurriculumImagesApiCall = (payload) => async (dispatch) => {
    dispatch({ type: PRODUCT_CURRICULUM_IMAGES_DATA, payload: 'loading' });
    const {data} = await api.post(`curriculum/get-curriculum-file-by-curriculum-id`,{id:payload?.product_curriculum_id});
    dispatch({ type: PRODUCT_CURRICULUM_IMAGES_DATA, payload: data });
    return data;
}