import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useEffectOnce} from "../../../../hooks/useEffectOnce";
import {Virtuoso} from "react-virtuoso";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBookOpen,
    faBookOpenReader,
    faCircleInfo,
    faCommentDots,
    faImages,
    faPenToSquare,
    faTrash,
    faChalkboardTeacher,
    faLaptopFile,
    faSchool
} from "@fortawesome/free-solid-svg-icons";
import EditProduct from "./EditProduct";
import PreLoader from "../../../loader/PreLoader";
import swal from "sweetalert";
import {faFilePen} from "@fortawesome/free-solid-svg-icons/faFilePen";
import EditProductComponents from "./EditProductComponents";
import UploadSliderImage from "./UploadSliderImage";
import AddBriefDescription from "./AddBriefDescription";
import {actionToGetProductApiCall, actionToUploadMainImageApiCall} from "../../../../store/action/inventoryActions";
import {callSoftDeleteDataWithLogFunction} from "../../../../store/action/commonActions";
import useAuth from "../../../../hooks/useAuth";
import ProductDetailUpdate from "./ProductDetailUpdate";
import {ProductCurriculumTable} from "./product_curriculum/ProductCurriculumTable";
import AddProductCurriculum from "./product_curriculum/AddProductCurriculum";
import Tippy from "@tippyjs/react";
import 'tippy.js/dist/tippy.css';
import AddProductGrade from "./AddProductGrade";
import AddProductSubject from "./AddProductSubject";
import AddProductTopic from "./AddProductTopic";
import AuthorizedComponent from "../../../auth/AuthorizedComponent";
import AddCurriculum from "../../../Curriculum/curriculum/AddCurriculum";
import CompanyPipeline from "../../../WebSetting/website_settings/company/CompanyPipeline";


export const ProductTable = ({}) => {
    const [isEditProductOpen, setIsEditProductOpen] = useState(false);
    const [isEditProductComponentOpen, setIsEditProductComponentOpen] = useState(false);
    const [isUploadSliderImagesOpen, setIsUploadSliderImagesOpen] = useState(false);
    const [selectedProductIndex,setSelectedProductIndex] = useState(false);
    const [isAddBriefDescriptionOpen, setIsAddBriefDescriptionOpen] = useState(false);
    const [isEditProductDetailOpen, setIsEditProductDetailOpen] = useState(false);
    const [isProductCurriculumTableOpen, setIsProductCurriculumTableOpen] = useState(false);
    const [isAddProductCurriculumOpen, setIsAddProductCurriculumOpen] = useState(false);
   // const [isAddProductRelatedPopupOpen, setIsAddProductRelatedPopupOpen] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const dispatch = useDispatch();
    const hiddenFileInput = useRef(null); // ADDED
    const {auth} = useAuth();
    const eventTypeId = useSelector((state) =>  state.inventory.inventoryEventTypeId);
    const productData = useSelector((state) =>  state.inventory?.productData);
    const ROLES = useSelector((state) =>  state.webSetting.roleList);
    const allowedRoles=[ROLES.SuperAdmin,ROLES.Admin,ROLES.WebsiteEditor,ROLES.InventoryManager];
    const headingClassName = allowedRoles?.includes(auth?.role) ?'product-heading-cell' : 'six-column-cell';
    const className = allowedRoles?.includes(auth?.role) ?'eleven-column-cell' : 'six-column-cell';
    const rowClassName = allowedRoles?.includes(auth?.role) ?'product-table-row' : 'product-table-small-row';
    const tableClassName = allowedRoles?.includes(auth?.role) ?'table-default-container' : 'table-container';
    const actionClassName = allowedRoles?.includes(auth?.role) ?'product-heading-action-cell' : 'product-heading-small-action-cell';
    const actionComponentClassName = allowedRoles?.includes(auth?.role) ?'action-components' : 'action-small-components';

    const [source,setSource]=useState(auth?.source);
    const [productsData, setProductsData] = useState([]);

    const changeCompany = (val)=>{
        setSource(val.id);
    }

    const subCategoryFilterFunction = () =>{
        let productList=[]
        productData !== 'loading' && productData?.map(productArray=>{
            if(productArray.source ==source){
                productList.push(productArray);
            }
        })
        setProductsData(productList);
    }
    useEffect(() => {
        if(source){
            subCategoryFilterFunction();
        }
    }, [source,productData]);

    useEffectOnce(()=>{
        dispatch(actionToGetProductApiCall());
    })

    const handlePhotoClick = (index) => {
        setSelectedProductIndex(index);
        hiddenFileInput.current.click();    // ADDED
    };
    const handleMainPhotoUpload = event => {
        const fileUploaded = event.target.files[0];
        let selectedProduct =productsData[selectedProductIndex];
        dispatch(actionToUploadMainImageApiCall({photo:fileUploaded,id:selectedProduct.id,showOnWebsite:selectedProduct.company_name,folder:'products',name:selectedProduct.name,auth_id:auth?.id,eventTypeId:eventTypeId}));
    };

    const deleteProduct = (row) => {
        swal({
            title: "Are you sure?",
            text: "You want to delete this Product "+row?.name,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (success) => {
                if (success) {
                    dispatch(callSoftDeleteDataWithLogFunction({tableName:'products',id:row.id,userId:auth?.id,eventTypeId})).then(() => {
                        dispatch(actionToGetProductApiCall())
                    })
                }
            });

    };

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedRows([]);
        } else {
            setSelectedRows(productsData.map(item => item.id));
        }
        setSelectAll(!selectAll);
    };

    const handleSelectRow = (id) => {
        const newSelectedRows = selectedRows.includes(id)
            ? selectedRows.filter(rowId => rowId !== id)
            : [...selectedRows, id];

        setSelectedRows(newSelectedRows);
        setSelectAll(newSelectedRows.length === productsData.length);
    };
    return(
        <>
            <div className={tableClassName}>
            {
                productData === 'loading' ?
                    <PreLoader/>
                    :
                    <>
                        <CompanyPipeline source={source} changeCompany={changeCompany}/>
                        <div className="table">
                            <div className="row-header">
                                <div className={headingClassName}>
                                    <AuthorizedComponent allowedRoles={allowedRoles}
                                                         AuthComponent={<> <input className='checkbox' type="checkbox"
                                                                                                             checked={selectAll}
                                                                                                             onChange={handleSelectAll}/>
                                                            </>}
                                                         Component={''}/>

                                    Product Name
                                </div>
                                <AuthorizedComponent allowedRoles={allowedRoles}
                                                     AuthComponent={<>   <div className={headingClassName}>Company</div>
                                                     </>}
                                                     Component={''}/>

                                <div className={headingClassName}>Category</div>
                                <div className={headingClassName}>Sub-Category</div>

                                <AuthorizedComponent allowedRoles={allowedRoles}
                                                     AuthComponent={<>  <div className={headingClassName}>Slug</div>
                                                     </>}
                                                     Component={''}/>
                                <div className={headingClassName}>Brand</div>
                                <AuthorizedComponent allowedRoles={allowedRoles}
                                                     AuthComponent={<>    <div className="product-heading-quantity-cell">Quantity</div>
                                                         <div className={headingClassName}>Purchase Price</div>
                                                         <div className={headingClassName}>Sale Price</div>
                                                     </>}
                                                     Component={''}/>

                                <div className={headingClassName}>Image</div>
                                <div className={actionClassName}>Action</div>
                            </div>
                            <Virtuoso style={{ height: '700px' }} totalCount={productsData.length} itemContent={index =>
                                <div className={rowClassName}>
                                    <div className={className}>
                                        <input className='checkbox' type="checkbox"
                                               checked={selectedRows.includes(productsData[index]?.id)}
                                               onChange={() => handleSelectRow(productsData[index]?.id)}/>
                                        {productsData[index]?.name}
                                    </div>
                                    <AuthorizedComponent allowedRoles={allowedRoles}
                                                         AuthComponent={<>   <div className={className}>{productsData[index]?.company_name}</div>
                                                         </>}
                                                         Component={''}/>

                                    <div className={className}>{productsData[index]?.category_name}</div>
                                    <div className={className}>{productsData[index]?.sub_category_name}</div>

                                    <AuthorizedComponent allowedRoles={allowedRoles}
                                                         AuthComponent={<><div className={className}>{productsData[index]?.slug}</div>
                                                         </>}
                                                         Component={''}/>

                                    <div className={className}>{productsData[index]?.brand_name}</div>
                                    <AuthorizedComponent allowedRoles={allowedRoles}
                                                         AuthComponent={<><div className="product-quantity-cell">{productsData[index]?.in_stock_quantity}</div>
                                                             <div className={className}>{productsData[index]?.purchase_price}</div>
                                                             <div className={className}>{productsData[index]?.sale_price}</div>
                                                         </>}
                                                         Component={''}/>

                                    <div className={className} onClick={()=>{allowedRoles?.includes(auth?.role) &&handlePhotoClick(index)}}>
                                        <img src={productsData[index]?.photo} height="35" width="35" alt={productsData[index]?.name}/></div>
                                    <div className={className}>
                                        <div className='action'>
                                            <AuthorizedComponent allowedRoles={allowedRoles}
                                                                 AuthComponent={<>

                                                                 <div className={actionComponentClassName}>
                                                                     {productsData[index]?.in_website_use ==='1'&& <>
                                                                     <div className='action-buttons'>
                                                                     <Tippy content='Add Slider Images'>
                                                                         <button className='action-button' onClick={() => {setIsUploadSliderImagesOpen(true);  setSelectedProductIndex(index);}}>
                                                                             <FontAwesomeIcon icon={faImages}/>
                                                                         </button>
                                                                     </Tippy>
                                                                 </div>
                                                                     <div className='action-buttons'>
                                                                         <Tippy content='Add Brief Description'>
                                                                             <button className='action-button' onClick={() => {setIsAddBriefDescriptionOpen(true);  setSelectedProductIndex(index);}}>
                                                                                 <FontAwesomeIcon icon={faCommentDots}/>
                                                                             </button></Tippy>
                                                                     </div>
                                                                     <div className='action-buttons'>
                                                                         <Tippy content='Edit Product Detail'>
                                                                             <button className='action-button' onClick={() => {setIsEditProductDetailOpen(true); setSelectedProductIndex(index);}} >
                                                                                 <FontAwesomeIcon className='action-icon' icon={faCircleInfo} />
                                                                             </button></Tippy>
                                                                     </div></>}
                                                                     <div className='action-buttons'>
                                                                         <Tippy content='Edit Product'>
                                                                             <button className='action-button' onClick={() => {setIsEditProductOpen(true); setSelectedProductIndex(index);}} >
                                                                                 <FontAwesomeIcon className='action-icon' icon={faPenToSquare} />
                                                                             </button></Tippy>
                                                                     </div>
                                                                 </div>
                                                                 </>}
                                                                 Component={''}/>



                                        <div className={actionComponentClassName}>
                                            <div className='action-buttons'>
                                                <Tippy content='Edit Product Components'>
                                                    <button className='action-button' onClick={() => {setIsEditProductComponentOpen(true); setSelectedProductIndex(index);}} >
                                                        <FontAwesomeIcon className='action-icon' icon={faFilePen} />
                                                    </button></Tippy>
                                            </div>
                                            {productsData[index]?.in_curriculum_use ==='1' && <>

                                            <div className='action-buttons'>
                                                <Tippy content='Product Curriculum Table'>
                                                    <button className='action-button' onClick={() => {setIsProductCurriculumTableOpen(true); setSelectedProductIndex(index);}} >
                                                        <FontAwesomeIcon className='action-icon' icon={faBookOpenReader} />
                                                    </button></Tippy>
                                            </div>
                                                <div className='action-buttons'>
                                                    <Tippy content='Add Product Curriculum'>
                                                        <button className='action-button' onClick={() => {setIsAddProductCurriculumOpen(true); setSelectedProductIndex(index);}} >
                                                            <FontAwesomeIcon className='action-icon' icon={faBookOpen} />
                                                        </button></Tippy>
                                                </div>
                                            </>}

                                            <AuthorizedComponent allowedRoles={allowedRoles}
                                                                 AuthComponent={<><div className='action-buttons'>
                                                                     <Tippy content='Delete Product'>
                                                                         <button onClick={()=>deleteProduct(productsData[index])} className='action-button'>
                                                                             <FontAwesomeIcon className='action-icon' icon={faTrash} />
                                                                         </button></Tippy>
                                                                 </div>
                                                                 </>}
                                                                 Component={''}/>

                                        </div>
                                        </div>
                                    </div>
                                </div>
                            } />
                        </div>
                        </>
            }
            <input
                type="file"
                onChange={handleMainPhotoUpload}
                ref={hiddenFileInput}
                style={{display: 'none'}} />
        <EditProduct isOpen={isEditProductOpen} selectedProduct={productsData[selectedProductIndex]} onRequestClose={() => {setIsEditProductOpen(false); setSelectedProductIndex(false);}} />
        <EditProductComponents isOpen={isEditProductComponentOpen} selectedProduct={productsData[selectedProductIndex]} onRequestClose={() => {setIsEditProductComponentOpen(false); setSelectedProductIndex(false);}} />
        <AddBriefDescription isOpen={isAddBriefDescriptionOpen} selectedProduct={productsData[selectedProductIndex]}  onRequestClose={() =>{ setIsAddBriefDescriptionOpen(false); setSelectedProductIndex(false);}}/>
        <ProductDetailUpdate isOpen={isEditProductDetailOpen} selectedProduct={productsData[selectedProductIndex]}  onRequestClose={() =>{ setIsEditProductDetailOpen(false); setSelectedProductIndex(false);}}/>
        <ProductCurriculumTable isOpen={isProductCurriculumTableOpen} selectedProduct={productsData[selectedProductIndex]}  onRequestClose={() =>{ setIsProductCurriculumTableOpen(false); setSelectedProductIndex(false);}}/>
        <AddCurriculum isOpen={isAddProductCurriculumOpen} selectedProduct={productsData[selectedProductIndex]}  onRequestClose={() =>{ setIsAddProductCurriculumOpen(false); setSelectedProductIndex(false);}}/>
        <UploadSliderImage isOpen={isUploadSliderImagesOpen}  selectedProduct={productsData[selectedProductIndex]}  onRequestClose={() => {setIsUploadSliderImagesOpen(false); setSelectedProductIndex(false);}} />
         </div>
            </>
    )
}
