import React, {useEffect, useRef, useState} from 'react';
import Modal from 'react-modal';
import {useDispatch, useSelector} from "react-redux";
import { uploadFileOnAws} from "../../../../store/action/inventoryActions";
import {callUpdateDataWithLogFunction} from "../../../../store/action/commonActions";
import useAuth from "../../../../hooks/useAuth";
import {Editor} from "@tinymce/tinymce-react";
import CategoryDropdown from "../../../inventory/inventory/category/CategoryDropdown";
import ProductDropdown from "../../../inventory/inventory/product/ProductDropdown";
import SelectGradeDropdown from "../../../Curriculum/grade/SelectGradeDropdown";
import SelectCurriculumDropdown from "../../../Curriculum/curriculum/SelectCurriculumDropdown";
import {actionToGetWebSettingSectionApiCall} from "../../../../store/action/webSettingActions";


const EditWebsiteSectionContent= ({ isOpen,selectedData, onRequestClose,mainData }) => {
    const eventTypeId = useSelector((state) =>  state.webSetting.webSettingEventTypeId);
    const dispatch = useDispatch();
    const {auth} = useAuth();
    const [isFormChanged, setIsFormChanged] = useState(false);
    const [formData, setFormData] = useState({ });
    const hiddenFileInput = useRef(null);
    const [errors, setErrors] = useState({});
    const [saveStatus, setSaveStatus] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedFormData = {
            ...formData,
            [name]: value
        };
        setFormData(updatedFormData);

        // Check if the updated form data differs from the initial data
        setIsFormChanged(JSON.stringify(updatedFormData) !== JSON.stringify(selectedData));
    };

    const handleSubmit = async(e) => {
        e.preventDefault()
        const formValue = new FormData(e.target);
        const obj = Object.fromEntries(formValue.entries());
        const newErrors = validateForm(obj);
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            if(['file','image','audio','video'].includes(obj.object_type) && obj['object_value']?.name?.trim() !== ''){
                // Get the file extension
                let filename = `website/content-${selectedData.id}-${obj['object_value']?.name}`;
                obj['object_value'] = await dispatch(uploadFileOnAws({filename:filename,photo:formData?.object_value,showOnWebsite:mainData.company_name,type:obj['object_value'].type}));
            }else{
                obj['object_value'] = formData?.object_value;
            }
            dispatch(callUpdateDataWithLogFunction({tableName:'website_section_content',data:obj,id:selectedData?.id,userId:auth?.id,eventTypeId})).then(res => {
                let status = res.success ===1 ? 'success' : 'fail'
                setSaveStatus(status);
                if(status === 'success'){
                    dispatch(actionToGetWebSettingSectionApiCall());
                    onRequestClose();
                }
            })
        } else {
            setSaveStatus('fail');
        }
    };

    useEffect(() => {
        setFormData(selectedData);
    }, [selectedData]);

    const validateForm = (data) => {
        const errors = {};

        if (!data.position.trim()) {
            errors.position = 'Position is in page is required';
        }


        return errors;
    };
    const handleEditorChange = (content) => {
        handleChange({target:{name:'object_value',value:content}});
    };

    const handlePhotoClick = () => {
        hiddenFileInput.current.click();    // ADDED
    };

    const handleContentFileUpload=(event)=>{
        const fileUploaded = event.target.files[0];
        handleChange({target:{name:'object_value',value:fileUploaded}});
    }
    const objectValueInput = (type,value) => {
        switch (type){
            case 'product':
                return <ProductDropdown errors={errors} name={'object_value'} source={formData?.source} product={formData.object_value} handleChange={handleChange}/>;
            case 'category':
                return <CategoryDropdown errors={errors} name={'object_value'} source={formData?.source} category={formData.object_value}  handleChange={handleChange}/>;
            case 'grade':
                return <SelectGradeDropdown errors={errors} name={'object_value'} grade_id={formData.object_value} handleChange={handleChange}/>;
            case 'curriculum':
                return <SelectCurriculumDropdown errors={errors} name={'object_value'} curriculum_id={formData.object_value} handleChange={handleChange}/>;
            case 'text':
                return <input type='text' placeholder='Website Content Value' name='object_value' className='form-input' value={value} onChange={handleChange}/>;
            case 'textarea':
                return <textarea className='text-area' placeholder='Website Content Value' name='object_value' value={value} onChange={handleChange}/>;
            case 'image':
                return  <>
                    <img src={value} height="35" width="35" alt={value} onClick={()=>handlePhotoClick()}/>
                    <input  multiple={false}
                            type="file"
                            onChange={(e)=>handleContentFileUpload(e)}
                            ref={hiddenFileInput}
                            name='object_value'
                            style={{display: 'none'}}
                            accept={"image/*,img/*"} />
                </>
            case 'file':
                return  <input multiple={false} name='object_value' className="content-upload-input" onChange={(e)=>handleContentFileUpload(e)}
                               ref={hiddenFileInput} type='file' accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf"/>;
            case 'video':
                return  <input multiple={false} name='object_value' className="content-upload-input" type='file' onChange={(e)=>handleContentFileUpload(e)}
                               ref={hiddenFileInput} accept={"video/*"}/>;
            case 'audio':
                return  <input multiple={false} name='object_value' className="content-upload-input" type='file' onChange={(e)=>handleContentFileUpload(e)}
                               ref={hiddenFileInput} accept={"audio/*"}/>;

            case 'editor':
                return <Editor
                    apiKey="nchrb4md98keu69dxd2sjsc25q52blhw3z0ryes84hw6wscr"
                    initialValue={value}
                    init={{
                        height: 500,
                        plugins: [
                            'advlist', 'autolink', 'link', 'image', 'lists', 'charmap', 'preview', 'anchor', 'pagebreak',
                            'searchreplace', 'wordcount', 'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media',
                            'table', 'emoticons', 'help'
                        ],
                        toolbar: 'undo redo | print preview code media fullscreen | styles bold italic | alignleft aligncenter alignright alignjustify |codeformat | ' +
                            'bullist numlist outdent indent | link image | ' +
                            'forecolor backcolor emoticons | help',
                        menu: {
                            favs: { title: 'My Favorites', items: 'code visualaid | searchreplace | emoticons' }
                        },
                        menubar: 'favs file edit view insert format tools table help',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                    }}
                    name='object_value'
                    onEditorChange={handleEditorChange}
                />
            default:
                return <input type='text' placeholder='Website Content Value' name='object_value' className='form-input' value={value} onChange={handleChange}/>;
        }
    }
    useEffect(() => {
        setErrors({});
        setSaveStatus({});
    }, [isOpen]);
    const getObjectTypeDropdown=(component_type,type)=>{
        switch (component_type){
            case 'product_card':
                return( <><option value="product" selected={type==='product'}>Product</option>
                </>)
            case 'category_card':
                return( <><option value="category" selected={type==='category'}>Category</option>
                </>)
            case 'grade_card':
                return( <><option value="grade" selected={type==='grade'}>Grade</option>
                </>)
            case 'curriculum_card':
                return(  <option value="curriculum" selected={true}>Curriculum</option>)
            case 'banner':
                return( <>
                    <option value="image" selected={type==='image'}>Image</option>
                    <option value="video" selected={type==='video'}>Video</option>
                </>)
            default:
                return(
                    <>   <option value="text" selected={type==='text'}>Text</option>
                        <option value="textarea" selected={type==='textarea'}>Text area</option>
                        <option value="editor" selected={type==='editor'}>Editor</option>
                        <option value="file" selected={type==='file'}>File</option>
                        <option value="image" selected={type==='image'}>Image</option>
                        <option value="video" selected={type==='video'}>Video</option>
                        <option value="audio" selected={type==='audio'}>Audio</option>
                    </>
                )
        }


    }
    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='Modal' ariaHideApp={false}>
            {formData?.id ?<div className="container">
                <div className='title'>Edit Website Content</div>
                <form action='#' onSubmit={(e) => handleSubmit(e)}>
                    <div className='vendor-details'>
                        <div className='input-box'>
                            <span className='details'>Name</span>
                            <input type='text' placeholder='Website Content Name*'
                                   name='name' className='form-input'
                                   value={formData?.name}
                                   onChange={handleChange}/>
                            {errors?.name && <span className="error-message">{errors.name} </span>}
                        </div>


                        <div className='input-box'>
                            <span className='details'>Website Object Heading</span>
                            <input type='text' placeholder='Website Heading' name='object_heading' className='form-input' value={formData.object_heading} onChange={handleChange}/>
                            {errors.object_heading &&  <span className="error-message"> {errors.object_heading} </span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Website Object Sub Heading</span>
                            <input type='text' placeholder='Website Sub Heading' name='object_subheading' className='form-input' value={formData.object_subheading} onChange={handleChange}/>
                            {errors.object_subheading &&  <span className="error-message"> {errors.object_subheading} </span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Call to action Name</span>
                            <input type='text' placeholder='Call to Action Name' name='call_to_action_name' className='form-input' value={formData.call_to_action_name} onChange={handleChange}/>
                            {errors.call_to_action_name &&  <span className="error-message"> {errors.call_to_action_name} </span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Call to action Link</span>
                            <input type='text' placeholder='Call to Action Link' name='call_to_action_link' className='form-input' value={formData.call_to_action_link} onChange={handleChange}/>
                            {errors.call_to_action_link &&  <span className="error-message"> {errors.call_to_action_link} </span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Website Content Extras</span>
                            <input type='text' placeholder='Website Content Extra' name='object_extras' className='form-input' value={formData.object_extras} onChange={handleChange}/>
                            {errors.object_extras &&  <span className="error-message"> {errors.object_extras} </span> }
                        </div>

                        <div className='input-box'>
                            <span className='details'>Object Class</span>
                            <input type='text' name='object_class' className='form-input' value={formData.object_class} onChange={handleChange}/>
                            {errors.object_class &&  <span className="error-message"> {errors.object_class} </span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Object Size</span>
                            <input type='text' name='object_size' className='form-input' value={formData.object_size} onChange={handleChange}/>
                            {errors.object_size &&  <span className="error-message"> {errors.object_size} </span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Website Position</span>
                            <input type='number' placeholder='Website Content Extra' name='position' className='form-input' value={formData.position} onChange={handleChange}/>
                            {errors.position &&  <span className="error-message"> {errors.position} </span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Object Type</span>
                            <select className='select' name='object_type' value={formData.object_type} placeholder='Object Type' onChange={(e)=>handleChange(e)}>
                                {getObjectTypeDropdown(mainData?.component_type,formData.object_type)}
                            </select>
                            {errors.object_type && <span className="error-message"> {errors.object_type}</span> }
                        </div>

                        <div className='input-box'>
                            {objectValueInput(formData.object_type,formData.object_value)}
                            {errors.object_value && <span className="error-message"> {errors.object_value}</span> }
                        </div>


                    </div>
                    <div className='buttons'>
                        <div className='button'>
                            <button className='submit-button' type='submit'  disabled={!isFormChanged}>Update</button>
                        </div>
                        <div className='button'>
                            <button className='close-button' onClick={onRequestClose}>Close</button>
                        </div>
                    </div>
                    {saveStatus==='success' ? <div className="success-message">Website Content Updated successfully!!</div> : saveStatus==='fail' && <div className='failure-message'>Something went Wrong</div> }
                </form>

            </div> : ''}
        </Modal>
    );
};


export default EditWebsiteSectionContent;
