import {Navigate, Route, Routes} from "react-router-dom";
import React from "react";
import SettingsComponent from "../Components/WebSetting/SettingsComponent";
import {WebsiteSectionTable} from "../Components/WebSetting/website_settings/section_content/WebsiteSectionTable";
import {MenuTable} from "../Components/WebSetting/website_settings/menu/MenuTable";
import {SeoSettingTable} from "../Components/WebSetting/website_settings/seo_setting/SeoSettingTable";
import {CompanyTable} from "../Components/WebSetting/website_settings/company/CompanyTable";
import MenuDetail from "../Components/WebSetting/website_settings/menu/MenuDetail";
export default function SettingsPage() {
    return (
        <>
            <Routes>
                <Route path="" exact element={<Navigate to="menu" replace />} />
                <Route element={<SettingsComponent />}>
                    <Route path="section" element={<WebsiteSectionTable />} />
                    <Route path="menu" element={<MenuTable />} />
                    <Route path="menu/:menu_id" element={<MenuDetail />} />
                    <Route path="seo-meta" element={<SeoSettingTable />} />
                    <Route path="company" element={<CompanyTable />} />
                </Route>
            </Routes>
        </>
    )
}