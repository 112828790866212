import {api} from "../../hooks/api/ApiConfig";
import {
    COMPANY_DATA_LIST, COMPONENT_DATA_LIST,
    MENU_DATA_LIST, MENU_DETAIL,
    SEO_META_DATA_LIST,
    SEO_REFERENCE_HTML,
    WEB_SETTING_CONTENT, WEB_SETTING_SECTION,
    WEBSITE_CONTENT
} from "../constant/webSettingConstant";

export const actionToGetWebSettingContentApiCall = () => async (dispatch) => {
    dispatch({ type: WEB_SETTING_CONTENT, payload: 'loading' });
    const {data} = await api.post(`web-setting/get-web-setting-content`);
    dispatch({ type: WEB_SETTING_CONTENT, payload: data });
    return data;
}
export const actionToGetWebSettingSectionApiCall = () => async (dispatch) => {
    dispatch({ type: WEB_SETTING_SECTION, payload: 'loading' });
    const {data} = await api.post(`web-setting/get-web-setting-section-content`);
    dispatch({ type: WEB_SETTING_SECTION, payload: data });
    return data;
}
export const actionToGetWebsiteContentApiCall = (payload) => async (dispatch) => {
    dispatch({ type: WEBSITE_CONTENT, payload: 'loading' });
    const {data} = await api.post(`web-setting/get-website-content`,{id:payload?.source});
    dispatch({ type: WEBSITE_CONTENT, payload: data });
    return data;
}
export const actionToGetWebSettingSeoMetaApiCall = (payload) => async (dispatch) => {
    dispatch({ type: SEO_META_DATA_LIST, payload: 'loading' });
    const {data} = await api.post(`web-setting/get-web-setting-seo-meta-data`,{id:payload?.source});
    dispatch({ type: SEO_META_DATA_LIST, payload: data });
    return data;
}
export const actionToGetWebSettingSeoReferenceHtmlApiCall = () => async (dispatch) => {
    const {data} = await api.post(`web-setting/get-web-setting-seo-reference-html`);
    dispatch({ type: SEO_REFERENCE_HTML, payload: data });
    return data;
}
export const actionToGetMenuListApiCall = (payload) => async (dispatch) => {
    const {data} = await api.post(`web-setting/get-menu-list`,payload);
    dispatch({ type: MENU_DATA_LIST, payload: data });
    return data;
}
export const actionToGetMenuDetailApiCall = (payload) => async (dispatch) => {
    const {data} = await api.post(`web-setting/get-menu-detail`,payload);
    dispatch({ type: MENU_DETAIL, payload: data.length > 0 ?data[0]:data });
    return data;
}
export const actionToGetCompanyListApiCall = () => async (dispatch) => {
    const {data} = await api.post(`common-log/get-company-list`);
    dispatch({ type: COMPANY_DATA_LIST, payload: data });
    return data;
}
export const actionToGetComponentListApiCall = () => async (dispatch) => {
    const {data} = await api.post(`web-setting/get-component-list`);
    dispatch({ type: COMPONENT_DATA_LIST, payload: data });
    return data;
}
