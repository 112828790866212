import Dashboard from "../Components/Dashboard/Dashboard";
import {Suspense} from "react";
export default function Home() {
    return (
        <>
            {/* Suspense for lazy-loaded components */}
            <Suspense fallback={<div>Loading...</div>}>
                <Dashboard />
            </Suspense>
        </>
    )
}