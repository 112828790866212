import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";
import Select from "react-select";
import { actionToGetTopicsApiCall} from "../../../store/action/curriculumAction";
import {callDeleteDataWithLogFunction, callInsertDataWithLogFunction} from "../../../store/action/commonActions";
import useAuth from "../../../hooks/useAuth";
import {actionToGetRegionsApiCall} from "../../../store/action/salesAction";

const AddRegionState = (props) => {
    const dispatch = useDispatch();
    const [loading,setLoading] = useState(true);
    const {auth} = useAuth();
    const [errors, setErrors] = useState({});
    const eventTypeId = useSelector((state) =>  state.sales.salesEventTypeId);
    const stateListData = useSelector((state) =>  state.common.stateList);
    const [stateDropdown, setStateDropdown] = useState([]);
    const selectedData = props.selectedData;
    //const [selectedSubjectTopic,setSelectedSubjectTopic] = useState([]);
    const [formData, setFormData] = useState({  });

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedFormData = {
            ...formData,
            [name]: value
        };
        setFormData(updatedFormData);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formValue = new FormData(e.target);
        const obj = Object.fromEntries(formValue.entries());
        const newErrors = await validateForm(obj);
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
                dispatch(callInsertDataWithLogFunction({
                    tableName: 'state_region',
                    data: {region_id:selectedData.id,state_id:obj['state_id']},
                    userId: auth?.id,
                    eventTypeId: eventTypeId
                })).then(() => {
                    dispatch(actionToGetRegionsApiCall())
                    resetForm();
                });
        }
    };
    const resetForm = () => {
        document.getElementById('curriculum-file')?.reset();
    };

    const validateForm = (data) => {
        const errors = {};
        if (!data.state_id.trim()) {
            errors.state_id = 'Topic is required';
        }

        return errors;
    };
    const deleteRegionState = (id) =>{
        swal({
            title: "Are you sure?",
            text: "You want to delete this subject topic ",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (success) => {
                if (success) {
                    dispatch(callDeleteDataWithLogFunction({tableName:'state_region',id:id,userId:auth?.id,eventTypeId})).then(() => {
                        dispatch(actionToGetRegionsApiCall())
                    })
                }
            });

    }
    useEffect(() => {
        let stateArray = [];
        setLoading(true);
        Object.values(stateListData)?.map(val=>{
            stateArray.push({value:val.id,label:val.name});
        })
        setStateDropdown(stateArray);
        setTimeout(()=>{
            setLoading(false);
        },1000)
    }, [stateListData,selectedData]);
    return (
        <div>
            <Modal isOpen={props.isOpen} onRequestClose={props.onRequestClose} className='region-state-modal'>
                {loading ? <>Loading ...</> :
                    <div className='container'>
                        <div className='title'>Add State to region</div>
                        <form id={'curriculum-file'} onSubmit={handleSubmit}>
                            <div className='input-box'>
                                <Select name={"state_id"}
                                        defaultValue={formData?.state_id}
                                        value={formData?.state_id}
                                        onChange={(val)=>handleChange({target:{name:'state_id',value:val}})}
                                        options={stateDropdown} className='select'
                                />
                                {errors.state_id && <span className="error-message"> {errors.state_id}</span> }
                            </div>
                            <button type={"submit"}>Add State</button>
                        </form>
                        <table className="image-table">
                            <thead>
                            <tr>
                                <th>Sr No</th>
                                <th>State Name</th>
                                <th>Delete</th>
                            </tr>
                            </thead>
                            <tbody>
                          {selectedData?.state_list?.map((state,index) => (
                                <tr key={state.id}>
                                    <td>{index+1}</td>
                                    <td>{state.state_name}</td>
                                    <td> <FontAwesomeIcon className='action-icon' icon={faTrash} onClick={()=> deleteRegionState(state.id)} /></td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>}
            </Modal>
        </div>
    );
};

export default AddRegionState;
