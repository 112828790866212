import React, {useState,useEffect} from 'react';
//import ReactImageMagnify from 'react-image-magnify';


const ProductImg = ({productDetailsData}) => {
    const [images, setImages] = useState({})
   // const [activeImg, setActiveImage] = useState("")
    useEffect(()=>{
        let tempPhotoArray = [];
        tempPhotoArray[0]=productDetailsData?.photo;
        productDetailsData?.photos?.map((row)=>{
            tempPhotoArray.push(row?.photo)
        });
        setImages(tempPhotoArray);
      //  setActiveImage(tempPhotoArray[0]);
    },[productDetailsData])

    return (
        <>
            <div className='flex flex-col justify-between lg:flex-row border'>
                <div className='flex flex-col gap-6'>
                    <div className='product-image'>
                        {/*<ReactImageMagnify {...{
                            smallImage: {
                                alt: 'W ',
                                isFluidWidth: true,
                                src: activeImg
                            },
                            largeImage: {
                                src: activeImg,
                                width: 1200,
                                height: 1800
                            }
                        }} />*/}
                    </div>
                    <div className='flex-p flex-row justify-between h-24 p-3'>
                        {(images && images?.length>0) ?
                            images?.map((row,key) => (
                                <img src={row} key={key} alt="Image Not Found" height={70} width={70} className='w-24 mb-0 rounded-md cursor-pointer' />
                            )):"No Image Found"}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductImg;


