import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import {useDispatch, useSelector} from "react-redux";
import useAuth from "../../../../hooks/useAuth";
import {callInsertDataWithLogFunction} from "../../../../store/action/commonActions";
import { actionToGetWebSettingSectionApiCall } from "../../../../store/action/webSettingActions";
import {Editor} from "@tinymce/tinymce-react";
import {
    actionToGetCategoriesApiCall,
    actionToGetProductApiCall,
    uploadFileOnAws
} from "../../../../store/action/inventoryActions";
import CategoryDropdown from "../../../inventory/inventory/category/CategoryDropdown";
import {useEffectOnce} from "../../../../hooks/useEffectOnce";
import ProductDropdown from "../../../inventory/inventory/product/ProductDropdown";
import SelectGradeDropdown from "../../../Curriculum/grade/SelectGradeDropdown";
import {actionToGetGradesApiCall} from "../../../../store/action/curriculumAction";
import SelectCurriculumDropdown from "../../../Curriculum/curriculum/SelectCurriculumDropdown";

const AddWebsiteSectionContent = ({ isOpen, onRequestClose,selectedData }) => {
    const eventTypeId = useSelector((state) =>  state.webSetting.webSettingEventTypeId);
    const companyList = useSelector((state) =>  state.users?.companyList);
    const [errors, setErrors] = useState({});
    const [saveStatus, setSaveStatus] = useState({});
    const [objectType, setObjectType] = useState('text');
    const [objectValue, setObjectValue] = useState('');
    const dispatch = useDispatch();
    const {auth} = useAuth();
    const [formData, setFormData] = useState({source:auth?.source});

    const handleEditorChange = (content)=>{
        setObjectValue(content)
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const obj = Object.fromEntries(formData.entries());
        if(objectType == 'editor'){
            obj['object_value'] =objectValue;
        }
        const newErrors = validateForm(obj);
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            obj['created_by']=auth?.id;
            obj['website_section_id']=selectedData.id;
            if(['file','image','audio','video'].includes(obj.object_type)){
                // Get the file extension
                let company = companyList.filter(function(item){
                    return item['id'] == obj?.source
                })
                let filename = `website/content-${obj['object_value'].name}`;
                obj['object_value'] = await dispatch(uploadFileOnAws({filename:filename,photo:obj?.object_value,showOnWebsite:company[0]?.name,type:obj['object_value'].type}));
            }
            dispatch(callInsertDataWithLogFunction({tableName:'website_section_content',data:obj,userId:auth?.id,eventTypeId})).then(res => {
                if(res?.success === 1){
                    dispatch(actionToGetWebSettingSectionApiCall());
                    let status = res?.success === 1 ? 'success' : 'fail'
                    setSaveStatus(status);
                    if (status === 'success') {
                        resetForm();
                    }
                }else{
                    setSaveStatus('fail');
                }
            })
        } else {
            setSaveStatus('fail');
        }
    };
    const objectValueInput = (type) => {
        switch (type){
            case 'product':
                return <ProductDropdown errors={errors} name={'object_value'} source={formData?.source} product={formData.object_value} handleChange={handleChange}/>;
             case 'category':
                return <CategoryDropdown errors={errors} name={'object_value'} source={formData?.source} category={formData.object_value} handleChange={handleChange}/>;
            case 'grade':
                return <SelectGradeDropdown errors={errors} name={'object_value'} grade_id={formData.object_value} handleChange={handleChange}/>;
            case 'curriculum':
                return <SelectCurriculumDropdown errors={errors} name={'object_value'} curriculum_id={formData.object_value} handleChange={handleChange}/>;
            case 'text':
                return <input type='text' placeholder='Website Content Value' name='object_value' className='form-input'/>;
            case 'textarea':
                return <textarea className='text-area' placeholder='Website Content Value' name='object_value'/>;
            case 'file':
                return  <input multiple={false} name='object_value' className="content-upload-input" type='file' accept=
                    "application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
text/plain, application/pdf"/>;
            case 'image':
                return  <input multiple={false} name='object_value' className="content-upload-input" type='file' accept={"image/*"}/>;
            case 'video':
                return  <input multiple={false} name='object_value' className="content-upload-input" type='file' accept={"video/*"}/>;
            case 'audio':
                return  <input multiple={false} name='object_value' className="content-upload-input" type='file' accept={"audio/*"}/>;
            case 'editor':
                return <Editor
                    apiKey="nchrb4md98keu69dxd2sjsc25q52blhw3z0ryes84hw6wscr"
                    init={{
                        height: 500,
                        plugins: [
                            'advlist', 'autolink', 'link', 'image', 'lists', 'charmap', 'preview', 'anchor', 'pagebreak',
                            'searchreplace', 'wordcount', 'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media',
                            'table', 'emoticons', 'help'
                        ],
                        toolbar: 'undo redo | print preview code media fullscreen | styles bold italic | alignleft aligncenter alignright alignjustify |codeformat | ' +
                            'bullist numlist outdent indent | link image | ' +
                            'forecolor backcolor emoticons | help',
                        menu: {
                            favs: { title: 'My Favorites', items: 'code visualaid | searchreplace | emoticons' }
                        },
                        menubar: 'favs file edit view insert format tools table help',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                    }}
                    onEditorChange={handleEditorChange}
                />
            default:
                return <input type='text' placeholder='Website Content Value' name='object_value' className='form-input'/>;
        }
    }
    const resetForm = () => {
        document.getElementById('website-content-form')?.reset();
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedFormData = {
            ...formData,
            [name]: value
        };
        setFormData(updatedFormData);
    };
    const validateForm = (data) => {
        const errors = {};

        if (!data.object_type.trim()) {
            errors.object_type = 'Type is required';
        }
        if (!data.position.trim()) {
            errors.position = 'Position is in page is required';
        }

        return errors;
    };
    useEffect(() => {
        setErrors({});
        setSaveStatus({});
        resetForm();
        let type= selectedData?.component_type==='category_card' ? 'category' :
            selectedData?.component_type==='product_card' ? 'product' :
            selectedData?.component_type==='grade_card' ? 'grade' :
            selectedData?.component_type==='curriculum_card' ? 'curriculum' :
                selectedData?.component_type==='banner' ? 'image' : 'text';
        setObjectType(type)
    }, [isOpen]);
    useEffectOnce(()=>{
        dispatch(actionToGetCategoriesApiCall());
        dispatch(actionToGetProductApiCall({in_website_use:'1'}));
        dispatch(actionToGetGradesApiCall());
    })
    const getObjectTypeDropdown=(component_type)=>{
        switch (component_type){
            case 'category_card':
                return(  <option value="category" selected={true}>Category</option>)
            case 'product_card':
                return(  <option value="product" selected={true}>Product</option>)
            case 'grade_card':
                return(  <option value="grade" selected={true}>Grade</option>)
            case 'curriculum_card':
                return(  <option value="curriculum" selected={true}>Curriculum</option>)
            case 'banner':
                return( <>
                    <option value="image" >Image</option>
                    <option value="video" >Video</option>
                </>)
            default:
                return(
                    <>
                    <option value="text" selected={true}>Text</option>
                        <option value="textarea" >Text area</option>
                        <option value="editor">Editor</option>
                        <option value="file">File</option>
                        <option value="image">Image</option>
                        <option value="video">Video</option>
                        <option value="audio">Audio</option>
                    </>
                )
        }


    }
    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='Modal'>
            <div className="container">
                <div className='title'>Add Website Content</div>
                <form onSubmit={handleSubmit} id='website-content-form'>
                    <div className='vendor-details'>
                        <div className='input-box'>
                            <span className='details'>Content Name</span>
                            <input type='text' placeholder='Website Content Name*'
                                   name='name' className='form-input'/>
                            {errors.name &&  <span className="error-message"> {errors.name} </span> }
                        </div>
                       <div className='input-box'>
                            <span className='details'>Object Heading</span>
                            <input type='text' placeholder='Website Heading' name='object_heading' className='form-input'/>
                            {errors.object_heading &&  <span className="error-message"> {errors.object_heading} </span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Object Sub Heading</span>
                            <input type='text' placeholder='Website Sub  Heading' name='object_subheading' className='form-input'/>
                            {errors.object_subheading &&  <span className="error-message"> {errors.object_subheading} </span> }
                        </div>

                        <div className='input-box'>
                            <span className='details'>Call to Action Name</span>
                            <input type='text' placeholder='Call to Action Name' name='call_to_action_name' className='form-input'/>
                            {errors.call_to_action_name &&  <span className="error-message"> {errors.call_to_action_name} </span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Call to Action Link</span>
                            <input type='text' name='call_to_action_link' className='form-input'/>
                            {errors.call_to_action_link &&  <span className="error-message"> {errors.call_to_action_link} </span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Extra Content</span>
                            <input type='text' name='object_extras' className='form-input'/>
                            {errors.object_extras &&  <span className="error-message"> {errors.object_extras} </span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Object Class</span>
                            <input type='text' name='object_class' className='form-input'/>
                            {errors.object_class &&  <span className="error-message"> {errors.object_class} </span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Object Size</span>
                            <input type='text' name='object_size' className='form-input'/>
                            {errors.object_size &&  <span className="error-message"> {errors.object_size} </span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Position Of Content</span>
                            <input type='number' name='position' className='form-input'/>
                            {errors.position &&  <span className="error-message"> {errors.position} </span> }
                        </div>
                        <div className='input-box'>
                            <span className='details'>Content Type</span>
                            <select className='select' name='object_type' value={objectType} onChange={(e)=>setObjectType(e.target.value)}>

                            {getObjectTypeDropdown(selectedData?.component_type)}
                            </select>

                            {errors.object_type && <span className="error-message"> {errors.object_type}</span> }
                        </div>
                            <div className='input-box'>
                                <span className='details'>Content Value</span>
                                {objectValueInput(objectType)}
                                {errors.object_value && <span className="error-message"> {errors.object_value}</span> }
                            </div>


                    </div>
                    <div className='buttons'>
                        <div className='button'>
                            <button className='submit-button' type='submit'>Submit</button>
                        </div>
                        <div className='button'>
                            <button className='close-button' onClick={onRequestClose}>Close</button>
                        </div>
                    </div>
                    {saveStatus==='success' ? <div className="success-message">Website Content Added successfully!!</div> : saveStatus==='fail' && <div className='failure-message'>Something went Wrong</div> }
                </form>
            </div>
        </Modal>
    );
};

export default AddWebsiteSectionContent;
