import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import Select from "react-select";

const SelectMenuDropdown = (props) =>{
    const menuDataList = useSelector((state) =>  state.webSetting.menuDataList);
    const [menuDropdown, setMenuDropdown] = useState([]);
    const [selectedDropDown, setSelectedDropDown] = useState({});
    useEffect(() => {
        let menuArray = [];
        let selectedData = {};
        menuDataList !== 'loading'  && menuDataList?.map(val=>{
            if(!props.source ||(props.source && props.source == val.source)) {
                menuArray.push({value:val.id,label:val.name});
                if(props?.menu_id == val.id) selectedData={value:val.id,label:val.name};
            }
        })
        setSelectedDropDown(selectedData)
        setMenuDropdown([...menuArray]);
    }, [menuDataList,props.source,props?.menu_id]);
    return(
    <div className='input-box'>
        <span className='details'>Menu</span>
        <Select name={props.name}
                value={selectedDropDown}
                onChange={(e)=>props.handleChange({target:{name:props?.name,value:e.value}})}
                options={menuDropdown}
        />
        {props.errors[props.name] && <span className="error-message"> {props.errors[props.name]}</span> }
    </div>
    )
}
export default SelectMenuDropdown;