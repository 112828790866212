import React, {useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Virtuoso} from "react-virtuoso";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChalkboardTeacher, faLaptopFile, faPenToSquare, faTrash} from "@fortawesome/free-solid-svg-icons";
import EditGrades from "./EditGrades";
import swal from "sweetalert";
import {actionToGetGradesApiCall} from "../../../store/action/curriculumAction";
import {callSoftDeleteDataWithLogFunction, callUpdateDataWithLogFunction} from "../../../store/action/commonActions";
import {useEffectOnce} from "../../../hooks/useEffectOnce";
import useAuth from "../../../hooks/useAuth";
import { uploadFileOnAws} from "../../../store/action/inventoryActions";

export const GradesTable = () => {
    const [isEditGradesOpen, setIsEditGradesOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);
    const dispatch = useDispatch();
    const gradeData = useSelector((state) =>  state.curriculum.gradesListData);
    const {auth} = useAuth();
    const hiddenFileInput = useRef();
    const eventTypeId = useSelector((state) =>  state.curriculum.curriculumEventTypeId);

    useEffectOnce(()=>{
        dispatch(actionToGetGradesApiCall());
    })

    const deleteGrade = (row) => {
        swal({
            title: "Are you sure?",
            text: "You want to delete this Grade "+row?.name,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (success) => {
                if (success) {
                    dispatch(callSoftDeleteDataWithLogFunction({tableName:'grades',id:row.id,userId:auth?.id,eventTypeId})).then(() => {
                        dispatch(actionToGetGradesApiCall())
                    })
                }
            });

    };
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedRows([]);
        } else {
            setSelectedRows(gradeData.map(item => item.id));
        }
        setSelectAll(!selectAll);
    };
    const handlePhotoClick = (index) => {
        setSelectedIndex(index);
        hiddenFileInput.current.click();    // ADDED
    };
    const handleMainPhotoUpload = async event => {
        const fileUploaded = event.target.files[0];
        const fileExtension = fileUploaded.name.split('.').pop();
        let data =gradeData[selectedIndex];
        let payload = {filename:'grades/'+data.name?.toLowerCase()+'.'+fileExtension,showOnWebsite:'stemcity',photo:fileUploaded,type:fileUploaded.type}
        data.photo ? payload['deletedFileName'] =  data.photo?.replace('https://stemcity.s3.ap-southeast-2.amazonaws.com/','') :'';
        let url = await dispatch(uploadFileOnAws(payload));
        dispatch(callUpdateDataWithLogFunction({tableName:'grades',data: {photo:url},id:data?.id,userId:auth?.id,eventTypeId:eventTypeId})).then(() => {
            dispatch(actionToGetGradesApiCall());
        })
    };
    const handleSelectRow = (id) => {
        const newSelectedRows = selectedRows.includes(id)
            ? selectedRows.filter(rowId => rowId !== id)
            : [...selectedRows, id];

        setSelectedRows(newSelectedRows);
        setSelectAll(newSelectedRows.length === gradeData.length);
    };
    return(
        <>
            <div className='table-container'>
            {
                gradeData === 'loading' ?
                    <PreLoader/>
                    :
                    <div className="table">
                        <div className="row-header">
                            <div className="three-column-cell">
                                <input className='checkbox' type="checkbox"
                                       checked={selectAll}
                                       onChange={()=>handleSelectAll()}/>
                                Grade Name
                            </div>
                            <div className="three-column-cell">Grade Icon</div>
                            <div className="three-column-cell">Action</div>
                        </div>
                        <Virtuoso style={{ height: '600px' }} totalCount={gradeData.length} itemContent={index =>
                            <div className="table-row">
                                <div className="three-column-cell">
                                    <input className='checkbox' type="checkbox"
                                           checked={selectedRows.includes(gradeData[index]?.id)}
                                           onChange={() => handleSelectRow(gradeData[index]?.id)}/>
                                    {gradeData[index]?.name}
                                </div>
                                <div className="three-column-cell" onClick={()=>handlePhotoClick(index)}>
                                    <img src={gradeData[index]?.photo} height="35" alt={gradeData[index]?.name}/>
                                </div>
                                <div className="three-column-cell">
                                    <div className='action-components'>
                                        <div className='action-buttons'>
                                            <button className='action-button' onClick={() => {setIsEditGradesOpen('edit_grade'); setSelectedIndex(index);}} >
                                                <FontAwesomeIcon className='action-icon' icon={faPenToSquare} />
                                            </button>
                                        </div>
                                        <div className='action-buttons'>
                                            <button onClick={()=>deleteGrade(gradeData[index])} className='action-button'>
                                                <FontAwesomeIcon className='action-icon' icon={faTrash} />
                                            </button>
                                        </div>

                                       {/* <div className='action-buttons'>
                                            <Tippy content='Add Subject'>
                                                <button className='action-button' onClick={() => {setIsEditGradesOpen('grade_subject'); setSelectedIndex(index);}} >
                                                    <FontAwesomeIcon className='action-icon' icon={faChalkboardTeacher} />
                                                </button>
                                            </Tippy>
                                        </div>*/}
                                    </div>
                                </div>
                            </div>
                        } />
                    </div>
            }

            <input type="file" onChange={handleMainPhotoUpload} ref={hiddenFileInput} style={{display: 'none'}} />
            <EditGrades isOpen={isEditGradesOpen === 'edit_grade'} selectedData={gradeData[selectedIndex]} onRequestClose={() => {setIsEditGradesOpen(false); setSelectedIndex(false);}} />
            {/*<AddGradeSubject isOpen={isEditGradesOpen === 'grade_subject'} selectedData={gradeData[selectedIndex]} onRequestClose={() => {setIsEditGradesOpen(false); setSelectedIndex(false);}} />*/}
            </div>
            </>
    )
}
