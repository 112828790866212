import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import Select from "react-select";

const SelectComponentDropdown = (props) =>{
    const componentDataList = useSelector((state) =>  state.webSetting.componentDataList);
    const [componentDropdown, setComponentDropdown] = useState([]);
    const [selectedDropDown, setSelectedDropDown] = useState({});

    useEffect(() => {
        let componentArray = [];
        let selectedData = {};
        componentDataList !== 'loading'  && componentDataList?.map(val=>{
            if(!props.component_type || (props.component_type && props.component_type==val.component_type))
                componentArray.push({value:val.id,label:val.name});
            if(props?.component_id == val.id) selectedData={value:val.id,label:val.name};
        })
        setSelectedDropDown(selectedData);
        setComponentDropdown([...componentArray]);
    }, [componentDataList,props?.component_id,props.component_type]);
    return(
    <div className='input-box'>
        <span className='details'>Component</span>
        <Select name={props.name}
                value={selectedDropDown}
                onChange={(e)=>props.handleChange({target:{name:props?.name,value:e.value}})}
                options={componentDropdown}
        />
        {props.errors[props.name] && <span className="error-message"> {props.errors[props.name]}</span> }
    </div>
    )
}
export default SelectComponentDropdown;