import React, {useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Virtuoso} from "react-virtuoso";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChalkboardTeacher, faPenToSquare, faTrash} from "@fortawesome/free-solid-svg-icons";
import EditTopics from "./EditTopics";
import swal from "sweetalert";
import {actionToGetTopicsApiCall} from "../../../store/action/curriculumAction";
import {callSoftDeleteDataWithLogFunction, callUpdateDataWithLogFunction} from "../../../store/action/commonActions";
import {useEffectOnce} from "../../../hooks/useEffectOnce";
import useAuth from "../../../hooks/useAuth";
import { uploadFileOnAws} from "../../../store/action/inventoryActions";
import Tippy from "@tippyjs/react";
import AddGradeSubjectTopic from "./AddGradeSubjectTopic";

export const TopicsTable = () => {
    const [isEditTopicsOpen, setIsEditTopicsOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);
    const dispatch = useDispatch();
    const topicData = useSelector((state) =>  state.curriculum.topicsListData);
    const {auth} = useAuth();
    const hiddenFileInput = useRef();
    const eventTypeId = useSelector((state) =>  state.curriculum.curriculumEventTypeId);

    useEffectOnce(()=>{
        dispatch(actionToGetTopicsApiCall());
    })

    const deleteTopic = (row) => {
        swal({
            title: "Are you sure?",
            text: "You want to delete this Topic "+row?.name,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (success) => {
                if (success) {
                    dispatch(callSoftDeleteDataWithLogFunction({tableName:'topics',id:row.id,userId:auth?.id,eventTypeId})).then(() => {
                        dispatch(actionToGetTopicsApiCall())
                    })
                }
            });

    };
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedRows([]);
        } else {
            setSelectedRows(topicData.map(item => item.id));
        }
        setSelectAll(!selectAll);
    };
    const handlePhotoClick = (index) => {
        setSelectedIndex(index);
        hiddenFileInput.current.click();    // ADDED
    };
    const handleMainPhotoUpload = async event => {
        const fileUploaded = event.target.files[0];
        const fileExtension = fileUploaded.name.split('.').pop();
        let data =topicData[selectedIndex];
        let payload = {filename:'topics/'+data.name?.toLowerCase()+'.'+fileExtension,showOnWebsite:'stemcity',photo:fileUploaded,type:fileUploaded.type}
        data.photo ? payload['deletedFileName'] =  data.photo?.replace('https://stemcity.s3.ap-southeast-2.amazonaws.com/','') :'';
        let url = await dispatch(uploadFileOnAws(payload));
        dispatch(callUpdateDataWithLogFunction({tableName:'topics',data: {photo:url},id:data?.id,userId:auth?.id,eventTypeId:eventTypeId})).then(() => {
            dispatch(actionToGetTopicsApiCall());
        })
    };
    const handleSelectRow = (id) => {
        const newSelectedRows = selectedRows.includes(id)
            ? selectedRows.filter(rowId => rowId !== id)
            : [...selectedRows, id];

        setSelectedRows(newSelectedRows);
        setSelectAll(newSelectedRows.length === topicData.length);
    };
    return(
        <>
            <div className='table-container'>
            {
                topicData === 'loading' ?
                    <PreLoader/>
                    :
                    <div className="table">
                        <div className="row-header">
                            <div className="three-column-cell">
                                <input className='checkbox' type="checkbox"
                                       checked={selectAll}
                                       onChange={()=>handleSelectAll()}/>
                                Topic Name
                            </div>
                            <div className="three-column-cell">Topic Icon</div>
                            <div className="three-column-cell">Action</div>
                        </div>
                        <Virtuoso style={{ height: '600px' }} totalCount={topicData.length} itemContent={index =>
                            <div className="table-row">
                                <div className="three-column-cell">
                                    <input className='checkbox' type="checkbox"
                                           checked={selectedRows.includes(topicData[index]?.id)}
                                           onChange={() => handleSelectRow(topicData[index]?.id)}/>
                                    {topicData[index]?.name}
                                </div>
                                <div className="three-column-cell" onClick={()=>handlePhotoClick(index)}>
                                    <img src={topicData[index]?.photo} height="35" alt={topicData[index]?.name}/>
                                </div>
                                <div className="three-column-cell">
                                    <div className='action-components'>
                                        <div className='action-buttons'>
                                            <button className='action-button' onClick={() => {setIsEditTopicsOpen('edit_topic'); setSelectedIndex(index);}} >
                                                <FontAwesomeIcon className='action-icon' icon={faPenToSquare} />
                                            </button>
                                        </div>
                                        <div className='action-buttons'>
                                            <button onClick={()=>deleteTopic(topicData[index])} className='action-button'>
                                                <FontAwesomeIcon className='action-icon' icon={faTrash} />
                                            </button>
                                        </div>

                                        <div className='action-buttons'>
                                            <Tippy content='Add Grade Subject'>
                                                <button className='action-button' onClick={() => {setIsEditTopicsOpen('add_grade_subject'); setSelectedIndex(index);}} >
                                                    <FontAwesomeIcon className='action-icon' icon={faChalkboardTeacher} />
                                                </button></Tippy>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        } />
                    </div>
            }

            <input type="file" onChange={handleMainPhotoUpload} ref={hiddenFileInput} style={{display: 'none'}} />
            <EditTopics isOpen={isEditTopicsOpen === 'edit_topic'} selectedData={topicData[selectedIndex]} onRequestClose={() => {setIsEditTopicsOpen(false); setSelectedIndex(false);}} />
            <AddGradeSubjectTopic isOpen={isEditTopicsOpen === 'add_grade_subject'} selectedData={topicData[selectedIndex]} onRequestClose={() => {setIsEditTopicsOpen(false); setSelectedIndex(false);}} />
           {/* <AddTopicCurriculum isOpen={isEditTopicsOpen === 'add_curriculum'} selectedData={topicData[selectedIndex]} onRequestClose={() => {setIsEditTopicsOpen(false); setSelectedIndex(false);}} />*/}
            </div>
            </>
    )
}
