import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import {useDispatch, useSelector} from "react-redux";
import {callInsertDataWithLogFunction} from "../../../store/action/commonActions";
import useAuth from "../../../hooks/useAuth";
import SelectFolderDropdown from "./SelectFolderDropdown";
import {actionToGetGalleryListApiCall} from "../../../store/action/galleryActions";

const AddFolders = ({ isOpen, onRequestClose,selectedData }) => {
    const ROLES = useSelector((state) =>  state.webSetting.roleList);
    const allowedRoles =[ROLES.SuperAdmin,ROLES.Admin];
    const eventTypeId = useSelector((state) =>  state.gallery.galleryEventTypeId);
    const [errors, setErrors] = useState({});
    const [saveStatus, setSaveStatus] = useState({});
    const [formData, setFormData] = useState({ });
    const dispatch = useDispatch();
    const {auth} = useAuth();
    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedFormData = {
            ...formData,
            [name]: value
        };
        setFormData(updatedFormData);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const formDetail = new FormData(e.target);
        const obj = Object.fromEntries(formDetail.entries());
        const newErrors = validateForm(obj);
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            if (!obj['parent_id']?.trim()){
                delete obj['parent_id'];
            }
            obj['created_by']=auth?.id;
            obj['type']='folder';
            dispatch(callInsertDataWithLogFunction({tableName:'gallery_folder',data:obj,userId:auth?.id,eventTypeId})).then(res => {
                dispatch(actionToGetGalleryListApiCall(!allowedRoles?.includes(auth?.role) ? {user_id:auth?.id}:{}));
                let status = res?.success ===1 ? 'success' :'fail'
                setSaveStatus(status);
                if(status === 'success'){
                    resetForm();
                }
            })
        } else {
            setSaveStatus('fail');
        }
    };

    const resetForm = () => {
        document.getElementById('grade-form')?.reset();
    };

    const validateForm = (data) => {
        const errors = {};

        if (!data.name.trim()) {
            errors.name = 'Folder Name is required';
        }

        return errors;
    };
    useEffect(() => {
        setErrors({});
        setSaveStatus({});
        resetForm();
    }, [isOpen]);
    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='Modal'>
            <div className="container">
                <div className='title'>Add Folder</div>
                <form onSubmit={handleSubmit} id='grade-form'>
                    <div className='vendor-details'>
                        {selectedData?.id ?  <input type={'hidden'} value={selectedData.id} name={'parent_id'}/> :
                        <SelectFolderDropdown errors={errors} name={'parent_id'} grade_id={formData?.parent_id} handleChange={handleChange}/>}
                        <div className='input-box'>
                            <span className='details'>Add Folder Name</span>
                            <input type='text' placeholder='Folder Name*' name='name' className='form-input'/>
                            {errors.name &&  <span className="error-message"> {errors.name} </span> }
                        </div>

                        <div className='input-box'>
                            <span className='details'>Description</span>
                            <textarea className='text-area' name={"description"} placeholder={'description'} />
                            {errors.description &&  <span className="error-message"> {errors.description} </span> }
                        </div>
                    </div>
                    <div className='buttons'>
                        <div className='button'>
                            <button className='submit-button' type='submit'>Submit</button>
                        </div>
                        <div className='button'>
                            <button className='close-button' onClick={onRequestClose}>Close</button>
                        </div>
                    </div>
                    {saveStatus==='success' ? <div className="success-message">Folder Added successfully!!</div> : saveStatus==='fail' && <div className='failure-message'>Something went Wrong</div> }
                </form>
            </div>
        </Modal>
    );
};

export default AddFolders;