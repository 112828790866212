import React, {useState} from 'react';
import './Login.css';
import {
    faFacebook, faGoogle
} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {actionToLogin} from "../store/action/usersActions";
import useAuth from "../hooks/useAuth";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useEffectOnce} from "../hooks/useEffectOnce";





export default function Login() {
    const { setAuth } = useAuth();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [signInError, setSignInError] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const validateForm = (formData) => {
        let errors = {};
        if (!formData.email && !formData.mobile) {
            errors.contact = 'Either Email or Mobile is required';
        }
        if (!formData.password) {
            errors.password = 'Password is required';
        } else if (formData.password.length < 6) {
            errors.password = 'Password must be at least 6 characters';
        }

        return errors;
    };
    const handleLogin = async (e) => {
        setIsSubmitting(true);
        e.preventDefault();
        const formData = new FormData(e.target);
        const obj = Object.fromEntries(formData.entries());
        const errors = validateForm(obj);
        if (Object.keys(errors).length === 0) {
            dispatch(actionToLogin(obj)).then(
                res => {
                    setAuth({...res});
                    navigate('/home');
                    setIsSubmitting(false);
                },
                (error) => {
                    setSignInError(error?.response?.data?.errors[0]?.msg)
                    setIsSubmitting(false);
                }
            )
        } else {
            setFormErrors(errors);
        }
    };
    useEffectOnce(()=>{
        if(localStorage.getItem('user')){
            navigate('/');
        }
    })
    return (
        <div className='login-body'>
        <div className="login-container" id="container">
            <div className="form-container sign-in">
                <form onSubmit={handleLogin}>
                    <h3>Sign In</h3>
                    <span>using</span>
                    <div className="social-icons">
                        <a href="#" className="icon"><FontAwesomeIcon className='icon' icon={faGoogle} /></a>
                        <a href="#" className="icon"><FontAwesomeIcon className='icon' icon={faFacebook} /></a>
                    </div>
                    <span>or use your email password</span>
                    <input type="email" name={'email'} placeholder="Email"/>
                    <input type="password" name={'password'} placeholder="Password"/>
                    {formErrors.contact && <span className="error">{formErrors.contact}</span>}
                    {signInError && <span className="error">{signInError}</span>}
                    <button disabled={isSubmitting}>Sign In</button>
                </form>
            </div>
            <div className="toggle-container">
                <div className="toggle">
                    <div className="toggle-panel toggle-right">
                        <h3>Just Log in to access Inventory!</h3>
                    </div>
                </div>
            </div>
        </div>
        </div>
)
}


