import {
    CLIENT_LEAD_LIST,
    REGION_LIST,
    SCHOOL_LIST
} from "../constant/salesConstant";

const initialState = {
    clientLeadList:[],
    schoolList:[],
    regionList:[],
    salesEventTypeId:5,
}
export  const salesReducer = (state = initialState, action) => {
    switch (action.type) {
        case CLIENT_LEAD_LIST:
            return { ...state, clientLeadList: action.payload };
        case SCHOOL_LIST:
            return { ...state, schoolList: action.payload };
        case REGION_LIST:
            return { ...state, regionList: action.payload };
        default:
            return state
    }
}
