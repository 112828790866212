import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import CreatableSelect from 'react-select/creatable';
import {callInsertDataWithLogFunction} from "../../../store/action/commonActions";
import useAuth from "../../../hooks/useAuth";
import {actionToGetGradeSubjectTopicApiCall} from "../../../store/action/curriculumAction";

const SelectGradeSubjectTopicDropdown = (props) =>{
    const gradeSubjectTopicListData = useSelector((state) =>  state.curriculum.gradeSubjectTopicListData);
    const eventTypeId = useSelector((state) =>  state.curriculum.curriculumEventTypeId);
    const [componentDropdown, setComponentDropdown] = useState([]);
    const [selectedDropDown, setSelectedDropDown] = useState({});
    const dispatch=useDispatch();
    const {auth} = useAuth();

    useEffect(() => {
        let componentArray = [];
        let selectedData = {};
        gradeSubjectTopicListData !== 'loading'  && gradeSubjectTopicListData?.map(val=>{
            let value = {value:val.id,label:val.topic_name};
            if(props?.subject_id == val.subject_id && props?.grade_id==val.grade_id){
                componentArray.push(value);
            }
            if(props?.grade_subject_topic_id == val.id) selectedData=value;
        })
        setSelectedDropDown(selectedData);
        setComponentDropdown([...componentArray]);
    }, [gradeSubjectTopicListData,props?.grade_subject_topic_id,props?.grade_id,props?.subject_id]);

    const handleCreate = (topic_name) =>{
        dispatch(callInsertDataWithLogFunction({tableName:'topics',data:{name:topic_name},userId:auth?.id,eventTypeId})).then(res => {
            if(res?.id ){
                dispatch(callInsertDataWithLogFunction({
                    tableName: 'grade_subject_topic',
                    data: {grade_id:props['grade_id'],subject_id:props['subject_id'],topic_id:res.id},
                    userId: auth?.id,
                    eventTypeId: eventTypeId
                })).then(result =>{
                    dispatch(actionToGetGradeSubjectTopicApiCall())
                    props.handleChange({target:{name:'grade_subject_topic_id',value: result.id}});

                });
            }else{
                errors.grade_subject_topic_id = 'Topic name is already available, please go to topic table and map with grade subject';
            }
        })
    }
    return(
    <div className='input-box'>
        <span className='details'>Select Topic</span>
        <CreatableSelect name={props.name}
                value={selectedDropDown}
                onChange={(e)=>props.handleChange({target:{name:props?.name,value:e.value}})}
                onCreateOption={handleCreate}
                options={componentDropdown}
        />
        {props.errors[props.name] && <span className="error-message"> {props.errors[props.name]}</span> }
    </div>
    )
}
export default SelectGradeSubjectTopicDropdown;