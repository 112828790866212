import React, { useState} from 'react';
import Modal from 'react-modal';
//import {useDispatch} from "react-redux";


const EditProductComponents = ({ isOpen, onRequestClose }) => {
   // const dispatch = useDispatch();
    //const updateProductFormData = useSelector(state => state.inventory.updateProductFormData)




    const [errors, setErrors] = useState({});
    //const [saveStatus, setSaveStatus] = useState({});

    const handleSubmit = (e) => {


        e.preventDefault();
        const newErrors = validateForm();
        setErrors(newErrors);
        onRequestClose();

        if (Object.keys(newErrors).length === 0) {


            console.log('Form submitted successfully!');
        } else {
            console.log(`Form submission failed
             due to form errors.`);
        }
    };

/*    useEffect(() => {
        setFormData(updateProductFormData);
    }, [updateProductFormData]);*/

    const validateForm = (data) => {
        const errors = {};

        if (!data.name.trim()) {
            errors.name = 'Product Name is required';
        }

        // if (!data.productCategory.trim()) {
        //     errors.productCategory = 'Category is required';
        // }
        //
        // if (!data.productsubCategory.trim()) {
        //     errors.productsubCategory = 'Sub-Category is required';
        // }
        //
        // if (!data.productType.trim()) {
        //     errors.productType = 'Product Type is required';
        // }
        //
        // if (!data.productSlug.trim()) {
        //     errors.productSlug = 'Slug Name is required';
        // }
        //
        // if (!data.shortDescription.trim()) {
        //     errors.shortDescription = 'Short Description is required';
        // }
        //
        // if (!data.briefDescription.trim()) {
        //     errors.briefDescription = 'Brief Description is required';
        // }
        //
        // if (!data.brandName.trim()) {
        //     errors.brandName = 'Brand is required';
        // }
        //
        // if (!data.unitSize.trim()) {
        //     errors.unitSize = 'Unit Size is required';
        // }
        //
        // if (!data.quantity.trim()) {
        //     errors.quantity = 'Qty. is required';
        // }
        // if (!data.buyerPrice.trim()) {
        //     errors.buyerPrice = 'Buyer Price is required';
        // }
        // if (!data.netPrice.trim()) {
        //     errors.netPrice = 'Net Price is required';
        // }

        return errors;
    };

    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='Modal'>
            <div className="container">
                <div className='title'>Edit Product's Components</div>
                <form action='#' onSubmit={(e) => handleSubmit(e)}>
                    <table className='products-table'>
                        <thead>
                            <tr>
                                <th>Component Name</th>
                                <th>Qty</th>
                                <th>Unit Price</th>
                                <th>Included?</th>
                            </tr>
                        </thead>
                    <tbody>
                    <tr>
                        <td>
                            <input className='form-input'
                                   type="text"
                                   name="componentName"
                                   placeholder="Component Name"
                            />
                        </td>
                        <td>
                            <input className='form-input'
                                   type="number"
                                   name="productName"
                                   placeholder="Qty"
                            />
                        </td>
                        <td>
                            <input className='form-input'
                                   type="text"
                                   name="productName"
                                   placeholder="Unit Price"
                            />
                        </td>
                        <td>
                            <input className='form-input'
                                   type="checkbox"
                                   name="included"
                                   placeholder="Included?"
                            />
                        </td>
                    </tr>
                    </tbody>
                    </table>

                    <div className='buttons'>
                        <div className='button'>
                            <button className='submit-button' onClick={(e) => handleSubmit(e)} type='button'>Update</button>
                        </div>
                        <div className='button'>
                            <button className='close-button' onClick={onRequestClose}>Close</button>
                        </div>
                    </div>
                   {/* {saveStatus==='success' ? <div className="success-message">Product Updated successfully!!</div> : saveStatus==='fail' && <div className='failure-message'>Something went Wrong</div> }
              */}  </form>

            </div>
        </Modal>
    );
};


export default EditProductComponents;
