import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import CreatableSelect from 'react-select/creatable';
import {callInsertDataWithLogFunction} from "../../../store/action/commonActions";
import {actionToGetSubjectsApiCall} from "../../../store/action/curriculumAction";
import useAuth from "../../../hooks/useAuth";

const SelectSubjectDropdown = (props) =>{
    const subjectsListData = useSelector((state) =>  state.curriculum.subjectsListData);
    const eventTypeId = useSelector((state) =>  state.curriculum.curriculumEventTypeId);
    const dispatch = useDispatch();
    const {auth} = useAuth();
    const [componentDropdown, setComponentDropdown] = useState([]);
    const [selectedDropDown, setSelectedDropDown] = useState({});
    const handleCreate = (val) => {
        if (val) {
            let obj ={name:val,created_by:auth?.id};
            dispatch(callInsertDataWithLogFunction({tableName:'subjects',data:obj,userId:auth?.id,eventTypeId})).then(res => {
                if(res?.id){
                    props.handleChange({target:{name:props?.name,value: res.id}});
                    dispatch(actionToGetSubjectsApiCall());
                }
            })
        }
    };
    useEffect(() => {
        let componentArray = [];
        let selectedData = {};
        subjectsListData !== 'loading'  && subjectsListData?.map(val=>{
            componentArray.push({value:val.id,label:val.name});
            if(props?.subject_id == val.id) selectedData={value:val.id,label:val.name};
        })
        setSelectedDropDown(selectedData);
        setComponentDropdown([...componentArray]);
    }, [subjectsListData,props?.subject_id]);
    return(
    <div className='input-box'>
        <span className='details'>Select Subject</span>
        <CreatableSelect name={props.name}
                value={selectedDropDown}
                onCreateOption={handleCreate}
                onChange={(e)=>props.handleChange({target:{name:props?.name,value:e.value}})}
                options={componentDropdown}
        />
        {props.errors[props.name] && <span className="error-message"> {props.errors[props.name]}</span> }
    </div>
    )
}
export default SelectSubjectDropdown;