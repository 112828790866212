import React, { useState } from 'react';
import Modal from 'react-modal';
import {useDispatch, useSelector} from "react-redux";
import {
    actionToCreateUserAction,
    actionToGetUsersListApiCall
} from "../../../store/action/usersActions";
import {capitalizeFirstLetter} from "../../../store/helper/commonHelper";
//import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import useAuth from "../../../hooks/useAuth";

const AddUsers = React.memo(({ isOpen, onRequestClose }) => {
    const genderList = ['male', 'female', 'others'];
    const usersRoleList = useSelector((state) =>  state.users?.usersRoleList);
    const dispatch = useDispatch();
    const [saveStatus, setSaveStatus] = useState({});
    const {auth} = useAuth();
    const [errors, setErrors] = useState({});

      const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const obj = Object.fromEntries(formData.entries());
        const newErrors = validateForm(obj);
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            obj['created_by']=auth?.id;
            dispatch(actionToCreateUserAction(obj)).then(res => {
                dispatch(actionToGetUsersListApiCall());
                let status = res?.data?.response?.success ===1 ? 'success' :'fail'
                setSaveStatus(status);
                if(status === 'success'){
                    resetForm();
                }
            })
        } else {
            setSaveStatus('fail');
        }
    };
    const resetForm = () => {
        document.getElementById('user_form').reset();
    };

    const validateForm = (data) => {
        const errors = {};

        if (!data.name?.trim()) {
            errors.name = 'Name is required';
        }
        if (!data.email && !data.mobile) {
            errors.contact = 'Either Email or Mobile is required';
        } else {
            if (data.email && !/\S+@\S+\.\S+/.test(data.email)) {
                errors.email = 'Email is invalid';
            }
            if (data.mobile && !/^\d{10}$/.test(data.mobile)) {
                errors.mobile = 'Mobile must be a 10-digit number';
            }
        }
        return errors;
    };
    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='Modal'>
            <div className="container">
                <div className='title'>Add User</div>
                <form onSubmit={handleSubmit} id='user_form' >
                    <div className='vendor-details'>
                        <div className='input-box'>
                            {/*<span className='details'>Name</span>*/}
                            <input type='text' placeholder='Name*'
                                   name='name' className='form-input'/>
                            {errors.name &&
                                <span className="error-message">
                            {errors.name}
                        </span>
                            }
                        </div>
                        <div className='input-box'>
                            <input type='email' name='email' className='form-input'
                                   placeholder='Email'/>
                            {errors.email && <span className="error">{errors.email}</span>}
                        </div>
                        <div className='input-box'>
                            <input type='text' name='mobile' className='form-input'
                                   placeholder='Mobile'/>
                            {errors.mobile && <span className="error">{errors.mobile}</span>}
                        </div>
                        <div className='input-box'>
                            <input type='text' name='password' className='form-input'
                                   placeholder='password'/>
                        </div>
                        <div className='input-box'>
                            <select className='select' name='role' >
                                {usersRoleList !== 'loading' && usersRoleList?.map((role,index) => {
                                    return role.in_option==='1' && <option key={role?.id} value={role?.id} selected={index === 0}>{role?.role}</option>
                                })}
                            </select>
                        </div>
                        <div className='input-box'>
                            <select className='select' name='gender' >
                                {genderList?.map((gender,index) => {
                                    return <option key={gender} value={gender} selected={index === 0}>{capitalizeFirstLetter(gender)}</option>
                                })}
                            </select>
                        </div>
                        <div className='input-box'>
                            <input type={'date'} name={'date_of_birth'} className='form-input' required/>
                            {/*<DatePicker name={"date_of_birth"} placeholderText={'Date of Birth'} className='form-input'/>*/}
                        </div>
                        <div className='input-box'>
                            <textarea className='text-area' name={"address"} placeholder={'Address'} />
                        </div>
                    </div>
                    {errors.contact && <span className="error">{errors.contact}</span>}
                    <div className='buttons'>
                        <div className='button'>
                            <button className='submit-button' type='submit'>Submit</button>
                        </div>
                        <div className='button'>
                            <button className='close-button' onClick={onRequestClose}>Close</button>
                        </div>
                    </div>
                    {saveStatus==='success' ? <div className="success-message">User Added successful!!</div> : saveStatus==='fail' && <div className='failure-message'>Something went Wrong</div> }
                </form>

            </div>
        </Modal>
    );
});

export default AddUsers;
