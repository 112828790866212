import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Virtuoso} from "react-virtuoso";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faLaptopFile, faPenToSquare, faTrash} from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";
import Tippy from "@tippyjs/react";
import {actionToGetMenuListApiCall} from "../../../../store/action/webSettingActions";
import {callSoftDeleteDataWithLogFunction, callUpdateDataWithLogFunction} from "../../../../store/action/commonActions";
import {uploadFileOnAws} from "../../../../store/action/inventoryActions";
import useAuth from "../../../../hooks/useAuth";
import EditMenu from "./EditMenu";
import MenuPreview from "./MenuPreview";
import CompanyPipeline from "../company/CompanyPipeline";
import {Link} from "react-router-dom";

export const MenuTable = () => {
    const [isEditMenuOpen, setIsEditMenuOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);
    const [menuListData, setMenuListData] = useState([]);
    const dispatch = useDispatch();
    const menuData = useSelector((state) =>  state.webSetting.menuDataList);
    const {auth} = useAuth();
    const hiddenFileInput = useRef();
    const eventTypeId = useSelector((state) =>  state.webSetting.webSettingEventTypeId);
    const [source,setSource]=useState(auth?.source);

    const changeCompany = (val)=>{
        setSource(val.id);
    }
    const menuFilterFunction = () =>{
        let menuList=[]
        menuData.map(menuArray=>{
            if(menuArray.source ==source){
                menuList.push(menuArray);
            }
        })
        setMenuListData(menuList);
    }
    const deleteMenu = (row) => {
        swal({
            title: "Are you sure?",
            text: "You want to delete this Menu "+row?.name,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (success) => {
                if (success) {
                    dispatch(callSoftDeleteDataWithLogFunction({tableName:'menu',id:row.id,userId:auth?.id,eventTypeId})).then(() => {
                        dispatch(actionToGetMenuListApiCall())
                    })
                }
            });

    };
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedRows([]);
        } else {
            setSelectedRows(menuListData.map(item => item.id));
        }
        setSelectAll(!selectAll);
    };
    const handlePhotoClick = (index) => {
        setSelectedIndex(index);
        hiddenFileInput.current.click();    // ADDED
    };
    const handleMainPhotoUpload = async event => {
        const fileUploaded = event.target.files[0];
        const fileExtension = fileUploaded.name.split('.').pop();
        let data =menuListData[selectedIndex];
        let payload = {filename:'menu/'+data.name?.toLowerCase()+'.'+fileExtension,showOnWebsite:data.company_name,photo:fileUploaded,type:fileUploaded.type};
        data.photo ? payload['deletedFileName'] = data.company_name.toLowerCase() ==='stemcity'?
            data.photo?.replace('https://stemcity.s3.ap-southeast-2.amazonaws.com/','') : data.photo?.replace('https://shikshak-solutions.s3.eu-north-1.amazonaws.com/','')  :'';
        let url = await dispatch(uploadFileOnAws(payload));
        dispatch(callUpdateDataWithLogFunction({tableName:'menu',data: {photo:url},id:data?.id,userId:auth?.id,eventTypeId:eventTypeId})).then(() => {
            dispatch(actionToGetMenuListApiCall());
        })
    };
    const handleSelectRow = (id) => {
        const newSelectedRows = selectedRows.includes(id)
            ? selectedRows.filter(rowId => rowId !== id)
            : [...selectedRows, id];

        setSelectedRows(newSelectedRows);
        setSelectAll(newSelectedRows.length === menuListData.length);
    };
    useEffect(() => {
        if(source){
            menuFilterFunction();
        }
    }, [source,menuData]);
    return(
        <>
            <div className='table-container'>
            {
                menuData === 'loading' ?
                    <PreLoader/>
                    :
                    <>
                   <CompanyPipeline source={source} changeCompany={changeCompany}/>
                    <div className="table">
                        <div className="row-header">
                            <div className="seven-column-cell">
                                <input className='checkbox' type="checkbox"
                                       checked={selectAll}
                                       onChange={()=>handleSelectAll()}/>
                                Menu Name
                            </div>
                            <div className="seven-column-cell">Url</div>
                            <div className="seven-column-cell">Menu Icon</div>
                            <div className="seven-column-cell">Company Name</div>
                            <div className="seven-column-cell">Ordering</div>
                            <div className="seven-column-cell">Tooltip</div>
                            <div className="seven-column-cell">Action</div>
                        </div>
                        <Virtuoso style={{ height: '600px' }} totalCount={menuListData.length} itemContent={index =>
                            <div className="table-row">
                                <div className="seven-column-cell">
                                    <input className='checkbox' type="checkbox"
                                           checked={selectedRows.includes(menuListData[index]?.id)}
                                           onChange={() => handleSelectRow(menuListData[index]?.id)}/>
                                    <Link to={'/website-setting/menu/'+menuListData[index]?.id}>{menuListData[index]?.name}</Link>
                                </div>
                                <div className="seven-column-cell">
                                    {menuListData[index]?.url}
                                </div>
                                <div className="seven-column-cell" onClick={()=>handlePhotoClick(index)}>
                                        <img src={menuListData[index]?.photo} height="35" alt={menuListData[index]?.name} />
                                </div>
                                <div className="seven-column-cell">
                                    {menuListData[index]?.company_name}
                                </div>
                                <div className="seven-column-cell">
                                    {menuListData[index]?.ordering}
                                </div>
                                <div className="seven-column-cell">
                                    {menuListData[index]?.tooltip}
                                </div>
                                <div className="seven-column-cell">
                                    <div className='action-components'>
                                        <div className='action-buttons'>
                                            <button className='action-button' onClick={() => {setIsEditMenuOpen('edit_menu'); setSelectedIndex(index);}} >
                                                <FontAwesomeIcon className='action-icon' icon={faPenToSquare} />
                                            </button>
                                        </div>
                                        <div className='action-buttons'>
                                            <Tippy content='View Menu'>
                                            <button onClick={()=>{setIsEditMenuOpen('view_menu'); setSelectedIndex(index);}} className='action-button'>
                                                <FontAwesomeIcon className='action-icon' icon={faEye} />
                                            </button>
                                            </Tippy>
                                        </div>
                                        <div className='action-buttons'>
                                            <Tippy content='Delete Menu'>
                                            <button onClick={()=>deleteMenu(menuListData[index])} className='action-button'>
                                                <FontAwesomeIcon className='action-icon' icon={faTrash} />
                                            </button>
                                            </Tippy>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        } />
                    </div>
                    </>
            }

            <input type="file" onChange={handleMainPhotoUpload} ref={hiddenFileInput} style={{display: 'none'}} />
            <EditMenu isOpen={isEditMenuOpen ==='edit_menu'} selectedData={menuListData[selectedIndex]} onRequestClose={() => {setIsEditMenuOpen(false); setSelectedIndex(false);}} />
            <MenuPreview isOpen={isEditMenuOpen ==='view_menu'} selectedData={menuListData[selectedIndex]} onRequestClose={() => {setIsEditMenuOpen(false); setSelectedIndex(false);}} />
            </div>

            </>
    )
}
